const adFormats = [
  {
    label: 'BAKED-IN',
    value: 'bakedIn',
  },
  {
    label: 'DYNAMIC-AD',
    value: 'dynamicAd',
  },
  {
    label: 'AFFILIATE',
    value: 'affiliate',
  },
  {
    label: 'PROGRAMMATIC',
    value: 'programmatic',
  },
  {
    label: 'YOUTUBE SIMULCAST',
    value: 'youtubeSimulcast',
  },
  { label: 'Run Of Network', value: 'programmatic' },
  {
    label: 'Dynamic Ad (Episodes)',
    value: 'dynamicAdEpisodes',
  },
  {
    label: 'Dynamic Ad (Time)',
    value: 'dynamicAdTime',
  },
];

export default adFormats;
