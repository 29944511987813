import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Roles from 'enums/firebase/roles.enum';
import getAdministrator from 'services/users/getAdministrator';
import getCustomClaims from '../getCustomClaims';

const login = async ({ email, password }) => {
  const auth = getAuth();
  const { user } = await signInWithEmailAndPassword(auth, email, password);

  const claims = await getCustomClaims(user);

  if (claims.role === Roles.Admin) {
    const administrators = await getAdministrator({user});

    return {
      id: user.uid,
      email: user.email,
      role: claims.role,
      network: claims.network,
      permissions: (administrators ? administrators.permissions : [])
    };
  }

  return null;
};

export default login;
