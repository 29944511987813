import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  selectEditPendingDocumentsPodcastsState,
  selectEditPodcastDataState,
} from 'state/selectors/podcasts';
import {
  clearEditPendingDocumentState,
  fetchPendingDocumentsPodcasts,
} from 'state/actions/podcasts';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Kind as ButtonKind,
  Color as ButtonColor,
} from 'components/Common/Button';
import Spinner, { Color as SpinnerColor } from 'components/Common/Spinner';
import Input, { Type as InputType } from 'components/Common/Input';

import classes from './EditPodcastDownloadsEstimate.module.scss';

const EditPodcastDownloadsEstimate = ({
  podcast,
  isApproved,
  onUpdate,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const screenshotDownloadsEstimate = podcast?.screenshots?.downloadsPerEpisode;

  const oldDownloadsEstimate = useMemo(() => {
    if (isApproved || !screenshotDownloadsEstimate) {
      return Number(podcast?.podcastData?.downloadsEstimate) ?? '';
    }

    return screenshotDownloadsEstimate === ''
      ? ''
      : Number(screenshotDownloadsEstimate);
  }, [
    screenshotDownloadsEstimate,
    podcast?.screenshots?.downloadsPerEpisode,
    podcast?.podcastData?.downloadsEstimate,
    isApproved,
  ]);

  const [downloadsEstimate, setDownloadsEstimate] = useState(
    oldDownloadsEstimate
  );

  const { loading, success } = useSelector(
    selectEditPendingDocumentsPodcastsState,
    shallowEqual
  );

  const { loading: loadingEditPodcastData } = useSelector(
    selectEditPodcastDataState,
    shallowEqual
  );

  useEffect(() => {
    setDownloadsEstimate(oldDownloadsEstimate);
  }, [oldDownloadsEstimate]);

  useEffect(() => {
    if (success) {
      dispatch(fetchPendingDocumentsPodcasts());
    }

    return () => {
      dispatch(clearEditPendingDocumentState());
    };
  }, [success]);

  const onChangeDownloadsEstimatesHandler = ({ target: { value } }) =>
    setDownloadsEstimate(value);

  const isUpdating = loading || loadingEditPodcastData;

  return (
    <div className={classes.container}>
      <Body className={classes.title}>{podcast?.podcastName}</Body>
      <img
        src={podcast?.podcastData?.imageUrl}
        alt="podcast"
        className={classes.image}
      />
      <Body size={BodySize.XS}>EDIT DOWNLOADS PER EPISODE</Body>
      <Input
        type={InputType.Number}
        containerClassName={classes.inputContainer}
        value={downloadsEstimate}
        onChange={onChangeDownloadsEstimatesHandler}
      />
      <div className={classes.buttonsContainer}>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          color={ButtonColor.Pink}
          className={classes.button}
          onClick={() => onCancel({ podcast })}
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          className={classes.button}
          disabled={downloadsEstimate === oldDownloadsEstimate || isUpdating}
          onClick={() => onUpdate({ podcast, downloadsEstimate })}
        >
          {isUpdating ? <Spinner color={SpinnerColor.White} /> : 'Update'}
        </Button>
      </div>
    </div>
  );
};

EditPodcastDownloadsEstimate.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  podcast: PropTypes.shape({
    id: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
      ),
      downloadsEstimate: PropTypes.string,
      webUrl: PropTypes.string,
      applePodcastUrl: PropTypes.string,
      ratingAverage: PropTypes.number,
    }),
    screenshots: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      audienceEstimate: PropTypes.string,
      downloadsPerEpisode: PropTypes.string,
    }),
  }),
  isApproved: PropTypes.bool,
};

EditPodcastDownloadsEstimate.defaultProps = {
  podcast: null,
  isApproved: false,
};

export default EditPodcastDownloadsEstimate;
