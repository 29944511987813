import { createAction } from '@reduxjs/toolkit';

export const fetchGeneralValuesInit = createAction(
  '[Generals] Fetch General Values Init'
);
export const fetchGeneralValuesSuccess = createAction(
  '[Generals] Fetch General Values Success'
);
export const fetchGeneralValuesFail = createAction(
  '[Generals] Fetch General Values Fail'
);
export const updateGeneralValuesInit = createAction(
  '[Generals] Update General Values Init'
);
export const updateGeneralValuesSuccess = createAction(
  '[Generals] Update General Values Success'
);
export const updateGeneralValuesFail = createAction(
  '[Generals] Update General Values Fail'
);
export const resetGeneralValues = createAction(
  '[Generals] Reset General Values'
);
export const fetchHostingProvidersInit = createAction(
  '[Generals] Fetch Hosting Providers Init'
);
export const fetchHostingProvidersSuccess = createAction(
  '[Generals] Fetch Hosting Providers Success'
);
export const fetchHostingProvidersFail = createAction(
  '[Generals] Fetch Hosting Providers Fail'
);
