import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import SideBar from 'components/Navigation/SideBar';
import Header from 'components/Navigation/Header';
import Footer from 'components/Navigation/Footer';

import classes from './NavigationLayout.module.scss';

const NavigationLayout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const onToggleMenuHandler = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <div className={classes.container}>
      <SideBar open={open} onToggleMenu={onToggleMenuHandler} />
      <Header />
      <main className={classes.content}>
        {children}
        <Footer className={classes.footer} />
      </main>
    </div>
  );
};

NavigationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationLayout;
