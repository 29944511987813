const adTypes = [
  {
    value: 'preRoll',
    label: 'PRE-ROLL',
  },
  {
    value: 'midRoll',
    label: 'MID-ROLL',
  },
  {
    value: 'postRoll',
    label: 'POST-ROLL',
  },
  {
    value: 'backCatalog',
    label: 'BACK CATALOG',
  },
  {
    value: 'bakedIn',
    label: 'BAKED-IN',
  },
  {
    value: 'dynamicAd',
    label: 'DYNAMIC AD',
  },
];

export default adTypes;
