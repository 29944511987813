import Login from 'pages/Login';
import Path from 'enums/path.enum';

const loginRoutes = [
  {
    component: Login,
    path: Path.Login,
  },
];

export default loginRoutes;
