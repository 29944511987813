import {
  getFirestore,
  collectionGroup,
  query,
  getDocs,
  where,
  collection,
  documentId,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchPendingDocumentsPodcasts = async (network) => {
  const firestore = getFirestore();

  try {
    const pendingDocumentsQuery = query(
      collectionGroup(firestore, Collections.AudienceEstimateScreenshots),
      where('approved', '==', false),
      where('network', '==', network)
    );

    const pendingDocuments = await getDocs(pendingDocumentsQuery);

    const users = [
      ...new Set(pendingDocuments.docs.map((doc) => doc.data().userId)),
    ];

    const pendingDocumentsData = {};

    pendingDocuments.docs.forEach((doc) => {
      const data = doc.data();

      if (
        !pendingDocumentsData[data.userId] ||
        pendingDocumentsData[data.userId].createdAt <= data.createdAt
      )
        pendingDocumentsData[data.userId] = {
          ...data,
        };
    });

    const slices = [];
    const offset = 10;

    for (let index = 0; index < users.length; index += offset) {
      slices.push(users.slice(index, index + offset));
    }

    const promises = slices.flatMap((dataSets) => {
      const podcasterWithPendingDocumentsQuery = query(
        collection(firestore, Collections.PodcastUsers),
        where(documentId(), 'in', dataSets),
        where('enabled', '==', true),
        where('networks', 'array-contains', network)
      );
      return getDocs(podcasterWithPendingDocumentsQuery);
    });

    const snapshots = await Promise.all(promises);

    const podcasterWithPendingDocumentsDocs = snapshots.flatMap(
      (snapshot) => snapshot.docs
    );

    return {
      pendingDocumentsPodcasts: podcasterWithPendingDocumentsDocs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          screenshots: pendingDocumentsData[doc.id],
        };
      }),
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export default fetchPendingDocumentsPodcasts;
