const PaymentTabs = [
  {
    label: 'Pending',
    key: 'pending',
  },
  {
    label: 'Processed',
    key: 'processed',
  },
];

export default PaymentTabs;
