import {
  collection,
  getDocs,
  query,
  getFirestore,
  where,
} from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const fetchUsers = async ({ network }) => {
  try {
    const firestore = getFirestore();
    const usersRef = collection(firestore, Collections.Administrators);

    const usersQuery = query(
      usersRef,
      where('network', '==', network)
    );

    const usersSnapshot = await getDocs(usersQuery);
    return usersSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchUsers;
