import { useState } from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button from 'components/Common/Button';
import Textarea, { Size as TextareaSize } from 'components/Common/Textarea';

import classes from './PodcastRejectReason.module.scss';

const PodcastRejectReason = ({ podcast, onAccept, onCancel }) => {
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const altPodcastImage = 'podcastImage';

  const handleOtherReason = () => {
    setIsOtherReason(true);
  };

  const handleCancelReject = () => {
    if (isOtherReason) {
      setIsOtherReason(false);
      setRejectionReason('');
    } else {
      onCancel(podcast);
    }
  };

  const handleAcceptOtherReason = () => {
    onAccept({ ...podcast, rejectionReason });
  };

  const handleAcceptDataDoesntMatch = () => {
    onAccept(podcast);
  };

  return (
    <div className={classes.container}>
      <Body size={BodySize.M}>{podcast?.podcastName}</Body>
      <img
        src={podcast?.podcastData?.imageUrl}
        alt={altPodcastImage}
        className={classes.image}
      />
      {!isOtherReason ? (
        <div className={classes.reason}>
          <Body size={BodySize.XS}>
            What was the reason this podcast was rejected?
          </Body>
          <Button
            kind="secondary"
            size="small"
            className={classes.reasonButton}
            onClick={handleAcceptDataDoesntMatch}
          >
            DATA DOESN’T MATCH SCREENSHOT
          </Button>
          <Button
            kind="secondary"
            size="small"
            className={classes.reasonButton}
            onClick={handleOtherReason}
          >
            OTHER REASON
          </Button>
        </div>
      ) : (
        <>
          <Body size={BodySize.XS}>
            Write more about the reason you’re rejecting
          </Body>
          <Textarea
            containerClassName={classes.textareaContainer}
            className={classes.textarea}
            placeholder="Type your reason"
            size={TextareaSize.M}
            onChange={(text) => {
              setRejectionReason(text);
            }}
            toolbarHidden
          />
        </>
      )}
      <div className={classes.buttons}>
        <Button
          kind="primary"
          color="pink"
          size="large"
          className={classes.button}
          onClick={handleCancelReject}
        >
          Cancel
        </Button>
        {isOtherReason && (
          <Button
            kind="primary"
            size="large"
            className={classes.button}
            onClick={handleAcceptOtherReason}
          >
            Confirm
          </Button>
        )}
      </div>
    </div>
  );
};

PodcastRejectReason.propTypes = {
  podcast: PropTypes.shape({
    id: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
      ),
      webUrl: PropTypes.string,
      applePodcastUrl: PropTypes.string,
      ratingAverage: PropTypes.number,
    }),
  }),
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PodcastRejectReason.defaultProps = {
  podcast: null,
};

export default PodcastRejectReason;
