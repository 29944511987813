/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Body, {
    Size as BodySize
} from 'components/Typography/Body';
import classes from './Forbidden.module.scss';


const Forbidden = () => (
    <div className={classes.container}>
        <div className={classes.content}>
            <Body
                size={BodySize.S}
                className={classes.title}
            >
                <b className={classes.title} data-testid="forbidden-title">Section Not Available</b>
                <p>You don&lsquo;t have permission to access this.<br />Contact your admin to request access.</p>
            </Body>
        </div>
    </div>
);

export default Forbidden;
