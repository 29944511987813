import { getFirestore, doc, setDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const sendToNetworkPodcast = async ({ podcastId, network }) => {
  const firestore = getFirestore();

  const sendToNetworkedPodcastRef = doc(
    firestore,
    Collections.PodcastUsers,
    podcastId
  );

  await setDoc(
    sendToNetworkedPodcastRef,
    {
      networks: [network],
    },
    { merge: true }
  );
};

export default sendToNetworkPodcast;
