const SummaryTabs = [
  {
    label: 'Details',
    key: 'details',
  },
  {
    label: 'Current Campaigns',
    key: 'current',
  },
  {
    label: 'Finished Campaigns',
    key: 'finished',
  },
];

export default SummaryTabs;
