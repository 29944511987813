import offerStatus from 'enums/campaigns/offerStatus.enum';
import podcastPaymentStatus from 'enums/campaigns/podcastPaymentStatus';

const getCampaignPodcastsPaymentsStatus = (campaign) => {
  if (!campaign.offers?.every((offer) => offer.podcastPaymentStatus)) {
    return podcastPaymentStatus.pending;
  }
  if (
    campaign.offers?.some(
      (offer) => offer.podcastPaymentStatus === offerStatus.rejected
    )
  ) {
    return podcastPaymentStatus.rejected;
  }
  if (
    campaign.offers?.some(
      (offer) => offer.podcastPaymentStatus === offerStatus.accepted
    )
  ) {
    return podcastPaymentStatus.accepted;
  }
  return podcastPaymentStatus.pending;
};

export default getCampaignPodcastsPaymentsStatus;
