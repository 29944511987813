import React from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';

import classes from './FormControl.module.scss';

const FormControl = ({ name, validations, render, className }) => {
  const { register, errors } = useFormContext();
  const error = errors[name];

  return (
    <div
      className={classNames(classes.formControl, className, {
        [classes.formControlWithError]: error,
      })}
    >
      {render({
        name,
        register: register(validations),
        error: !!error?.message,
      })}
      {error && (
        <Body
          color={BodyColor.Red}
          size={BodySize.XXS}
          className={classes.error}
        >
          {error.message}
        </Body>
      )}
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  render: PropTypes.any.isRequired,
  validations: PropTypes.shape({}),
  className: PropTypes.string,
};

FormControl.defaultProps = {
  validations: {},
  className: '',
};

export default FormControl;
