/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImgsViewer from 'react-images-viewer';
import { shallowEqual, useSelector } from 'react-redux';

import WebIcon from 'assets/icons/website.png';
import AppleIcon from 'assets/icons/apple-podcast.png';
import InstagramIcon from 'assets/icons/instagram.png';
import TiktokIcon from 'assets/icons/tiktok.png';
import XIcon from 'assets/icons/x.png';
import ThreadsIcon from 'assets/icons/threads.png';
import getPodcastFields from 'utils/podcasts/getPodcastFields';
import removeNonAcceptedTags from 'utils/functions/removeNonAcceptedTags';
import Badge from 'components/Common/Badge';
import Link from 'components/Typography/Link';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, { Color as ButtonColor } from 'components/Common/Button';
import Tabs from 'components/Common/Tabs';
import Tab from 'components/Common/Tab';
import SummaryTabs from 'enums/podcasts/summaryTabs.enum';
import { selectFetchHostingProviders } from 'state/selectors/generals';

import classes from './PodcastFullInformation.module.scss';
import PodcastDetails from '../PodcastDetails';
import PodcastsCampaigns from '../PodcastsCampaigns';

const DETAILS = 'details';
const CURRENT = 'current';
const FINISHED = 'finished';

const PodcastFullInformation = ({
  podcast,
  onAccept,
  onReject,
  onEdit,
  onEditDownloads,
  approvingDocuments,
  approvingPodcast,
  isApproved,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([]);
  const data = podcast?.podcastData || null;
  const { email, name, lastName, createdAt, uid: podcastId, socialMedia } =
    podcast || {};
  const onAcceptHandler = useCallback(() => {
    onAccept(podcast);
  }, [podcast]);

  const onRejectHandler = useCallback(() => {
    onReject(podcast);
  }, [podcast]);

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const screenshots = podcast?.screenshots;

  const urls = screenshots?.urls;
  const oldUrls = screenshots?.oldUrls;

  const hasDownloadsEstimate = screenshots?.downloadsPerEpisode;
  const hasAudienceEstimate =
    screenshots?.audienceEstimate || screenshots?.monthlyListeners;

  useEffect(() => {
    if (!oldUrls && urls) {
      const {
        monthlyListeners: audienceEstimate,
        downloadsPerEpisode: downloadsEstimate,
      } = urls;

      const podcastScreenshots = [];

      if (audienceEstimate) {
        podcastScreenshots.push({
          title: 'Average Monthly Audience',
          src: audienceEstimate,
          alt: `screenshot${email}-audience`,
        });
      }

      if (downloadsEstimate) {
        podcastScreenshots.push({
          title: 'Downloads Per Episode',
          src: downloadsEstimate,
          alt: `screenshot${email}-downloads`,
        });
      }

      setImages(podcastScreenshots);
    } else if (oldUrls) {
      const imagesObject = oldUrls.map((url, index) => ({
        src: url,
        alt: `screenshot${email}-${index}`,
      }));

      setImages(imagesObject);
    } else {
      setImages([]);
    }
  }, [podcast, urls, oldUrls]);

  const audienceEstimate = useMemo(() => {
    if (isApproved || !hasAudienceEstimate) {
      return data?.audienceEstimate;
    }

    if (oldUrls) {
      return screenshots?.monthlyListeners;
    }

    if (urls) {
      return screenshots?.monthlyListeners || data?.audienceEstimate;
    }

    return '';
  }, [
    isApproved,
    oldUrls,
    urls,
    hasAudienceEstimate,
    data?.audienceEstimate,
    screenshots?.audienceEstimate,
  ]);

  const downloadsEstimate = useMemo(() => {
    if (isApproved || !hasDownloadsEstimate) {
      return data?.downloadsEstimate;
    }

    if (oldUrls) {
      return screenshots?.downloadsPerEpisode;
    }

    if (urls) {
      return screenshots?.downloadsPerEpisode || data?.downloadsEstimate;
    }

    return '';
  }, [
    isApproved,
    oldUrls,
    urls,
    screenshots?.downloadsPerEpisode,
    hasDownloadsEstimate,
    data?.downloadsEstimate,
  ]);
  const { hostingProviders } = useSelector(
    selectFetchHostingProviders,
    shallowEqual
  );
  const podcastData = getPodcastFields({
    adFormats: podcast?.adFormats,
    adTypes: podcast?.adPlacements,
    gender: data?.audienceInformation?.gender,
    ageRanges: data?.audienceInformation?.ageRanges,
    country: podcast?.country,
    ethnicities: data?.audienceInformation?.ethnicities,
    maritalStatuses: data?.audienceInformation?.maritalStatuses,
    episodesPerMonth: data?.episodesPerMonth,
    incomeRanges: data?.audienceInformation?.incomeRanges,
    audienceEstimate,
    downloadsEstimate,
    approveProps: {
      showEditableOptions: isApproved || approvingDocuments || approvingPodcast,
      onEdit: () => onEdit(podcast),
      onEditDownloads: () => onEditDownloads(podcast),
    },
    hostingProvider: podcast?.hostingProvider,
    hostingProviders,
  });

  const description = useMemo(
    () => (data?.description ? removeNonAcceptedTags(data?.description) : ''),
    [data?.description]
  );

  const parseSocialMediaUrl = (url, socialMediaName) => {
    if (url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    }

    const sanitizedUrl = url.replace(/^@/, '');

    const socialMediaUrls = {
      instagram: `https://instagram.com/${sanitizedUrl}`,
      tiktok: `https://tiktok.com/@${sanitizedUrl}`,
      threads: `https://threads.net/@${sanitizedUrl}`,
      x: `https://x.com/${sanitizedUrl}`,
    };

    return socialMediaUrls[socialMediaName] || url;
  };

  return (
    <>
      {isViewerOpen && images.length && (
        <ImgsViewer
          backdropCloseable
          imgs={images}
          currImg={currentImage}
          isOpen={isViewerOpen}
          onClickPrev={() => setCurrentImage(currentImage - 1)}
          onClickNext={() => setCurrentImage(currentImage + 1)}
          onClose={closeImageViewer}
        />
      )}
      {podcast && (
        <div className={classes.container}>
          <div className={classes.content}>
            <div className={classes.topContent}>
              <div className={classes.leftColumn}>
                <div className={classes.mediaContainer}>
                  <img
                    src={data?.imageUrl}
                    alt={`logo${data?.podcastTitle}`}
                    className={classes.image}
                  />

                  <div className={classes.media}>
                    {data?.webUrl && (
                      <Link
                        to={{ pathname: data?.webUrl }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={WebIcon}
                          alt="web"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                    {data?.iTunesId && (
                      <Link
                        to={{
                          pathname: `https://podcasts.apple.com/us/podcast/id${data?.iTunesId}`,
                        }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={AppleIcon}
                          alt="apple"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                    {socialMedia?.handles?.instagram && (
                      <Link
                        to={{
                          pathname: parseSocialMediaUrl(
                            socialMedia?.handles?.instagram,
                            'instagram'
                          ),
                        }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={InstagramIcon}
                          alt="instagram"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                    {socialMedia?.handles?.tikTok && (
                      <Link
                        to={{
                          pathname: parseSocialMediaUrl(
                            socialMedia?.handles?.tikTok,
                            'tiktok'
                          ),
                        }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={TiktokIcon}
                          alt="tikTok"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                    {socialMedia?.handles?.threads && (
                      <Link
                        to={{
                          pathname: parseSocialMediaUrl(
                            socialMedia?.handles?.threads,
                            'threads'
                          ),
                        }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={ThreadsIcon}
                          alt="threads"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                    {socialMedia?.handles?.x && (
                      <Link
                        to={{
                          pathname: parseSocialMediaUrl(
                            socialMedia?.handles?.x,
                            'x'
                          ),
                        }}
                        underline={false}
                        target="_blank"
                      >
                        <img
                          src={XIcon}
                          alt="x"
                          className={classes.mediaIcon}
                        />
                      </Link>
                    )}
                  </div>
                </div>
                <div className={classes.descriptionContainer}>
                  <Body size={BodySize.XS} className={classes.description}>
                    {description}
                  </Body>
                </div>
              </div>
              <div className={classes.rightColumn}>
                <Body size={BodySize.XS} className={classes.title}>
                  {data?.podcastTitle || ''}
                </Body>
                <div className={classes.badge}>
                  {Object.entries(data?.categories ?? {})?.map(
                    ([id, value]) => (
                      <Badge text={value} key={`${id}-${value}`} />
                    )
                  )}
                </div>
                {isApproved && (
                  <Tabs className={classes.tabs} active={0}>
                    {SummaryTabs.map((tab) => (
                      <Tab
                        className={classes.tabsContainer}
                        tab={tab.label}
                        key={tab.key}
                      >
                        {tab.key === DETAILS ? (
                          <PodcastDetails
                            podcastData={podcastData}
                            oldUrls={oldUrls}
                            images={images}
                            openImageViewer={openImageViewer}
                            setCurrentImage={setCurrentImage}
                            name={name}
                            lastName={lastName}
                            email={email}
                            createdAt={createdAt}
                          />
                        ) : null}
                        {tab.key === CURRENT ? (
                          <PodcastsCampaigns podcastId={podcastId} current />
                        ) : null}
                        {tab.key === FINISHED ? (
                          <PodcastsCampaigns
                            podcastId={podcastId}
                            current={false}
                          />
                        ) : null}
                      </Tab>
                    ))}
                  </Tabs>
                )}
                {!isApproved && (
                  <PodcastDetails
                    podcastData={podcastData}
                    oldUrls={oldUrls}
                    images={images}
                    openImageViewer={openImageViewer}
                    setCurrentImage={setCurrentImage}
                    name={name}
                    lastName={lastName}
                    email={email}
                    createdAt={createdAt}
                  />
                )}
              </div>
            </div>
            <div className={classes.bottomContent}>
              <div className={classes.buttonContainer}>
                {onAccept && (
                  <Button className={classes.button} onClick={onAcceptHandler}>
                    {approvingDocuments ? 'Ok' : 'Accept'}
                  </Button>
                )}
                {onReject && (
                  <Button
                    onClick={onRejectHandler}
                    className={classes.button}
                    color={ButtonColor.Pink}
                  >
                    {approvingDocuments ? 'Flag' : 'Reject'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PodcastFullInformation.propTypes = {
  podcast: PropTypes.shape({
    id: PropTypes.string,
    country: PropTypes.string,
    adFormats: PropTypes.arrayOf(PropTypes.string),
    adPlacements: PropTypes.arrayOf(PropTypes.string),
    podcastData: PropTypes.shape({
      podcastTitle: PropTypes.string,
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
      ),
      audienceInformation: PropTypes.shape({}),
      webUrl: PropTypes.string,
      applePodcastUrl: PropTypes.string,
      ratingAverage: PropTypes.number,
    }),
    screenshots: PropTypes.shape({
      oldUrls: PropTypes.arrayOf(PropTypes.string),
      urls: PropTypes.shape({
        audienceEstimate: PropTypes.string,
        downloadsEstimate: PropTypes.string,
      }),
      audienceEstimate: PropTypes.string,
      downloadsPerEpisode: PropTypes.string,
    }),
    hostingProvider: PropTypes.string,
    hostingProviders: PropTypes.arrayOf(
      PropTypes.shape({
        capabilities: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            enabled: PropTypes.bool,
            observations: PropTypes.string,
          })
        ),
        name: PropTypes.string,
      })
    ),
  }),
  isApproved: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onEdit: PropTypes.func,
  onEditDownloads: PropTypes.func,
  approvingDocuments: PropTypes.bool,
  approvingPodcast: PropTypes.bool,
};

PodcastFullInformation.defaultProps = {
  podcast: null,
  onEdit: null,
  onEditDownloads: null,
  approvingDocuments: false,
  approvingPodcast: false,
  isApproved: false,
  onAccept: null,
  onReject: null,
};

export default PodcastFullInformation;
