import { doc, getDoc, getFirestore } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchCampaignData = async ({ brandId, campaignId }) => {
  const firestore = getFirestore();

  const campaignRef = doc(
    firestore,
    Collections.Brands,
    brandId,
    Collections.Campaigns,
    campaignId
  );

  const campaignData = (await getDoc(campaignRef)).data();
  if (campaignData)
    return {
      ...campaignData,
      airingFrom: campaignData.airingFrom.toDate() || '',
      airingTo: campaignData.airingTo.toDate() || '',
    };
  return undefined;
};

export default fetchCampaignData;
