import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { selectFetchPendingDocumentsPodcastsState } from 'state/selectors/podcasts';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import {
  clearPendingDocumentsState,
  fetchPendingDocumentsPodcasts,
} from 'state/actions/podcasts';
import { fetchHostingProviders } from 'state/actions/generals';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import PodcasterPanel from 'components/Pages/PendingDocuments/PodcasterPanel';

import classes from './PendingDocuments.module.scss';

const PendingDocuments = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    error: errorPendingDocumentsPodcasts,
    success: successPendingDocumentsPodcasts,
    loading: loadingPendingDocumentsPodcasts,
    pendingDocumentsPodcasts,
  } = useSelector(selectFetchPendingDocumentsPodcastsState, shallowEqual);

  useEffect(() => {
    if (user) {
      dispatch(fetchPendingDocumentsPodcasts(user.network));
      dispatch(fetchHostingProviders());
    }
    return () => {
      dispatch(clearPendingDocumentsState());
    };
  }, [user]);

  const refresh = () => {
    dispatch(fetchPendingDocumentsPodcasts(user.network));
  };

  return (
    <div className={classes.container}>
      <Body
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.M}
        className={classes.title}
      >
        Pending documents
      </Body>
      <div className={classes.dataContainer}>
        {loadingPendingDocumentsPodcasts && <div>Loading...</div>}
        {errorPendingDocumentsPodcasts && <div>Error</div>}
        {successPendingDocumentsPodcasts &&
          pendingDocumentsPodcasts?.length === 0 && (
            <div>No pending documents</div>
          )}
        {successPendingDocumentsPodcasts &&
          pendingDocumentsPodcasts?.map((podcast, index) => (
            <div className={classes.panelContainer} key={podcast.id}>
              <PodcasterPanel
                podcast={podcast}
                last={pendingDocumentsPodcasts.length - 1 === index}
                refresh={refresh}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default PendingDocuments;
