import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebase';

const acceptPayment = async ({ paymentId, amount }) => {
  try {
    const processPayment = httpsCallable(functions, 'payments-processPayment');
    return await processPayment({ paymentId, amount });
  } catch (error) {
    if (
      error
        .toString()
        .includes('transfers' || 'crypto_transfers' || 'legacy_payments')
    )
      throw new Error('There is a problem with this Stripe account.');
    if (error.toString().includes("amount cannon't be zero."))
      throw new Error('Amount can not be zero.');
    if (
      error
        .toString()
        .includes(
          'The amount passed by parameter is greater than the total amount.'
        )
    )
      throw new Error(
        'The amount passed by parameter is greater than the total amount.'
      );
    throw new Error(error);
  }
};

export default acceptPayment;
