const PaymentHideFilterColumn = [
  'status',
  'total',
  'paid',
  'campaign.startDate',
  'campaign.endDate',
  'campaign.cpmRate',
  'campaign.percentages.network',
  'campaign.percentages.podcaster',
  'podcaster.podcast.impressions',
  'pay',
];

export default PaymentHideFilterColumn;
