/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Input, { Type as InputType } from 'components/Common/Input';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Modal from 'components/Common/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import Dropdown from 'components/Common/Dropdown';
import Confirmation from 'components/Common/Confirmation';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import Form from 'components/Common/Form';
import ArrowDownIcon from 'assets/icons/calendar.png';
import {
  acceptPayment,
  createPayment,
  clearPaymentsState,
} from 'state/actions/payments';
import { fetchApprovedPodcasts } from 'state/actions/podcasts';
import { selectFetchApprovedPodcastsState } from 'state/selectors/podcasts';
import {
  selectCreatePaymentState,
  selectAcceptPaymentState,
} from 'state/selectors/payments';
import { sortingOptionsApprovedPodcasts } from 'utils/podcasts/getSortingOptions';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import classNames from 'classnames';
import classes from './CreatePayment.module.scss';

const CAMPAIGN = {
  SPREAKER: 'Spreaker Programmatic',
  MEGAPHONE_SPAN: 'Megaphone SPAN',
  MEGAPHONE_RON: 'Megaphone RON',
};
const CreatePayment = ({ closeSelf }) => {
  const [campaign, setCampaign] = useState('');
  const [podcast, setPodcast] = useState({});
  const [totalAmount, setTotalAmount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [impressions, setImpressions] = useState('');
  const [minDateTo, setMinDateTo] = useState('');
  const [podcastsApproved, setPodcastsApproved] = useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const clearFormValues = () => {
    setCampaign('');
    setPodcast({ podcastData: { podcastTitle: '' } });
    setTotalAmount('');
    setEndDate('');
    setStartDate('');
    setImpressions('');
  };

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const {
    success: successFetchingApprovedPodcasts,
    approvedPodcasts,
  } = useSelector(selectFetchApprovedPodcastsState, shallowEqual);

  const {
    loading: loadingAcceptPayment,
    success: successAcceptingPayment,
  } = useSelector(selectAcceptPaymentState, shallowEqual);

  const {
    success: successCreatePayment,
    loading: loadingCreatePayment,
    createPaymentResult,
  } = useSelector(selectCreatePaymentState, shallowEqual);

  const handlePayment = () => {
    const paymentData = {
      total: parseFloat(totalAmount),
      created: new Date(Date.now()),
      paid: 0,
      campaign: {
        endDate: new Date(endDate),
        name: campaign,
        percentages: {
          network: 0,
          podcaster: 0,
        },
        startDate: new Date(startDate),
        cpmRate: 0,
      },
      partialPayments: [],
      brand: {
        name: null,
        id: null,
      },
      podcaster: {
        stripeId: podcast.stripeId,
        rimarkId: podcast.rimarkId ?? '',
        podcast: {
          name: podcast.podcastData.podcastTitle,
          id: podcast.podcastData.podcastId,
          impressions: Number(impressions),
          episodes: 0,
        },
        offerId: null,
        id: podcast.uid,
        email: podcast.email,
      },
      status: 'pending',
      network: user.network,
      processingDate: null,
    };
    onOpenModalHandler(ModalType.CREATE_PAYMENT_CONFIRMATION, { paymentData });
  };
  const checkPaymentAvaliable = () => {
    const values = [
      campaign,
      podcast,
      totalAmount,
      startDate,
      endDate,
      impressions,
    ];
    return values.every((value) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      if (value instanceof Date) {
        return true;
      }
      if (typeof value === 'object') {
        return Object.keys(value).length > 0;
      }
      return false;
    });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchApprovedPodcasts({
        ...sortingOptionsApprovedPodcasts[0],
        network: user.network,
      })
    );
  }, []);

  useEffect(() => {
    if (successFetchingApprovedPodcasts) {
      const podcastsFiltered = approvedPodcasts.filter((a) => a.stripeId);
      setPodcastsApproved(podcastsFiltered);
    }
  }, [successFetchingApprovedPodcasts]);

  useEffect(() => {
    if (successCreatePayment) {
      dispatch(
        acceptPayment({
          paymentId: createPaymentResult,
          amount: parseFloat(totalAmount),
        })
      );
      onCloseModalHandler();
    }
  }, [successCreatePayment]);

  useEffect(() => {
    if (successAcceptingPayment) {
      clearFormValues();
      dispatch(clearPaymentsState());
      closeSelf();
    }
  }, [successAcceptingPayment]);

  const onCreatePaymentHandler = useCallback(() => {
    const { paymentData } = modal;
    dispatch(createPayment({ paymentData }));
  }, [modal]);

  const startAndEndFormSchema = yup.object().shape({
    startDate: yup.date().nullable().required('Start date is required'),
    endDate: yup.date().nullable().required('End date is required'),
  });

  useEffect(() => {
    const parseStartDate = new Date(startDate);
    const oneDayInMs = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const parseEndDate = new Date(parseStartDate.getTime() + oneDayInMs);
    setMinDateTo(parseEndDate);
  }, [startDate]);

  return (
    <div className={classes.container}>
      <Form
        className={classes.form}
        validationSchema={startAndEndFormSchema}
        onSubmit={onCreatePaymentHandler}
      >
        <Modal
          isOpen={modal.type === ModalType.CREATE_PAYMENT_CONFIRMATION}
          onClose={onCloseModalHandler}
        >
          <Confirmation
            title="Process payment"
            description="Are you sure you want to process this payment?"
            onAccept={onCreatePaymentHandler}
            onCancel={onCloseModalHandler}
            loading={loadingCreatePayment}
          />
        </Modal>
        <Body size={BodySize.XS} className={classes.title}>
          CREATE PAYMENT
        </Body>
        <div className={classes.row}>
          <Body size={BodySize.XS}>Campaign</Body>
          <Button
            alt={CAMPAIGN.SPREAKER}
            className={
              campaign === CAMPAIGN.SPREAKER
                ? classNames(classes.button, classes['button--selected'])
                : classes.button
            }
            kind={ButtonKind.Secondary}
            size={ButtonSize.S}
            onClick={() => setCampaign(CAMPAIGN.SPREAKER)}
          >
            {CAMPAIGN.SPREAKER}
          </Button>
          <Button
            alt={CAMPAIGN.MEGAPHONE_SPAN}
            className={
              campaign === CAMPAIGN.MEGAPHONE_SPAN
                ? [classes.button, classes['button--selected']]
                : classes.button
            }
            kind={ButtonKind.Secondary}
            size={ButtonSize.S}
            onClick={() => setCampaign(CAMPAIGN.MEGAPHONE_SPAN)}
          >
            {CAMPAIGN.MEGAPHONE_SPAN}
          </Button>
          <Button
            alt={CAMPAIGN.MEGAPHONE_RON}
            className={
              campaign === CAMPAIGN.MEGAPHONE_RON
                ? [classes.button, classes['button--selected']]
                : classes.button
            }
            kind={ButtonKind.Secondary}
            size={ButtonSize.S}
            onClick={() => setCampaign(CAMPAIGN.MEGAPHONE_RON)}
          >
            {CAMPAIGN.MEGAPHONE_RON}
          </Button>
        </div>
        <div className={classes.row}>
          <Body size={BodySize.XS}>Podcast</Body>
          <div className={classes.column}>
            <Input
              type={InputType.Text}
              containerClassName={classes.inputContainerDropdown}
              value={podcast.podcastData?.podcastTitle}
              onChange={(v) => {
                setPodcast(v.target.value);
                setPodcastsApproved(
                  approvedPodcasts.filter(
                    (p) =>
                      p.stripeId !== '' &&
                      p.podcastData.podcastTitle
                        .toLowerCase()
                        .includes(v.target.value.toLowerCase())
                  )
                );
              }}
              name="podcast"
            />
            <Dropdown
              options={podcastsApproved.map((p) => ({
                ...p,
                label: p.podcastData?.podcastTitle,
                onClick: (setOpen) => {
                  setPodcast(p);
                  setOpen(false);
                },
              }))}
              className={classes.dropdown}
              isOpen={isDropDownOpen}
              setIsDropDownOpen={setIsDropDownOpen}
            />
          </div>
        </div>
        <div className={classes.row}>
          <Body size={BodySize.XS}>Total Amount</Body>
          <Input
            type={InputType.Number}
            containerClassName={classes.inputContainer}
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
            name="amount"
          />
        </div>
        <div className={classes.row}>
          <Body size={BodySize.XS}>From</Body>
          <FormControlDatePicker
            type={InputType.Date}
            containerClassName={classes.inputContainer}
            defaultValue={startDate}
            onManualChange={(e) => setStartDate(e)}
            name="startdate"
            icon={<img alt="start-calendar-icon" src={ArrowDownIcon} />}
          />
        </div>
        <div className={classes.row}>
          <Body size={BodySize.XS}>To</Body>
          <FormControlDatePicker
            type={InputType.Date}
            containerClassName={classes.inputContainer}
            defaultValue={endDate}
            minDate={minDateTo}
            onManualChange={(e) => setEndDate(e)}
            name="enddate"
            icon={<img alt="start-calendar-icon" src={ArrowDownIcon} />}
          />
        </div>
        <div className={classes.row}>
          <Body size={BodySize.XS}>Impressions</Body>
          <Input
            type={InputType.Number}
            containerClassName={classes.inputContainer}
            value={impressions}
            onChange={(e) => setImpressions(e.target.value)}
            name="impressions"
          />
        </div>
        <Button
          loading={loadingAcceptPayment}
          className={classes.pay}
          onClick={handlePayment}
          disabled={!checkPaymentAvaliable()}
        >
          Pay
        </Button>
      </Form>
    </div>
  );
};

CreatePayment.propTypes = {
  closeSelf: PropTypes.func.isRequired,
};

export default CreatePayment;
