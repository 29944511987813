import React from 'react';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from 'components/Pages/Podcasts/PodcastDates/PodcastDates.module.scss';

const getPodcastDates = (podcast, isApproved) => [
  {
    label: 'Sign Up Date',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {dayjs(podcast.createdAt).format('MM/DD/YYYY') || ''}
      </Body>
    ),
  },
  {
    label: `${isApproved ? 'Approved Date' : 'Rejected Date'}`,
    component: (props) => {
      const date = isApproved ? podcast.acceptedDate : podcast.rejectedDate;
      return (
        <Body {...props} size={BodySize.XS} className={classes.informationText}>
          {date ? dayjs(date).format('MM/DD/YYYY') : '-'}
        </Body>
      );
    },
  },
];

export default getPodcastDates;
