import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import parse from 'html-react-parser';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import getCampaignData from 'utils/campaigns/getCampaignData';

import classes from './CampaignDetails.module.scss';

const CampaignDetails = ({ campaign, title }) => {
  const campaignFields = getCampaignData(campaign);

  return campaign ? (
    <div className={classes.container}>
      {title && (
        <Body
          size={BodySize.S}
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          {title}
        </Body>
      )}
      <div
        className={classNames(classes.information, {
          [classes.removeMargin]: !!title,
        })}
      >
        {campaignFields.map(({ label, component: Component }) => (
          <div className={classes.item} key={label}>
            <Body size={BodySize.XS} className={classes.itemTitle}>
              {label}
            </Body>
            <Component />
          </div>
        ))}
      </div>
      <div
        className={classNames(classes.descriptionItem, {
          [classes.removeMarginDescription]: !!title,
        })}
      >
        <Body size={BodySize.XS} className={classes.itemTitle}>
          Description
        </Body>
        <Body
          size={BodySize.XS}
          className={classNames(
            classes.informationText,
            classes.informationTextDescription
          )}
        >
          {parse(campaign.description) || ''}
        </Body>
      </div>
    </div>
  ) : (
    <div className={classes.notFoundContainer}>
      <Body
        size={BodySize.S}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.M}
        className={classes.title}
      >
        Campaign data not found
      </Body>
    </div>
  );
};

CampaignDetails.propTypes = {
  campaign: PropTypes.shape({ description: PropTypes.string }),
  title: PropTypes.string,
};

CampaignDetails.defaultProps = {
  campaign: null,
  title: '',
};

export default CampaignDetails;
