import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, { Color as ButtonColor } from 'components/Common/Button';

import getBrandData from 'utils/brands/getBrandData';
import classes from './BrandSummary.module.scss';

const BrandSummary = ({ brand, onAccept, onReject, isPending, isAccepted }) => {
  const brandData = useMemo(() => {
    let data = getBrandData(brand, isAccepted);
    if (isPending) {
      data = data.filter((doc) => !doc.condition);
    }
    return data;
  }, [brand, isPending, isAccepted]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.image}>
          <img src={brand.logoUrl} alt="logo" className={classes.logo} />
        </div>
        <div className={classes.brand}>
          <Body size={BodySize.XS} className={classes.title}>
            {brand.name || 'Brand'}
          </Body>
          <div className={classes.information}>
            {brandData.map(({ label, component: Component }) => (
              <div className={classes.item} key={label}>
                <Body size={BodySize.XS} className={classes.itemTitle}>
                  {label}
                </Body>
                <Component />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        {(isPending || !isAccepted) && (
          <Button onClick={onAccept} className={classes.button}>
            Approve
          </Button>
        )}
        {(isPending || isAccepted) && (
          <Button
            onClick={onReject}
            className={classes.button}
            color={ButtonColor.Pink}
          >
            Reject
          </Button>
        )}
      </div>
    </div>
  );
};

BrandSummary.propTypes = {
  brand: PropTypes.shape({
    brandId: PropTypes.string,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    brandData: PropTypes.shape({
      description: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  }).isRequired,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  isAccepted: PropTypes.bool,
  isPending: PropTypes.bool,
};

BrandSummary.defaultProps = {
  onAccept: null,
  onReject: null,
  isAccepted: false,
  isPending: false,
};

export default BrandSummary;
