import { getDoc, doc, getFirestore } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchGeneralValues = async ({network}) => {
  try {
    const firestore = getFirestore();

    const cpmRef = doc(firestore, Collections.DefaultValues, network);
    const GeneralValues = await getDoc(cpmRef);

    return GeneralValues.data();
  } catch (error) {
    return error;
  }
};

export default fetchGeneralValues;
