import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Roles from 'enums/firebase/roles.enum';

import {
  loginInit,
  loginSuccess,
  loginFail,
  verifyAuthInit,
  verifyAuthSuccess,
  verifyAuthFail,
  logoutInit,
  logoutSuccess,
  logoutFail,
} from 'state/actionCreators/auth';
import loginService from 'services/auth/login';
import logoutService from 'services/auth/logout';
import Errors from 'enums/errors/errors.enums';
import getCustomClaims from 'services/auth/getCustomClaims';
import getAdministrator from 'services/users/getAdministrator';

export const logout = () => async (dispatch) => {
  dispatch(logoutInit());

  try {
    await logoutService();

    return dispatch(logoutSuccess());
  } catch (error) {
    return dispatch(logoutFail({ error: error.message }));
  }
};

export const login = ({ email, password }) => async (dispatch) => {
  dispatch(loginInit());

  try {
    const user = await loginService({ email, password });

    if (user) {
      return dispatch(loginSuccess(user));
    }

    dispatch(logout());

    return dispatch(loginFail({ error: Errors.InvalidCredentials }));
  } catch (error) {
    return dispatch(loginFail({ error: error.message }));
  }
};

export const verifyAuth = () => async (dispatch) => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    dispatch(verifyAuthInit());
    if (user) {
      try {
        const claims = await getCustomClaims(user);
        if (claims.role === Roles.Admin) {
          const administrators = await getAdministrator({user});

          return dispatch(
            verifyAuthSuccess({
              id: user.uid,
              email: user.email,
              role: claims.role,
              network: claims.network,
              permissions: (administrators ? administrators.permissions : []),
            })
          );
        }
        dispatch(logout());

        return dispatch(verifyAuthFail({ error: Errors.InvalidRole }));
      } catch (error) {
        return dispatch(verifyAuthFail({ error: error.message }));
      }
    }
    return dispatch(verifyAuthFail({ error: Errors.NoAuthenticatedUser }));
  });
};
