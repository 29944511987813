import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Spinner from 'components/Common/Spinner';
import Modal from 'components/Common/Modal';
import Confirmation from 'components/Common/Confirmation';
import PodcastDates from 'components/Pages/Podcasts/PodcastDates';
import PodcastFullInformation from 'components/Pages/Podcasts/PodcastFullInformation';
import Toast from 'components/Common/Toast';
import {
  selectAcceptPodcastState,
  selectFetchRejectedPodcastsState,
} from 'state/selectors/podcasts';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import {
  acceptPodcast,
  clearPodcastsState,
  fetchRejectedPodcasts,
} from 'state/actions/podcasts';
import { fetchHostingProviders } from 'state/actions/generals';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import getPodcastData from 'utils/podcasts/getPodcastData';

import classes from './RejectedPodcasts.module.scss';

const RejectedPodcasts = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    error: errorFetchingRejectedPodcasts,
    success: successFetchingRejectedPodcasts,
    loading: loadingFetchingRejectedPodcasts,
    rejectedPodcasts,
  } = useSelector(selectFetchRejectedPodcastsState, shallowEqual);

  const {
    error: errorApprovePodcast,
    success: successApprovePodcast,
    loading: loadingApprovePodcast,
  } = useSelector(selectAcceptPodcastState, shallowEqual);

  const [podcastsToShow, setPodcastsToShow] = useState([]);
  const [podcastIdToDelete, setPodcastIdToDelete] = useState(null);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (user) dispatch(fetchRejectedPodcasts(user.network));

    return () => {
      dispatch(clearPodcastsState());
    };
  }, [user]);

  useEffect(() => {
    if (successFetchingRejectedPodcasts) {
      setPodcastsToShow(rejectedPodcasts);
      dispatch(fetchHostingProviders());
      dispatch(clearPodcastsState());
    }
  }, [rejectedPodcasts, successFetchingRejectedPodcasts]);

  useEffect(() => {
    if (successApprovePodcast) {
      onCloseModalHandler();
      setPodcastsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== podcastIdToDelete)
      );
      setPodcastIdToDelete(null);
    }
  }, [successApprovePodcast, podcastIdToDelete]);

  const onClickAcceptHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.ACCEPT_PODCAST, {
      podcastId: podcast.uid,
    });
  }, []);

  const onApprovePodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);
    dispatch(acceptPodcast({ podcastId: modal.podcastId }));
  }, [modal]);

  const onLearnMoreHandler = useCallback((podcast) => {
    const podcastData = getPodcastData({ podcast });
    onOpenModalHandler(ModalType.PODCAST_SUMMARY, { podcast: podcastData });
  }, []);

  return (
    <>
      {errorFetchingRejectedPodcasts && (
        <Toast text={errorFetchingRejectedPodcasts} id="Fetch podcasts error" />
      )}
      {errorApprovePodcast && (
        <Toast text={errorApprovePodcast} id="Approve podcast error" />
      )}
      <Modal
        isOpen={modal.type === ModalType.ACCEPT_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Accept podcast"
          description="Are you sure you want to accept the selected podcast?"
          onAccept={onApprovePodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingApprovePodcast}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_SUMMARY}
        onClose={onCloseModalHandler}
      >
        <PodcastFullInformation podcast={modal.podcast} />
      </Modal>

      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Podcasts rejected
          </Body>
          {loadingFetchingRejectedPodcasts ? (
            <Spinner className={classes.spinner} />
          ) : (
            <>
              {podcastsToShow.length === 0 ? (
                <Body
                  size={BodySize.XS}
                  spacing={BodySpacing.S}
                  className={classes.noPodcasts}
                >
                  There are no podcasts rejected.
                </Body>
              ) : (
                <>
                  <div className={classes.podcasts}>
                    {podcastsToShow.map((podcast, index) => {
                      const key = `podcast-${index}`;

                      return (
                        <PodcastDates
                          key={key}
                          podcast={podcast}
                          onAction={() => onClickAcceptHandler(podcast)}
                          isAccepted={false}
                          learnMoreHandler={() => onLearnMoreHandler(podcast)}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RejectedPodcasts;
