import { createAction } from '@reduxjs/toolkit';

export const fetchPendingPodcastsInit = createAction(
  '[Podcasts] Fetch Pending Podcasts Init'
);
export const fetchPendingPodcastsSuccess = createAction(
  '[Podcasts] Fetch Pending Podcasts Success'
);
export const fetchPendingPodcastsFail = createAction(
  '[Podcasts] Fetch Pending Podcasts Fail'
);

export const fetchApprovedPodcastsInit = createAction(
  '[Podcasts] Fetch Approved Podcasts Init'
);
export const fetchApprovedPodcastsSuccess = createAction(
  '[Podcasts] Fetch Approved Podcasts Success'
);
export const fetchApprovedPodcastsFail = createAction(
  '[Podcasts] Fetch Approved Podcasts Fail'
);

export const fetchRejectedPodcastsInit = createAction(
  '[Podcasts] Fetch Rejected Podcasts Init'
);
export const fetchRejectedPodcastsSuccess = createAction(
  '[Podcasts] Fetch Rejected Podcasts Success'
);
export const fetchRejectedPodcastsFail = createAction(
  '[Podcasts] Fetch Rejected Podcasts Fail'
);

export const fetchPendingDocumentsPodcastsInit = createAction(
  '[Podcasts] Fetch Pending Documents Podcasts Init'
);
export const fetchPendingDocumentsPodcastsSuccess = createAction(
  '[Podcasts] Fetch Pending Documents Podcasts Success'
);
export const fetchPendingDocumentsPodcastsFail = createAction(
  '[Podcasts] Fetch Pending Documents Podcasts Fail'
);

export const acceptPodcastInit = createAction('[Podcasts] Accept Podcast Init');
export const acceptPodcastSuccess = createAction(
  '[Podcasts] Accept Podcast Success'
);
export const acceptPodcastFail = createAction('[Podcasts] Accept Podcast Fail');

export const sendToNetworkPodcastInit = createAction(
  '[Podcasts] Send To Network Podcast Init'
);

export const sendToNetworkPodcastLoading = createAction(
  '[Podcasts] Send To Network Podcast Loading'
);

export const sendToNetworkPodcastSuccess = createAction(
  '[Podcasts] Send To Network Podcast Success'
);
export const sendToNetworkPodcastFail = createAction(
  '[Podcasts] Send To Network Podcast Fail'
);

export const rejectPodcastInit = createAction('[Podcasts] Reject Podcast Init');
export const rejectPodcastSuccess = createAction(
  '[Podcasts] Reject Podcast Success'
);
export const rejectPodcastFail = createAction('[Podcasts] Reject Podcast Fail');

export const acceptPendingDocumentInit = createAction(
  '[Podcasts] Accept Pending Document Init'
);
export const acceptPendingDocumentSuccess = createAction(
  '[Podcasts] Accept Pending Document Success'
);

export const acceptPendingDocumentFail = createAction(
  '[Podcasts] Accept Pending Document Fail'
);
export const flagPendingDocumentInit = createAction(
  '[Podcasts] Flag Pending Document Init'
);
export const flagPendingDocumentSuccess = createAction(
  '[Podcasts] Flag Pending Document Success'
);
export const flagPendingDocumentFail = createAction(
  '[Podcasts] Flag Pending Document Fail'
);
export const editPendingDocumentInit = createAction(
  '[Podcasts] Edit Pending Document Init'
);
export const editPendingDocumentSuccess = createAction(
  '[Podcasts] Edit Pending Document Success'
);
export const editPendingDocumentFail = createAction(
  '[Podcasts] Edit Pending Document Fail'
);
export const editPodcastDataInit = createAction(
  '[Podcasts] Edit Podcast Data Init'
);
export const editPodcastDataSuccess = createAction(
  '[Podcasts] Edit Podcast Data Success'
);
export const editPodcastDataFail = createAction(
  '[Podcasts] Edit Podcast Data Fail'
);

export const clearPodcasts = createAction('[Podcasts] Clear Podcasts');

export const clearAllPodcasts = createAction('[Podcasts] Clear All Podcasts');

export const clearPendingDocuments = createAction(
  '[Podcasts] Clear Pending Documents'
);

export const clearEditPodcastData = createAction(
  '[Podcasts] Clear Edit Podcast Data'
);

export const clearEditPendingDocument = createAction(
  '[Podcasts] Clear Edit Pending Document'
);

export const hiatusPodcastInit = createAction('[Podcasts] Hiatus Podcast Init');

export const hiatusPodcastLoading = createAction(
  '[Podcasts] Hiatus Podcast Loading'
);

export const hiatusPodcastSuccess = createAction(
  '[Podcasts] Hiatus Podcast Success'
);
export const hiatusPodcastFail = createAction('[Podcasts] Hiatus Podcast Fail');

export const fetchPodcastOffersInit = createAction(
  '[Podcasts] Fetch Podcast Offers Init'
);

export const fetchPodcastOffersLoading = createAction(
  '[Podcasts] Fetch Podcast Offers Loading'
);

export const fetchPodcastOffersSuccess = createAction(
  '[Podcasts] Fetch Podcast Offers Success'
);
export const fetchPodcastOffersFail = createAction(
  '[Podcasts] Fetch Podcast Offers Fail'
);
