import { getFirestore, doc, updateDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const updateGeneralValues = async (value, network = 'ossa') => {
  try {
    const firestore = getFirestore();

    const cpmRef = doc(firestore, Collections.DefaultValues, network);

    await updateDoc(cpmRef, value);
    return value;
  } catch (error) {
    return error;
  }
};

export default updateGeneralValues;
