/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-unused-vars
import { useCallback, useEffect, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import Tabs from 'components/Common/Tabs';
import Modal from 'components/Common/Modal';
import Tab from 'components/Common/Tab';
import Toast from 'components/Common/Toast';
import Spinner, { Size as SpinnerSize } from 'components/Common/Spinner';
import Slider from 'components/Common/Slider';
import Table from 'components/Common/Table';
import { brandName } from 'assets/white-label';
import Switch from 'components/Common/Switch';

import CreateUser from 'components/Pages/Settings/CreateUser';

import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import Confirmation from 'components/Common/Confirmation';
import PermissionsAccess from 'enums/generals/permissionsAccess.enum';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import {
  selectFetchGeneralValuesState,
  selectUpdateGeneralValuesState,
} from 'state/selectors/generals';
import {
  selectFetchUsersState,
  selectCreateUserState,
  selectUpdateUserState,
  selectDeleteUserState,
} from 'state/selectors/users';
import {
  fetchGeneralValues,
  updateGeneralValues,
  resetGeneralValuesState,
} from 'state/actions/generals';
import {
  fetchUsers,
  updateUser,
  deleteUser,
  resetUserState,
} from 'state/actions/users';

import classes from './Settings.module.scss';

const settingsTabs = {
  General: 'General',
  Roles: 'Roles',
};

const Settings = () => {
  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState();
  const [userSelected, setUserSelected] = useState(undefined);
  const [saveUser, setSaveUser] = useState(false);
  const [cpmRate, setCpmRate] = useState();
  const [showToast, setShowToast] = useState(false);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    generalValues,
    success: successFetchingGeneralValues,
    loading: loadingFetchingGeneralValues,
    error: errorFetchingGeneralValues,
  } = useSelector(selectFetchGeneralValuesState, shallowEqual);

  const {
    success: successUpdateGeneralValues,
    loading: loadingUpdateGeneralValues,
    error: errorUpdateGeneralValues,
  } = useSelector(selectUpdateGeneralValuesState, shallowEqual);

  const {
    loading: loadingFetchingUsers,
    error: errorFetchingUsers,
    users,
  } = useSelector(selectFetchUsersState, shallowEqual);

  const { success: successUpdateUser, error: errorUpdateUser } = useSelector(
    selectUpdateUserState,
    shallowEqual
  );

  const { success: successCreateUser, error: errorCreateUser } = useSelector(
    selectCreateUserState,
    shallowEqual
  );

  const { success: successDeleteUser, error: errorDeleteUser } = useSelector(
    selectDeleteUserState,
    shallowEqual
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchUsers({ network: user.network }));
      dispatch(fetchGeneralValues({ network: user.network }));
    }
  }, [user, userSelected]);

  /*
  General Tab
  */

  // Initializing general values
  useEffect(() => {
    if (successFetchingGeneralValues && !loadingFetchingGeneralValues) {
      setCpmRate(generalValues?.cpmRate);
      setSliderValue((generalValues.percentages?.podcaster || 0.5) * 100);
    }
  }, [successFetchingGeneralValues, loadingFetchingGeneralValues]);

  const onClickHandler = () => {
    const networkPercentage = Number((1 - sliderValue / 100).toFixed(2));
    const podcastPercentage = Number((sliderValue / 100).toFixed(2));
    const newGeneralValues = {
      cpmRate: parseInt(cpmRate, 10),
      percentages: {
        network: networkPercentage,
        podcaster: podcastPercentage,
      },
    };
    dispatch(resetUserState());
    setShowToast(true);
    dispatch(updateGeneralValues(newGeneralValues));
  };

  const onlyNumbersRegex = /^[0-9]*$/;
  const checkCPMValidity = useCallback(
    (cpmToUpdate) => cpmToUpdate.match(onlyNumbersRegex),
    []
  );

  const onChangeInputHandler = useCallback(
    (event) => {
      setShowToast(false);
      if (checkCPMValidity(event.target.value)) {
        setCpmRate(event.target.value);
      }
    },
    [checkCPMValidity]
  );

  const changeSliderPosition = (value) => {
    setShowToast(false);
    setSliderValue(value);
  };

  useEffect(() => {
    if (successUpdateGeneralValues || errorUpdateGeneralValues) {
      dispatch(resetGeneralValuesState());
    }
  }, [successUpdateGeneralValues, errorUpdateGeneralValues]);

  // Roles
  useEffect(() => {
    if (
      errorFetchingUsers ||
      successCreateUser ||
      errorCreateUser ||
      successDeleteUser ||
      errorDeleteUser
    ) {
      setShowToast(true);
      if (successCreateUser || successDeleteUser) {
        dispatch(fetchUsers({ network: user.network }));
      }
    }
  }, [
    errorFetchingUsers,
    successCreateUser,
    errorCreateUser,
    successDeleteUser,
    errorDeleteUser,
  ]);

  const onClickCreateUserHandler = useCallback(() => {
    onOpenModalHandler(ModalType.CREATE_USER);
  }, []);

  useEffect(() => {
    if (saveUser && userSelected) {
      setShowToast(true);
      dispatch(updateUser(userSelected));
      setSaveUser(false);
    }
  }, [saveUser, userSelected]);

  const setPermission = (obj) => {
    dispatch(resetUserState());
    const newAdmin = { ...userSelected };
    newAdmin.permissions = {
      ...newAdmin.permissions,
      [obj[0]]: !newAdmin.permissions[obj[0]],
    };
    setUserSelected(newAdmin);
    setSaveUser(true);
  };

  useEffect(() => {
    if (successUpdateUser || errorUpdateUser) {
      setShowToast(false);
      dispatch(resetUserState());
    }
  }, [successUpdateUser, errorUpdateUser]);

  const onSelect = async (row) => {
    dispatch(resetUserState());
    setUserSelected(row.original);
  };

  const onDelete = async (row) => {
    const result = await dispatch(deleteUser({ user: row.original }));
    if (result) dispatch(fetchUsers({ network: user.network }));
    onCloseModalHandler();
  };

  const onDeleteHandler = (row) => {
    onDelete(row);
  };

  const getEmailListColumns = () => [
    {
      Header: 'header',
      columns: [
        {
          Header: 'Email',
          accessor: 'email',
          columnName: 'email',
          Cell: ({ row }) => (
            <div
              data-testid="email"
              className={classes.email}
              onClick={() => onSelect(row)}
            >
              {row.original.email}
            </div>
          ),
        },
        {
          Header: '',
          accessor: 'onDelete',
          columnName: 'onDelete',
          Cell: ({ row }) => (
            <div
              data-testid="delete"
              className={classes.tableButton}
              onClick={() =>
                onOpenModalHandler(ModalType.DELETE_USER_CONFIRMATION, { row })
              }
            >
              DELETE
            </div>
          ),
        },
      ],
    },
  ];

  const columns = useMemo(() => getEmailListColumns(), []);

  return (
    <>
      {showToast && successCreateUser && (
        <Toast
          text="User Created Successfully"
          id="Create user success"
          type="success"
        />
      )}{' '}
      {showToast && errorCreateUser && (
        <Toast text={errorCreateUser} id="Create user error" />
      )}{' '}
      {showToast && successUpdateGeneralValues && (
        <Toast
          text="Values Updated Successfully"
          id="Update general values success"
          type="success"
        />
      )}{' '}
      {showToast && errorUpdateGeneralValues && (
        <Toast text="Error Updating Values" id="Update general values error" />
      )}
      {showToast && successUpdateUser && (
        <Toast
          text="User Updated Successfully"
          id="Update user values success"
          type="success"
        />
      )}
      {showToast && errorUpdateUser && (
        <Toast text="Error Update User" id="Update user error" />
      )}
      {showToast && errorFetchingUsers && (
        <Toast text="Error Fetching User" id="Fetch users error" />
      )}{' '}
      {showToast && successDeleteUser && (
        <Toast
          text="User Deleted Successfully"
          id="Delete user success"
          type="success"
        />
      )}{' '}
      {showToast && errorDeleteUser && (
        <Toast text={errorDeleteUser} id="Delete user error" />
      )}{' '}
      <div className={classes.container}>
        <Modal
          isOpen={modal.type === ModalType.DELETE_USER_CONFIRMATION}
          onClose={onCloseModalHandler}
        >
          <Confirmation
            title="Delete user"
            description="Are you sure you want to delete this user?"
            onAccept={() => onDeleteHandler(modal.row)}
            onCancel={onCloseModalHandler}
            loading={false}
          />
        </Modal>
        <Modal
          isOpen={modal.type === ModalType.CREATE_USER}
          onClose={onCloseModalHandler}
        >
          <CreateUser closeSelf={onCloseModalHandler} />
        </Modal>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Settings
        </Body>
        <Tabs className={classes.tabsContainer} active={1}>
          <Tab key={0} tab={settingsTabs.General} className={classes.tabs}>
            {loadingFetchingGeneralValues ? (
              <Spinner
                size={SpinnerSize.L}
                className={classes.spinnerContainer}
              />
            ) : (
              <div className={classes.controls}>
                <div className={classes.row}>
                  <div className={classes.descriptions}>
                    <Body className={classes.title}>CPM</Body>
                    <span className={classes.text}>
                      This will change the CPM rates across the whole&nbsp;
                      {brandName} platform
                    </span>
                  </div>
                  <div className={classes.inputContainer}>
                    <Body
                      className={classNames([classes.title, classes.cashIcon])}
                    >
                      $
                    </Body>
                    <Input
                      data-testid="cpmRate"
                      name="cpmRate"
                      value={cpmRate}
                      onChange={(event) => onChangeInputHandler(event)}
                      type={InputType.Text}
                      size={InputSize.M}
                    />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.descriptions}>
                    <Body className={classes.title}>Split</Body>
                    <span className={classes.text}>
                      {100 - sliderValue}% {brandName} / {sliderValue}%
                      Podcaster
                    </span>
                  </div>
                  <div className={classes.inputContainer}>
                    <Slider
                      data-testid="slider"
                      className={classes.slider}
                      startText={brandName}
                      endText="Podcaster"
                      min={0}
                      value={sliderValue}
                      max={100}
                      step={1}
                      marks={{ 50: '' }}
                      onChange={changeSliderPosition}
                    />
                  </div>
                </div>
                {!loadingFetchingGeneralValues && (
                  <div className={classes.bottomControl}>
                    <p>{errorFetchingGeneralValues}</p>
                    <Button
                      className={classes.button}
                      onClick={onClickHandler}
                      loading={loadingUpdateGeneralValues}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Tab>
          <Tab key={1} tab={settingsTabs.Roles} className={classes.tabs}>
            {loadingFetchingUsers ? (
              <>
                <Spinner
                  size={SpinnerSize.L}
                  className={classes.spinnerContainer}
                />
              </>
            ) : (
              <div className={classes.rolesContainer}>
                <div className={classes.row}>
                  <Table columns={columns} data={users} showFilter={false} />
                </div>
                <div className={classes.row}>
                  <Button
                    className={classes.button}
                    kind={ButtonKind.Tertiary}
                    size={ButtonSize.S}
                    onClick={onClickCreateUserHandler}
                  >
                    New User
                  </Button>
                  {userSelected && (
                    <div className={classes.permissionsContainer}>
                      {userSelected &&
                        Object.entries(userSelected.permissions)
                          .sort((a, b) => a[0].localeCompare(b[0]))
                          .map((obj, index) => {
                            const key = `permission-${index}`;
                            return (
                              <div key={key}>
                                <Switch
                                  data-testid="switch"
                                  className={classes.switcher}
                                  label={
                                    Object.entries(PermissionsAccess)
                                      .map((p) => (p[0] === obj[0] ? p[1] : ''))
                                      .filter((e) => e !== '')[0]
                                  }
                                  onClickSwitch={() => setPermission(obj)}
                                  checked={obj[1]}
                                />
                              </div>
                            );
                          })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Settings;
