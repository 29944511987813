import { createSelector } from '@reduxjs/toolkit';

const selectGeneralsState = ({ generals }) => generals;

export const selectFetchGeneralValuesState = createSelector(
  selectGeneralsState,
  ({
    fetchGeneralValuesLoading,
    fetchGeneralValuesSuccess,
    fetchGeneralValuesError,
    generalValues,
  }) => ({
    loading: fetchGeneralValuesLoading,
    success: fetchGeneralValuesSuccess,
    error: fetchGeneralValuesError,
    generalValues,
  })
);

export const selectUpdateGeneralValuesState = createSelector(
  selectGeneralsState,
  ({
    updateGeneralValuesLoading,
    updateGeneralValuesSuccess,
    updateGeneralValuesError,
  }) => ({
    loading: updateGeneralValuesLoading,
    success: updateGeneralValuesSuccess,
    error: updateGeneralValuesError,
  })
);

export const selectFetchHostingProviders = createSelector(
  selectGeneralsState,
  ({
    fetchHostingProvidersSuccess,
    fetchHostingProvidersError,
    hostingProviders,
  }) => ({
    success: fetchHostingProvidersSuccess,
    error: fetchHostingProvidersError,
    hostingProviders,
  })
);