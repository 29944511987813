import {
  fetchCurrentCampaignsInit,
  fetchCurrentCampaignsSuccess,
  fetchCurrentCampaignsFail,
  fetchFinishedCampaignsInit,
  fetchFinishedCampaignsSuccess,
  fetchFinishedCampaignsFail,
  clearCampaigns,
  sendAdCopyInit,
  sendAdCopySuccess,
  sendAdCopyFail,
  clearAdCopy,
} from 'state/actionCreators/campaigns';
import fetchCurrentCampaignsService from 'services/campaigns/fetchCurrentCampaigns';
import fetchFinishedCampaignsService from 'services/campaigns/fetchFinishedCampaigns';
import sendAdCopyService from 'services/campaigns/sendAdCopy';

export const fetchCurrentCampaigns = ({ brandId }) => async (dispatch) => {
  dispatch(fetchCurrentCampaignsInit());

  try {
    const campaigns = await fetchCurrentCampaignsService({
      brandId,
    });

    let transformedCampaigns = null;

    if (campaigns) {
      transformedCampaigns = campaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }

    return dispatch(
      fetchCurrentCampaignsSuccess({ campaigns: transformedCampaigns })
    );
  } catch (error) {
    return dispatch(fetchCurrentCampaignsFail({ error: error.message }));
  }
};

export const fetchFinishedCampaigns = ({ brandId }) => async (dispatch) => {
  dispatch(fetchFinishedCampaignsInit());

  try {
    const campaigns = await fetchFinishedCampaignsService({
      brandId,
    });

    let transformedCampaigns = null;

    if (campaigns) {
      transformedCampaigns = campaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }

    return dispatch(
      fetchFinishedCampaignsSuccess({ campaigns: transformedCampaigns })
    );
  } catch (error) {
    return dispatch(fetchFinishedCampaignsFail({ error: error.message }));
  }
};

export const sendAdCopy = ({ campaign, podcastId, adCopy, brandId }) => async (
  dispatch
) => {
  dispatch(sendAdCopyInit());

  try {
    await sendAdCopyService({
      brandId,
      campaign,
      podcastId,
      adCopy,
    });

    return dispatch(sendAdCopySuccess());
  } catch (error) {
    return dispatch(sendAdCopyFail({ error: error.message }));
  }
};

export const clearCampaignState = () => clearCampaigns();

export const clearAdCopyState = () => clearAdCopy();
