const PermissionsAccess = {
  approveBrands: 'BRANDS FOR APPROVAL',
  approvePendingDocuments: 'PENDING DOCUMENTS',
  approvePodcasters: 'PODCASTS FOR APPROVAL',
  seeApprovedBrands: 'BRANDS APPROVED',
  seeApprovedPodcasters: 'PODCASTS APPROVED',
  seeRejectedBrands: 'BRANDS REJECTED',
  seeRejectedPodcasters: 'PODCASTS REJECTED',
  settings: 'SETTINGS',
  campaigns: 'CAMPAIGNS',
  payments: 'PAYMENTS',
};

export default PermissionsAccess;
