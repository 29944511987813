const filterPodcastsByAudience = (podcasts, order) => {
  podcasts.sort((a, b) => {
    const audienceA = parseInt(a.podcastData.audienceEstimate, 10) || 0;
    const audienceB = parseInt(b.podcastData.audienceEstimate, 10) || 0;
    if (order === 'asc') {
      return audienceA - audienceB;
    }
    return audienceB - audienceA;
  });
  return podcasts;
};

export default filterPodcastsByAudience;
