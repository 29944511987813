/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPendingPodcastsInit,
  fetchPendingPodcastsSuccess,
  fetchPendingPodcastsFail,
  fetchApprovedPodcastsInit,
  fetchApprovedPodcastsSuccess,
  fetchApprovedPodcastsFail,
  fetchRejectedPodcastsInit,
  fetchRejectedPodcastsSuccess,
  fetchRejectedPodcastsFail,
  fetchPendingDocumentsPodcastsInit,
  fetchPendingDocumentsPodcastsSuccess,
  fetchPendingDocumentsPodcastsFail,
  acceptPodcastInit,
  acceptPodcastSuccess,
  acceptPodcastFail,
  rejectPodcastInit,
  rejectPodcastSuccess,
  rejectPodcastFail,
  sendToNetworkPodcastInit,
  sendToNetworkPodcastLoading,
  sendToNetworkPodcastFail,
  sendToNetworkPodcastSuccess,
  clearPodcasts,
  clearAllPodcasts,
  clearPendingDocuments,
  editPendingDocumentInit,
  editPendingDocumentSuccess,
  editPendingDocumentFail,
  clearEditPendingDocument,
  editPodcastDataInit,
  editPodcastDataSuccess,
  editPodcastDataFail,
  clearEditPodcastData,
  hiatusPodcastFail,
  hiatusPodcastLoading,
  hiatusPodcastInit,
  hiatusPodcastSuccess,
  fetchPodcastOffersFail,
  fetchPodcastOffersLoading,
  fetchPodcastOffersInit,
  fetchPodcastOffersSuccess,
} from 'state/actionCreators/podcasts';

export const initialState = {
  fetchPendingPodcastsLoading: false,
  fetchPendingPodcastsSuccess: false,
  fetchPendingPodcastsError: null,
  fetchApprovedPodcastsLoading: false,
  fetchApprovedPodcastsSuccess: false,
  fetchApprovedPodcastsError: null,
  fetchRejectedPodcastsLoading: false,
  fetchRejectedPodcastsSuccess: false,
  fetchRejectedPodcastsError: null,
  fetchPendingDocumentsPodcastsLoading: false,
  fetchPendingDocumentsPodcastsSuccess: false,
  fetchPendingDocumentsPodcastsError: null,
  acceptPodcastLoading: false,
  acceptPodcastSuccess: false,
  acceptPodcastError: null,
  rejectPodcastLoading: false,
  rejectPodcastSuccess: false,
  rejectPodcastError: null,
  sendToNetworkPodcastLoading: false,
  sendToNetworkPodcastSuccess: false,
  sendToNetworkPodcastError: null,
  editPendingDocumentLoading: false,
  editPendingDocumentError: null,
  editPendingDocumentSuccess: false,
  editPodcastDataLoading: false,
  editPodcastDataError: null,
  editPodcastDataSuccess: false,
  pendingPodcasts: [],
  approvedPodcasts: [],
  rejectedPodcasts: [],
  pendingDocumentsPodcasts: [],
  lastPaginationPodcast: null,
  hiatusPodcastLoading: false,
  hiatusPodcastSuccess: false,
  hiatusPodcastError: null,
  fetchPodcastOffersLoading: false,
  fetchPodcastOffersSuccess: false,
  fetchPodcastOffersError: null,
  podcastOffers: [],
};

const podcastsReducer = createReducer(initialState, {
  [fetchPendingPodcastsInit]: (state) => {
    state.fetchPendingPodcastsLoading = true;
    state.fetchPendingPodcastsSuccess = false;
    state.fetchPendingPodcastsError = null;
    state.pendingPodcasts = [];
  },
  [fetchPendingPodcastsSuccess]: (state, { payload }) => {
    const { lastPodcast, pendingPodcasts } = payload;
    state.fetchPendingPodcastsLoading = false;
    state.fetchPendingPodcastsSuccess = true;
    state.fetchPendingPodcastsError = null;
    state.pendingPodcasts = pendingPodcasts;
    state.lastPaginationPodcast = lastPodcast;
  },
  [fetchPendingPodcastsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPendingPodcastsLoading = false;
    state.fetchPendingPodcastsSuccess = false;
    state.fetchPendingPodcastsError = error;
  },
  [fetchApprovedPodcastsInit]: (state) => {
    state.fetchApprovedPodcastsLoading = true;
    state.fetchApprovedPodcastsSuccess = false;
    state.fetchApprovedPodcastsError = null;
    state.approvedPodcasts = [];
  },
  [fetchApprovedPodcastsSuccess]: (state, { payload }) => {
    const { approvedPodcasts } = payload;
    state.fetchApprovedPodcastsLoading = false;
    state.fetchApprovedPodcastsSuccess = true;
    state.fetchApprovedPodcastsError = null;
    state.approvedPodcasts = approvedPodcasts;
  },
  [fetchApprovedPodcastsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchApprovedPodcastsLoading = false;
    state.fetchApprovedPodcastsSuccess = false;
    state.fetchApprovedPodcastsError = error;
  },
  [fetchRejectedPodcastsInit]: (state) => {
    state.fetchRejectedPodcastsLoading = true;
    state.fetchRejectedPodcastsSuccess = false;
    state.fetchRejectedPodcastsError = null;
    state.rejectedPodcasts = [];
  },
  [fetchRejectedPodcastsSuccess]: (state, { payload }) => {
    const { rejectedPodcasts } = payload;
    state.fetchRejectedPodcastsLoading = false;
    state.fetchRejectedPodcastsSuccess = true;
    state.fetchRejectedPodcastsError = null;
    state.rejectedPodcasts = rejectedPodcasts;
  },
  [fetchRejectedPodcastsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchRejectedPodcastsLoading = false;
    state.fetchRejectedPodcastsSuccess = false;
    state.fetchRejectedPodcastsError = error;
  },
  [fetchPendingDocumentsPodcastsInit]: (state) => {
    state.fetchPendingDocumentsPodcastsLoading = true;
    state.fetchPendingDocumentsPodcastsSuccess = false;
    state.fetchPendingDocumentsPodcastsError = null;
    state.pendingDocumentsPodcasts = [];
  },
  [fetchPendingDocumentsPodcastsSuccess]: (state, { payload }) => {
    const { pendingDocumentsPodcasts } = payload;
    state.fetchPendingDocumentsPodcastsLoading = false;
    state.fetchPendingDocumentsPodcastsSuccess = true;
    state.fetchPendingDocumentsPodcastsError = null;
    state.pendingDocumentsPodcasts = pendingDocumentsPodcasts;
  },
  [fetchPendingDocumentsPodcastsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPendingDocumentsPodcastsLoading = false;
    state.fetchPendingDocumentsPodcastsSuccess = false;
    state.fetchPendingDocumentsPodcastsError = error;
  },
  [acceptPodcastInit]: (state) => {
    state.acceptPodcastLoading = true;
    state.acceptPodcastSuccess = false;
    state.acceptPodcastError = null;
  },
  [acceptPodcastSuccess]: (state) => {
    state.acceptPodcastLoading = false;
    state.acceptPodcastSuccess = true;
    state.acceptPodcastError = null;
  },
  [acceptPodcastFail]: (state, { payload }) => {
    const { error } = payload;
    state.acceptPodcastLoading = false;
    state.acceptPodcastSuccess = false;
    state.acceptPodcastError = error;
  },
  [rejectPodcastInit]: (state) => {
    state.rejectPodcastLoading = true;
    state.rejectPodcastSuccess = false;
    state.rejectPodcastError = null;
  },
  [rejectPodcastSuccess]: (state) => {
    state.rejectPodcastLoading = false;
    state.rejectPodcastSuccess = true;
    state.rejectPodcastError = null;
  },
  [rejectPodcastFail]: (state, { payload }) => {
    const { error } = payload;
    state.rejectPodcastLoading = false;
    state.rejectPodcastSuccess = false;
    state.rejectPodcastError = error;
  },
  [sendToNetworkPodcastInit]: (state) => {
    state.sendToNetworkPodcastLoading = false;
    state.sendToNetworkPodcastSuccess = false;
    state.sendToNetworkPodcastError = null;
  },
  [sendToNetworkPodcastLoading]: (state) => {
    state.sendToNetworkPodcastLoading = true;
    state.sendToNetworkPodcastSuccess = false;
    state.sendToNetworkPodcastError = null;
  },
  [sendToNetworkPodcastSuccess]: (state) => {
    state.sendToNetworkPodcastLoading = false;
    state.sendToNetworkPodcastSuccess = true;
    state.sendToNetworkPodcastError = null;
  },
  [sendToNetworkPodcastFail]: (state, { payload }) => {
    const { error } = payload;
    state.sendToNetworkPodcastLoading = false;
    state.sendToNetworkPodcastSuccess = false;
    state.sendToNetworkPodcastError = error;
  },
  [hiatusPodcastInit]: (state) => {
    state.hiatusPodcastLoading = false;
    state.hiatusPodcastSuccess = false;
    state.hiatusPodcastError = null;
  },
  [hiatusPodcastLoading]: (state) => {
    state.hiatusPodcastLoading = true;
    state.hiatusPodcastSuccess = false;
    state.hiatusPodcastError = null;
  },
  [hiatusPodcastSuccess]: (state) => {
    state.hiatusPodcastLoading = false;
    state.hiatusPodcastSuccess = true;
    state.hiatusPodcastError = null;
  },
  [hiatusPodcastFail]: (state, { payload }) => {
    const { error } = payload;
    state.hiatusPodcastLoading = false;
    state.hiatusPodcastSuccess = false;
    state.hiatusPodcastError = error;
  },
  [fetchPodcastOffersInit]: (state) => {
    state.fetchPodcastOffersLoading = false;
    state.fetchPodcastOffersSuccess = false;
    state.fetchPodcastOffersError = null;
  },
  [fetchPodcastOffersLoading]: (state) => {
    state.fetchPodcastOffersLoading = true;
    state.fetchPodcastOffersSuccess = false;
    state.fetchPodcastOffersError = null;
  },
  [fetchPodcastOffersSuccess]: (state, { payload }) => {
    const { podcastOffers } = payload;
    state.fetchPodcastOffersLoading = false;
    state.fetchPodcastOffersSuccess = true;
    state.fetchPodcastOffersError = null;
    state.podcastOffers = podcastOffers;
  },
  [fetchPodcastOffersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPodcastOffersLoading = false;
    state.fetchPodcastOffersSuccess = false;
    state.fetchPodcastOffersError = error;
  },
  [editPendingDocumentInit]: (state) => {
    state.editPendingDocumentLoading = true;
    state.editPendingDocumentError = null;
    state.editPendingDocumentSuccess = false;
  },
  [editPendingDocumentSuccess]: (state) => {
    state.editPendingDocumentLoading = false;
    state.editPendingDocumentSuccess = true;
    state.editPendingDocumentError = null;
  },
  [editPendingDocumentFail]: (state, { payload }) => {
    const { error } = payload;
    state.editPendingDocumentLoading = false;
    state.editPendingDocumentSuccess = false;
    state.editPendingDocumentError = error;
  },
  [editPodcastDataInit]: (state) => {
    state.editPodcastDataLoading = true;
    state.editPodcastDataError = null;
    state.editPodcastDataSuccess = false;
  },
  [editPodcastDataSuccess]: (state) => {
    state.editPodcastDataLoading = false;
    state.editPodcastDataSuccess = true;
    state.editPodcastDataError = null;
  },
  [editPodcastDataFail]: (state, { payload }) => {
    const { error } = payload;
    state.editPodcastDataLoading = false;
    state.editPodcastDataSuccess = false;
    state.editPodcastDataError = error;
  },
  [clearPodcasts]: (state) => {
    state.fetchPendingPodcastsLoading = false;
    state.fetchPendingPodcastsSuccess = false;
    state.fetchPendingPodcastsError = null;
    state.fetchApprovedPodcastsLoading = false;
    state.fetchApprovedPodcastsSuccess = false;
    state.fetchApprovedPodcastsError = null;
    state.fetchRejectedPodcastsLoading = false;
    state.fetchRejectedPodcastsSuccess = false;
    state.fetchRejectedPodcastsError = null;
    state.acceptPodcastLoading = false;
    state.acceptPodcastSuccess = false;
    state.acceptPodcastError = null;
    state.rejectPodcastLoading = false;
    state.rejectPodcastSuccess = false;
    state.rejectPodcastError = null;
  },
  [clearAllPodcasts]: (state) => {
    state.fetchPendingPodcastsLoading = false;
    state.fetchPendingPodcastsSuccess = false;
    state.fetchPendingPodcastsError = null;
    state.fetchApprovedPodcastsLoading = false;
    state.fetchApprovedPodcastsSuccess = false;
    state.fetchApprovedPodcastsError = null;
    state.fetchRejectedPodcastsLoading = false;
    state.fetchRejectedPodcastsSuccess = false;
    state.fetchRejectedPodcastsError = null;
    state.acceptPodcastLoading = false;
    state.acceptPodcastSuccess = false;
    state.acceptPodcastError = null;
    state.rejectPodcastLoading = false;
    state.rejectPodcastSuccess = false;
    state.rejectPodcastError = null;
    state.pendingPodcasts = [];
    state.approvedPodcasts = [];
    state.rejectedPodcasts = [];
    state.lastPaginationPodcast = null;
  },
  [clearPendingDocuments]: (state) => {
    state.fetchPendingDocumentsPodcastsLoading = false;
    state.fetchPendingDocumentsPodcastsSuccess = false;
    state.fetchPendingDocumentsPodcastsError = null;
    state.pendingDocumentsPodcasts = [];
  },
  [clearEditPodcastData]: (state) => {
    state.editPodcastDataLoading = false;
    state.editPodcastDataSuccess = false;
    state.editPodcastDataError = null;
  },
  [clearEditPendingDocument]: (state) => {
    state.editPendingDocumentLoading = false;
    state.editPendingDocumentSuccess = false;
    state.editPendingDocumentError = null;
  },
});

export default podcastsReducer;
