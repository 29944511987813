import { createSelector } from '@reduxjs/toolkit';

const selectBrandsState = ({ brands }) => brands;

export const selectFetchPendingBrandsState = createSelector(
  selectBrandsState,
  ({
    fetchPendingBrandsLoading,
    fetchPendingBrandsSuccess,
    fetchPendingBrandsError,
    pendingBrands,
  }) => ({
    loading: fetchPendingBrandsLoading,
    success: fetchPendingBrandsSuccess,
    error: fetchPendingBrandsError,
    pendingBrands,
  })
);

export const selectFetchApprovedBrandsState = createSelector(
  selectBrandsState,
  ({
    fetchApprovedBrandsLoading,
    fetchApprovedBrandsSuccess,
    fetchApprovedBrandsError,
    approvedBrands,
  }) => ({
    loading: fetchApprovedBrandsLoading,
    success: fetchApprovedBrandsSuccess,
    error: fetchApprovedBrandsError,
    approvedBrands,
  })
);

export const selectFetchRejectedBrandsState = createSelector(
  selectBrandsState,
  ({
    fetchRejectedBrandsLoading,
    fetchRejectedBrandsSuccess,
    fetchRejectedBrandsError,
    rejectedBrands,
  }) => ({
    loading: fetchRejectedBrandsLoading,
    success: fetchRejectedBrandsSuccess,
    error: fetchRejectedBrandsError,
    rejectedBrands,
  })
);

export const selectAcceptBrandState = createSelector(
  selectBrandsState,
  ({ acceptBrandLoading, acceptBrandSuccess, acceptBrandError }) => ({
    loading: acceptBrandLoading,
    success: acceptBrandSuccess,
    error: acceptBrandError,
  })
);

export const selectRejectBrandState = createSelector(
  selectBrandsState,
  ({ rejectBrandLoading, rejectBrandSuccess, rejectBrandError }) => ({
    loading: rejectBrandLoading,
    success: rejectBrandSuccess,
    error: rejectBrandError,
  })
);
