import React from 'react';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DatePicker = ({
  key,
  name,
  id,
  date,
  className,
  onChange,
  minDate,
  maxDate,
  placeholder,
  disabled,
  readOnly,
  error,
  defaultValue,
  ...rest
}) => (
  <div className={classNames({ 'picker-error': error })}>
    <ReactDatePicker
      alt={name}
      key={key}
      name={name}
      autoComplete="off"
      id={id}
      className={classNames('red', className)}
      selected={date || defaultValue}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      placeholderText={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
      {...rest}
    />
  </div>
);

DatePicker.propTypes = {
  key: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
};

DatePicker.defaultProps = {
  key: null,
  date: null,
  minDate: null,
  maxDate: null,
  placeholder: '',
  name: '',
  id: '',
  className: '',
  disabled: false,
  readOnly: false,
  error: false,
  defaultValue: null,
};

export default DatePicker;
