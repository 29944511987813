import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Spinner from 'components/Common/Spinner';
import Modal from 'components/Common/Modal';
import Confirmation from 'components/Common/Confirmation';
import Toast from 'components/Common/Toast';
import BrandSummary from 'components/Pages/Brands/BrandSummary';
import {
  selectFetchApprovedBrandsState,
  selectRejectBrandState,
} from 'state/selectors/brands';
import {
  clearBrandsState,
  fetchApprovedBrands,
  rejectBrand,
} from 'state/actions/brands';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';

import classes from './ApprovedBrands.module.scss';

const ApprovedBrands = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    error: errorApprovedBrands,
    success: successApprovedBrands,
    loading: loadingApprovedBrands,
    approvedBrands,
  } = useSelector(selectFetchApprovedBrandsState, shallowEqual);

  const {
    error: errorRejectBrand,
    success: successRejectBrand,
    loading: loadingRejectBrand,
  } = useSelector(selectRejectBrandState, shallowEqual);

  const [brandsToShow, setBrandsToShow] = useState([]);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (user) dispatch(fetchApprovedBrands(user.network));

    return () => {
      dispatch(clearBrandsState());
    };
  }, [user]);

  useEffect(() => {
    if (successApprovedBrands) {
      setBrandsToShow(approvedBrands);
      dispatch(clearBrandsState());
    }
  }, [successApprovedBrands, approvedBrands]);

  useEffect(() => {
    if (successRejectBrand) {
      onCloseModalHandler();
      setBrandsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== brandIdToDelete)
      );
      setBrandIdToDelete(null);
    }
  }, [successRejectBrand, brandIdToDelete]);

  const onClickRejectHandler = useCallback((brand) => {
    onOpenModalHandler(ModalType.REJECT_BRAND, {
      brandId: brand.uid,
    });
  }, []);

  const onRejectBrandHandler = useCallback(() => {
    setBrandIdToDelete(modal.brandId);
    dispatch(rejectBrand({ brandId: modal.brandId }));
  }, [modal]);

  return (
    <>
      {errorApprovedBrands && (
        <Toast text={errorApprovedBrands} id="Fetch approved brands error" />
      )}
      {errorRejectBrand && (
        <Toast text={errorRejectBrand} id="Reject brand error" />
      )}
      <Modal
        isOpen={modal.type === ModalType.REJECT_BRAND}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Reject brand"
          description="Are you sure you want to reject the selected brand?"
          onAccept={onRejectBrandHandler}
          onCancel={onCloseModalHandler}
          loading={loadingRejectBrand}
          acceptButtonText="Yes"
        />
      </Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Brands approved
          </Body>
          {loadingApprovedBrands ? (
            <Spinner className={classes.spinner} />
          ) : (
            <>
              {brandsToShow.length === 0 ? (
                <Body
                  size={BodySize.XS}
                  spacing={BodySpacing.S}
                  className={classes.noBrands}
                >
                  There are no brands approved.
                </Body>
              ) : (
                <>
                  <div className={classes.brands}>
                    {brandsToShow.map((brand, index) => {
                      const key = `brand-${index}`;
                      return (
                        <BrandSummary
                          key={key}
                          brand={brand}
                          onReject={() => onClickRejectHandler(brand)}
                          isAccepted
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ApprovedBrands;
