const sortPaymentsDates = (rowA, rowB, columnName) => {
  const dateA =
    rowA.values[columnName] && rowA.values[columnName].seconds
      ? new Date(rowA.values[columnName].seconds * 1000)
      : new Date(0);
  const dateB =
    rowB.values[columnName] && rowB.values[columnName].seconds
      ? new Date(rowB.values[columnName].seconds * 1000)
      : new Date(0);

  return dateA.getTime() - dateB.getTime();
};

export default sortPaymentsDates;
