/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPendingPaymentsInit,
  fetchPendingPaymentsSuccess,
  fetchPendingPaymentsFail,
  rejectPaymentInit,
  rejectPaymentSuccess,
  rejectPaymentFail,
  acceptPaymentInit,
  acceptPaymentSuccess,
  acceptPaymentFail,
  clearPayments,
  clearFetchedPayments,
  closePaymentInit,
  closePaymentSuccess,
  closePaymentFail,
  closePaymentLoading,
  createPaymentInit,
  createPaymentSuccess,
  createPaymentFail,
  createPaymentLoading,
} from 'state/actionCreators/payments';

export const initialState = {
  fetchPendingPaymentsLoading: false,
  fetchPendingPaymentsSuccess: false,
  fetchPendingPaymentsError: null,
  rejectPaymentLoading: false,
  rejectPaymentSuccess: false,
  rejectPaymentError: null,
  closePaymentLoading: false,
  closePaymentSuccess: false,
  closePaymentError: null,
  acceptPaymentLoading: false,
  acceptPaymentSuccess: false,
  acceptPaymentError: null,
  createPaymentLoading: false,
  createPaymentSuccess: false,
  createPaymentError: null,
  createPaymentResult: {},
  pendingPayments: [],
};

const paymentsReducer = createReducer(initialState, {
  [fetchPendingPaymentsInit]: (state) => {
    state.fetchPendingPaymentsLoading = true;
    state.fetchPendingPaymentsSuccess = false;
    state.fetchPendingPaymentsError = null;
    state.pendingPayments = [];
  },
  [fetchPendingPaymentsSuccess]: (state, { payload }) => {
    const { payments } = payload;
    state.fetchPendingPaymentsLoading = false;
    state.fetchPendingPaymentsSuccess = true;
    state.fetchPendingPaymentsError = null;
    state.pendingPayments = payments;
  },
  [fetchPendingPaymentsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPendingPaymentsLoading = false;
    state.fetchPendingPaymentsSuccess = false;
    state.fetchPendingPaymentsError = error;
  },
  [rejectPaymentInit]: (state) => {
    state.rejectPaymentLoading = true;
    state.rejectPaymentSuccess = false;
    state.rejectPaymentError = null;
  },
  [rejectPaymentSuccess]: (state) => {
    state.rejectPaymentLoading = false;
    state.rejectPaymentSuccess = true;
    state.rejectPaymentError = null;
  },
  [rejectPaymentFail]: (state, { payload }) => {
    const { error } = payload;
    state.rejectPaymentLoading = false;
    state.rejectPaymentSuccess = false;
    state.rejectPaymentError = error;
  },
  [acceptPaymentInit]: (state) => {
    state.acceptPaymentLoading = true;
    state.acceptPaymentSuccess = false;
    state.acceptPaymentError = null;
  },
  [acceptPaymentSuccess]: (state) => {
    state.acceptPaymentLoading = false;
    state.acceptPaymentSuccess = true;
    state.acceptPaymentError = null;
  },
  [acceptPaymentFail]: (state, { payload }) => {
    const { error } = payload;
    state.acceptPaymentLoading = false;
    state.acceptPaymentSuccess = false;
    state.acceptPaymentError = error;
  },
  [clearPayments]: (state) => {
    state.rejectPaymentLoading = false;
    state.rejectPaymentSuccess = false;
    state.rejectPaymentError = null;
    state.acceptPaymentLoading = false;
    state.acceptPaymentSuccess = false;
    state.acceptPaymentError = null;
    state.createPaymentLoading = false;
    state.createPaymentSuccess = false;
    state.createPaymentError = null;
    state.createPaymentResult = {};
  },
  [clearFetchedPayments]: (state) => {
    state.fetchPendingPaymentsLoading = false;
    state.fetchPendingPaymentsSuccess = false;
    state.fetchPendingPaymentsError = null;
    state.pendingPayments = [];
  },
  [closePaymentInit]: (state) => {
    state.closePaymentLoading = false;
    state.closePaymentSuccess = false;
    state.closePaymentError = null;
  },
  [closePaymentLoading]: (state) => {
    state.closePaymentLoading = true;
    state.closePaymentSuccess = false;
    state.closePaymentError = null;
  },
  [closePaymentSuccess]: (state) => {
    state.closePaymentLoading = false;
    state.closePaymentSuccess = true;
    state.closePaymentError = null;
  },
  [closePaymentFail]: (state, { payload }) => {
    const { error } = payload;
    state.closePaymentLoading = false;
    state.closePaymentSuccess = false;
    state.closePaymentError = error;
  },
  [createPaymentInit]: (state) => {
    state.createPaymentLoading = false;
    state.createPaymentSuccess = false;
    state.createPaymentError = null;
    state.createPaymentResult = {};
  },
  [createPaymentLoading]: (state) => {
    state.createPaymentLoading = true;
    state.createPaymentSuccess = false;
    state.createPaymentError = null;
    state.createPaymentResult = {};
  },
  [createPaymentSuccess]: (state, { payload }) => {
    const { result } = payload;
    state.createPaymentLoading = false;
    state.createPaymentSuccess = true;
    state.createPaymentError = null;
    state.createPaymentResult = result;
  },
  [createPaymentFail]: (state, { payload }) => {
    const { error } = payload;
    state.createPaymentLoading = false;
    state.createPaymentSuccess = false;
    state.createPaymentError = error;
    state.createPaymentResult = {};
  },
});

export default paymentsReducer;
