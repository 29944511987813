import { createSelector } from '@reduxjs/toolkit';

const selectPodcastsState = ({ podcasts }) => podcasts;

export const selectFetchPendingPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchPendingPodcastsLoading,
    fetchPendingPodcastsSuccess,
    fetchPendingPodcastsError,
    pendingPodcasts,
    lastPaginationPodcast,
  }) => ({
    loading: fetchPendingPodcastsLoading,
    success: fetchPendingPodcastsSuccess,
    error: fetchPendingPodcastsError,
    pendingPodcasts,
    lastPaginationPodcast,
  })
);

export const selectFetchApprovedPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchApprovedPodcastsLoading,
    fetchApprovedPodcastsSuccess,
    fetchApprovedPodcastsError,
    approvedPodcasts,
  }) => ({
    loading: fetchApprovedPodcastsLoading,
    success: fetchApprovedPodcastsSuccess,
    error: fetchApprovedPodcastsError,
    approvedPodcasts,
  })
);

export const selectFetchRejectedPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchRejectedPodcastsLoading,
    fetchRejectedPodcastsSuccess,
    fetchRejectedPodcastsError,
    rejectedPodcasts,
  }) => ({
    loading: fetchRejectedPodcastsLoading,
    success: fetchRejectedPodcastsSuccess,
    error: fetchRejectedPodcastsError,
    rejectedPodcasts,
  })
);

export const selectAcceptPodcastState = createSelector(
  selectPodcastsState,
  ({ acceptPodcastLoading, acceptPodcastSuccess, acceptPodcastError }) => ({
    loading: acceptPodcastLoading,
    success: acceptPodcastSuccess,
    error: acceptPodcastError,
  })
);

export const selectRejectPodcastState = createSelector(
  selectPodcastsState,
  ({ rejectPodcastLoading, rejectPodcastSuccess, rejectPodcastError }) => ({
    loading: rejectPodcastLoading,
    success: rejectPodcastSuccess,
    error: rejectPodcastError,
  })
);

export const selectSendToNetworkPodcastState = createSelector(
  selectPodcastsState,
  ({
    sendToNetworkPodcastLoading,
    sendToNetworkPodcastSuccess,
    sendToNetworkPodcastError,
  }) => ({
    loading: sendToNetworkPodcastLoading,
    success: sendToNetworkPodcastSuccess,
    error: sendToNetworkPodcastError,
  })
);

export const selectFetchPendingDocumentsPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchPendingDocumentsPodcastsLoading,
    fetchPendingDocumentsPodcastsSuccess,
    fetchPendingDocumentsPodcastsError,
    pendingDocumentsPodcasts,
  }) => ({
    loading: fetchPendingDocumentsPodcastsLoading,
    success: fetchPendingDocumentsPodcastsSuccess,
    error: fetchPendingDocumentsPodcastsError,
    pendingDocumentsPodcasts,
  })
);

export const selectEditPendingDocumentsPodcastsState = createSelector(
  selectPodcastsState,
  ({
    editPendingDocumentLoading,
    editPendingDocumentSuccess,
    editPendingDocumentError,
  }) => ({
    loading: editPendingDocumentLoading,
    success: editPendingDocumentSuccess,
    error: editPendingDocumentError,
  })
);

export const selectEditPodcastDataState = createSelector(
  selectPodcastsState,
  ({
    editPodcastDataLoading,
    editPodcastDataSuccess,
    editPodcastDataError,
  }) => ({
    loading: editPodcastDataLoading,
    success: editPodcastDataSuccess,
    error: editPodcastDataError,
  })
);

export const selectHiatusPodcastState = createSelector(
  selectPodcastsState,
  ({ hiatusPodcastLoading, hiatusPodcastSuccess, hiatusPodcastError }) => ({
    loading: hiatusPodcastLoading,
    success: hiatusPodcastSuccess,
    error: hiatusPodcastError,
  })
);

export const selectFetchPodcastOffersState = createSelector(
  selectPodcastsState,
  ({
    fetchPodcastOffersLoading,
    fetchPodcastOffersSuccess,
    fetchPodcastOffersError,
    podcastOffers,
  }) => ({
    loading: fetchPodcastOffersLoading,
    success: fetchPodcastOffersSuccess,
    error: fetchPodcastOffersError,
    podcastOffers,
  })
);
