import React from 'react';
import classNames from 'classnames';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Badge from 'components/Common/Badge';
import incomeOptions from 'utils/campaigns/incomeOptions';
import { newAgeOptions } from 'utils/campaigns/ageOptions';

import classes from 'components/Pages/Campaigns/CampaignDetails/CampaignDetails.module.scss';
import ethnicityOptions from '../ethnicityOptions';

const getCampaignData = (campaign) => [
  {
    label: 'Name',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign.name || ''}
      </Body>
    ),
  },
  {
    label: 'Airing Between',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign.airingFrom?.toDateString() || ''} -{' '}
        {campaign.airingTo?.toDateString() || ''}
      </Body>
    ),
  },
  {
    label: 'Categories',
    component: (props) => (
      <div className={classes.categories}>
        <div className={classes.badgesContainer}>
          {campaign.categories.map(({ id, name: category }) => (
            <Badge
              {...props}
              text={category}
              className={classes.category}
              key={`${id}-${category}`}
            />
          ))}
        </div>
        {Object.values(campaign.categories).length === 0 && (
          <Body
            {...props}
            size={BodySize.XS}
            className={classes.informationText}
          >
            -
          </Body>
        )}
      </div>
    ),
  },
  {
    label: 'Gender',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        Male: {campaign.gender?.male || '0'} % <br />
        Female: {campaign.gender?.female || '0'} %
      </Body>
    ),
  },
  {
    label: 'Age',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign?.age?.length ? (
          (campaign?.age || []).map((ageId) => (
            <Badge
              className={classes.categories}
              text={
                newAgeOptions.find((ageOption) => ageOption.value === ageId)
                  ?.label
              }
              key={ageId}
            />
          ))
        ) : (
          <Body size={BodySize.XS} className={classes.informationText}>
            -
          </Body>
        )}
      </Body>
    ),
  },
  {
    label: 'Campaign Total Budget',
    component: (props) => (
      <Body
        {...props}
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.green)}
      >
        {`$${campaign.budget}` || '-'}
      </Body>
    ),
  },
  {
    label: 'Ethnicity',
    component: () => (
      <div className={classes.badgesContainer}>
        {campaign?.ethnicity?.length ? (
          (campaign?.ethnicity || []).map((ethnicityId) => (
            <Badge
              className={classes.categories}
              text={
                ethnicityOptions.find(
                  (ethnicityOption) => ethnicityOption.value === ethnicityId
                )?.label
              }
              key={ethnicityId}
            />
          ))
        ) : (
          <>
            <Body size={BodySize.XS} className={classes.informationText}>
              -
            </Body>
          </>
        )}
      </div>
    ),
  },
  {
    label: 'Income',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign?.income?.length ? (
          (campaign?.income || []).map((incomeId) => (
            <Badge
              className={classes.categories}
              text={
                incomeOptions.find(
                  (incomeOption) => incomeOption.value === incomeId
                )?.label
              }
              key={incomeId}
            />
          ))
        ) : (
          <>
            <Body size={BodySize.XS} className={classes.informationText}>
              -
            </Body>
          </>
        )}
      </Body>
    ),
  },
];

export default getCampaignData;
