import {
  addDoc,
  collection,
  getFirestore,
  query,
  where,
  getDocs,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const addAdministrator = async ({ email, network }) => {
  try {
    const firestore = getFirestore();
    const collRef = collection(firestore, Collections.Administrators);

    const emailQuery = query(collRef, where('email', '==', email));
    const querySnapshot = await getDocs(emailQuery);

    if (!querySnapshot.empty) {
      throw new Error(`Administrator with email ${email} already exists`);
    }

    return await addDoc(collRef, {
      email,
      network,
      permissions: {
        approveBrands: true,
        approvePendingDocuments: true,
        approvePodcasters: true,
        campaigns: true,
        payments: true,
        seeApprovedBrands: true,
        seeApprovedPodcasters: true,
        seeRejectedBrands: true,
        seeRejectedPodcasters: true,
        settings: true,
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};

export default addAdministrator;
