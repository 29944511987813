import { doc, updateDoc, getFirestore } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';
import PaymentStatus from 'enums/payments/paymentStatus.enum';

const rejectPayment = async ({ paymentId }) => {
  const firestore = getFirestore();

  const paymentRef = doc(firestore, Collections.Payments, paymentId);
  await updateDoc(paymentRef, { status: PaymentStatus.Rejected, processingDate: new Date() });
};

export default rejectPayment;
