import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'state/reducers/auth';
import paymentsReducer from 'state/reducers/payments';
import podcastsReducer from 'state/reducers/podcasts';
import brandsReducer from 'state/reducers/brands';
import campaignsReducer from 'state/reducers/campaigns';
import generalsReducer from 'state/reducers/generals';
import usersReducer from 'state/reducers/users';

const rootReducer = combineReducers({
  auth: authReducer,
  payments: paymentsReducer,
  podcasts: podcastsReducer,
  brands: brandsReducer,
  campaigns: campaignsReducer,
  generals: generalsReducer,
  users: usersReducer,
});

export default rootReducer;
