import { collection, getDocs, query, getFirestore } from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const fetchHostingProviders = async () => {
  try {
    const firestore = getFirestore();
    const hostingProviderRef = collection(
      firestore,
      Collections.HostingProviders
    );

    const hostingProvidersQuery = query(hostingProviderRef);

    const hostingProvidersSapshot = await getDocs(hostingProvidersQuery);

    const hostingProviders = hostingProvidersSapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    return hostingProviders;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchHostingProviders;
