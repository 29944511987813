const ageOptions = [
  { label: 'GenZ', value: { start: 1997, end: 2012 } },
  { label: 'GenY 1', value: { start: 1992, end: 1996 } },
  { label: 'GenY 2', value: { start: 1981, end: 1991 } },
  { label: 'GenX', value: { start: 1965, end: 1980 } },
  { label: 'Babyboomer', value: { start: 1946, end: 1964 } },
];

const newAgeOptions = [
  { label: '12-17 years old', value: '12 - 17' },
  { label: '18-34 years old', value: '18 - 34' },
  { label: '35-54 years old', value: '35 - 54' },
  { label: '55+ years old', value: '55+' },
];

const reducedAgeOptions = [
  { ...newAgeOptions[0], label: '12 - 17' },
  { ...newAgeOptions[1], label: '18 - 34' },
  { ...newAgeOptions[2], label: '35 - 54' },
  { ...newAgeOptions[3], label: '55+' },
];

export { reducedAgeOptions, newAgeOptions };

export default ageOptions;
