import Collections from 'enums/firebase/collections.enum';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

const flagPendingDocument = async (screenshot) => {
  const { userId, id } = screenshot;

  const firestore = getFirestore();

  const audienceEstimateScreenshotsRef = doc(
    firestore,
    Collections.PodcastUsers,
    userId,
    Collections.AudienceEstimateScreenshots,
    id
  );

  await setDoc(
    audienceEstimateScreenshotsRef,
    {
      flaggedDate: Timestamp.now().toMillis(),
    },
    { merge: true }
  );
};

export default flagPendingDocument;
