import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import NavigationLayout from 'components/Layouts/NavigationLayout';
import RestrictedRoute from 'components/Navigation/RestrictedRoute';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import Path from 'enums/path.enum';
import getPathsByPermission from 'utils/users/getPathsByPermission';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const isLoggedIn = useMemo(() => !!user, [user]);

  if (user) {
    const userPermissions = getPathsByPermission(user.permissions);
    const isForbiddenPath = rest.path !== Path.Forbidden;
    const hasNoPermission =
      !userPermissions.includes(rest.path) || user.permissions.length === 0;

    if (isForbiddenPath && hasNoPermission) {
      return <Redirect to={Path.Forbidden} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <NavigationLayout>
            <RestrictedRoute component={Component} {...props} {...rest} />
          </NavigationLayout>
        ) : (
          <Redirect to={Path.Login} />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticatedRoute;
