import { getFirestore, doc, deleteDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const deleteUser = async ({ user }) => {
  try {
    const firestore = getFirestore();
    const adminRef = doc(
      firestore,
      Collections.Administrators,
      user.id
    );
    await deleteDoc(adminRef, user);
    return true;
  } catch (error) {
    return error;
  }
};

export default deleteUser;
