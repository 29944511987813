import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';
import networks from 'enums/podcasts/networks.enum';

const acceptPodcast = async ({ podcastId, newAudience, network = null }) => {
  const firestore = getFirestore();

  const acceptedPodcastRef = doc(
    firestore,
    Collections.PodcastUsers,
    podcastId
  );

  let audienceEstimate = null;
  let downloadsEstimate = '';
  let audienceEstimateScreenshotsRef = null;

  if (newAudience) {
    const {
      audienceEstimate: newAudienceEstimate,
      monthlyListeners: newMonthlyListeners,
      downloadsPerEpisode: newDownloadsEstimate,
      id,
    } = newAudience;

    audienceEstimate = newMonthlyListeners ?? newAudienceEstimate;
    downloadsEstimate = newDownloadsEstimate;

    audienceEstimateScreenshotsRef = doc(
      firestore,
      Collections.PodcastUsers,
      podcastId,
      Collections.AudienceEstimateScreenshots,
      id
    );
  }

  const podcastData = {
    waitingForConfirmation: false,
    enabled: true,
    acceptedDate: Timestamp.now().toMillis(),
  };

  if (network !== networks.Spotify) {
    podcastData.podcastData = {
      audienceEstimate,
      downloadsEstimate,
    };
  }

  await setDoc(acceptedPodcastRef, podcastData, { merge: true });

  if (audienceEstimateScreenshotsRef) {
    await setDoc(
      audienceEstimateScreenshotsRef,
      {
        approved: true,
      },
      { merge: true }
    );
  }
};

export default acceptPodcast;
