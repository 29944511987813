import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  collectionGroup,
} from 'firebase/firestore';

import filterPodcastsByAudience from 'utils/podcasts/filterPodcastsByAudience';
import Collections from 'enums/firebase/collections.enum';

const fetchApprovedPodcasts = async (selectedSort) => {
  const firestore = getFirestore();

  const approvedPodcastsRef = collection(firestore, Collections.PodcastUsers);
  const approvedSnapshotsQuery = query(
    collectionGroup(firestore, Collections.AudienceEstimateScreenshots),
    where('approved', '==', true)
  );

  let baseQuery = query(
    approvedPodcastsRef,
    where('waitingForConfirmation', '==', false),
    where('enabled', '==', true),
    where('networks', 'array-contains', selectedSort.network)
  );

  baseQuery = query(
    baseQuery,
    orderBy(selectedSort.sortBy, selectedSort.sortOrder)
  );

  const [approvedPodcastsSnapshot, approvedScreenshots] = await Promise.all([
    getDocs(baseQuery),
    getDocs(approvedSnapshotsQuery),
  ]);

  const approvedScreenshotsData = {};

  approvedScreenshots.docs.forEach((doc) => {
    const data = doc.data();

    if (
      !approvedScreenshotsData[data.userId] ||
      approvedScreenshotsData[data.userId].createdAt <= data.createdAt
    )
      approvedScreenshotsData[data.userId] = {
        ...data,
      };
  });

  const approvedPodcasts = approvedPodcastsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    screenshots: approvedScreenshotsData[doc.id],
    uid: doc.id,
  }));

  if (selectedSort.sortBy === 'podcastData.audienceEstimate') {
    return filterPodcastsByAudience(approvedPodcasts, selectedSort.sortOrder);
  }

  return approvedPodcasts;
};

export default fetchApprovedPodcasts;
