import {
  fetchPendingPaymentsInit,
  fetchPendingPaymentsSuccess,
  fetchPendingPaymentsFail,
  rejectPaymentInit,
  rejectPaymentSuccess,
  rejectPaymentFail,
  acceptPaymentInit,
  acceptPaymentSuccess,
  acceptPaymentFail,
  clearPayments,
  clearFetchedPayments,
  closePaymentInit,
  closePaymentSuccess,
  closePaymentFail,
  closePaymentLoading,
  createPaymentInit,
  createPaymentSuccess,
  createPaymentFail,
  createPaymentLoading,
} from 'state/actionCreators/payments';
import fetchPendingPaymentsService from 'services/payments/fetchPendingPayments';
import rejectPaymentService from 'services/payments/rejectPayment';
import acceptPaymentService from 'services/payments/acceptPayment';
import closePaymentService from 'services/payments/closePayment';
import createPaymentService from 'services/payments/createPayment';

export const fetchPendingPayments = ({ podcastId, network }) => async (
  dispatch
) => {
  dispatch(fetchPendingPaymentsInit());

  try {
    const payments = await fetchPendingPaymentsService({
      podcastId,
      network,
    });

    return dispatch(fetchPendingPaymentsSuccess({ payments }));
  } catch (error) {
    return dispatch(fetchPendingPaymentsFail({ error: error.message }));
  }
};

export const rejectPayment = ({ paymentId }) => async (dispatch) => {
  dispatch(rejectPaymentInit());

  try {
    await rejectPaymentService({ paymentId });

    return dispatch(rejectPaymentSuccess());
  } catch (error) {
    return dispatch(rejectPaymentFail({ error: error.message }));
  }
};

export const acceptPayment = ({ paymentId, amount }) => async (dispatch) => {
  dispatch(acceptPaymentInit());

  try {
    await acceptPaymentService({ paymentId, amount });

    return dispatch(acceptPaymentSuccess());
  } catch (error) {
    return dispatch(acceptPaymentFail({ error: error.message }));
  }
};

export const closePayment = ({ paymentId }) => async (dispatch) => {
  dispatch(closePaymentInit());

  try {
    dispatch(closePaymentLoading());
    await closePaymentService({ paymentId });

    return dispatch(closePaymentSuccess());
  } catch (error) {
    return dispatch(closePaymentFail({ error: error.message }));
  }
};

export const createPayment = ({ paymentData }) => async (dispatch) => {
  dispatch(createPaymentInit());
  try {
    dispatch(createPaymentLoading());
    const result = await createPaymentService({ paymentData });
    return dispatch(createPaymentSuccess({ result }));
  } catch (error) {
    return dispatch(createPaymentFail({ error: error.message }));
  }
};

export const clearPaymentsState = () => clearPayments();

export const clearFetchedPaymentsState = () => clearFetchedPayments();
