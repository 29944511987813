import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Modal from 'components/Common/Modal';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import PodcastFullInformation from 'components/Pages/Podcasts/PodcastFullInformation';
import Confirmation from 'components/Common/Confirmation';
import EditPodcastAudienceEstimate from 'components/Pages/Podcasts/EditPodcastAudienceEstimate';
import EditPodcastDownloadsEstimate from 'components/Pages/Podcasts/EditPodcastDownloadsEstimate';
import {
  acceptPendingDocument,
  flagPendingDocument,
  fetchPendingDocumentsPodcasts,
  editPendingDocument,
} from 'state/actions/podcasts';
import getPodcastData from 'utils/podcasts/getPodcastData';

import classes from './PodcasterPanel.module.scss';

const PodcasterPanel = ({ podcast, last, refresh }) => {
  const dispatch = useDispatch();
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onClick = () => {
    const podcastData = getPodcastData({ podcast });
    onOpenModalHandler(ModalType.PODCAST_SUMMARY, { podcast: podcastData });
  };

  const onOkHandler = () => {
    onOpenModalHandler(ModalType.CONFIRM_DOCUMENT, {
      podcast,
    });
  };

  const onFlagHandler = () => {
    onOpenModalHandler(ModalType.FLAG_DOCUMENT, {
      podcast,
    });
  };

  const onEditHandler = () => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_AUDIENCE, {
      podcast,
    });
  };

  const onEditDownloadsHandler = () => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_DOWNLOADS, {
      podcast,
    });
  };

  const onConfirmOkHanlder = async () => {
    await dispatch(acceptPendingDocument({ screenshot: podcast.screenshots }));
    dispatch(fetchPendingDocumentsPodcasts());
    await refresh();
  };

  const onConfirmFlagHandler = async () => {
    await dispatch(flagPendingDocument({ screenshot: podcast.screenshots }));
    dispatch(fetchPendingDocumentsPodcasts());
    await refresh();
  };

  const onEditAudienceHandler = async ({ audienceEstimate }) => {
    await dispatch(
      editPendingDocument({
        screenshot: podcast.screenshots,
        editedData: { monthlyListeners: audienceEstimate },
      })
    );
    dispatch(fetchPendingDocumentsPodcasts());
  };

  const onEditDownloadsEstimateHandler = async ({ downloadsEstimate }) => {
    dispatch(
      editPendingDocument({
        screenshot: podcast.screenshots,
        editedData: { downloadsPerEpisode: downloadsEstimate },
      })
    );
  };

  const onCancelAudienceHandler = () => {
    onOpenModalHandler(ModalType.PODCAST_SUMMARY, { podcast });
  };

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_SUMMARY}
        onClose={onCloseModalHandler}
      >
        <PodcastFullInformation
          podcast={modal.podcast}
          onAccept={onOkHandler}
          onReject={onFlagHandler}
          onEdit={onEditHandler}
          onEditDownloads={onEditDownloadsHandler}
          approvingDocuments
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.CONFIRM_DOCUMENT}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Accept document"
          description="Are you sure you want to accept this document?"
          onAccept={onConfirmOkHanlder}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.FLAG_DOCUMENT}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Flag document"
          description="Are you sure you want to flag this document?"
          onAccept={onConfirmFlagHandler}
          onCancel={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE}
        onClose={onCloseModalHandler}
      >
        <EditPodcastAudienceEstimate
          podcast={podcast}
          onUpdate={onEditAudienceHandler}
          onCancel={onCancelAudienceHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS}
        onClose={onCloseModalHandler}
      >
        <EditPodcastDownloadsEstimate
          podcast={podcast}
          onUpdate={onEditDownloadsEstimateHandler}
          onCancel={onCancelAudienceHandler}
        />
      </Modal>
      <div
        className={classNames(classes.container, { [classes.notLast]: !last })}
      >
        <div className={classes.leftContainer}>
          <img
            src={podcast.podcastData.imageUrl}
            alt={podcast.podcastName}
            className={classes.podcastImg}
          />
          <Button
            kind={ButtonKind.Secondary}
            size={ButtonSize.S}
            onClick={onClick}
          >
            Learn More
          </Button>
        </div>
        <div className={classes.rightContainer}>
          <Body size={BodySize.XS} className={classes.podcastName}>
            {podcast.podcastData.podcastTitle}
          </Body>
          <div className={classes.dates}>
            <div className={classes.date}>
              <Body size={BodySize.XS}>Sign Up Date</Body>
              <Body size={BodySize.S} className={classes.dateValue}>
                {dayjs(podcast.createdAt).format('MM/DD/YYYY')}
              </Body>
            </div>
            <div className={classes.date}>
              <Body size={BodySize.XS}>Approved Date</Body>
              <Body size={BodySize.S} className={classes.dateValue}>
                {dayjs(podcast.acceptedDate).format('MM/DD/YYYY')}
              </Body>
            </div>
            <div className={classes.date}>
              <Body size={BodySize.XS}>Latest Screenshot Uploaded</Body>
              <Body size={BodySize.S} className={classes.dateValue}>
                {dayjs(podcast.screenshots.createdAt).format('MM/DD/YYYY')}
              </Body>
            </div>
            {podcast.screenshots.flaggedDate && (
              <div className={classes.date}>
                <Body size={BodySize.XS} className={classes.flagged}>
                  Flagged Date
                </Body>
                <Body
                  size={BodySize.S}
                  className={classNames(classes.dateValue, classes.flagged)}
                >
                  {dayjs(podcast.screenshots.flaggedDate).format('MM/DD/YYYY')}
                </Body>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
PodcasterPanel.propTypes = {
  podcast: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    screenshots: PropTypes.shape({
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      flaggedDate: PropTypes.number,
    }).isRequired,
    podcastData: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      podcastTitle: PropTypes.string.isRequired,
    }).isRequired,
    podcastName: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    acceptedDate: PropTypes.number.isRequired,
  }).isRequired,
  last: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default PodcasterPanel;
