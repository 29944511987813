import React from 'react';
import PropTypes, { string } from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
  Color as ButtonColor,
} from 'components/Common/Button';

import networks from 'enums/podcasts/networks.enum';
import classes from './PodcastSummary.module.scss';

const PodcastSummary = ({
  podcast,
  onClickLearnMore,
  onAccept,
  onReject,
  onSendToNetwork,
}) => {
  const actualNetwork = podcast.networks[0];
  const isVisible = actualNetwork !== networks.Colture;
  const newNetwork =
    actualNetwork === networks.Ossa ? networks.Spotify : networks.Ossa;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.image}>
          {podcast.podcastData?.imageUrl ? (
            <img
              src={podcast.podcastData?.imageUrl}
              alt={podcast.podcastData?.podcastTitle}
              className={classes.logo}
            />
          ) : (
            <Body>{podcast.podcastData?.podcastTitle}</Body>
          )}
        </div>
        <Body size={BodySize.XS} className={classes.title}>
          {podcast.podcastData?.podcastTitle || 'Podcast'}
        </Body>
        <Body size={BodySize.XS} className={classes.subtitle}>
          {podcast.podcastData?.description || 'The podcast has no description'}
        </Body>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.learnMore}
          kind={ButtonKind.Secondary}
          size={ButtonSize.S}
          onClick={onClickLearnMore}
        >
          Learn more
        </Button>
        <Button onClick={onAccept} className={classes.button}>
          Accept
        </Button>
        <Button
          onClick={onReject}
          className={classes.button}
          color={ButtonColor.Pink}
        >
          Reject
        </Button>
        {isVisible && (
          <Button
            onClick={onSendToNetwork}
            className={classes.button}
            color={
              actualNetwork === networks.Ossa
                ? ButtonColor.Green
                : ButtonColor.Black
            }
          >
            Send To {newNetwork}
          </Button>
        )}
      </div>
    </div>
  );
};

PodcastSummary.propTypes = {
  podcast: PropTypes.shape({
    podcastId: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      podcastTitle: PropTypes.string,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
    networks: PropTypes.arrayOf(string),
  }).isRequired,
  onClickLearnMore: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onSendToNetwork: PropTypes.func.isRequired,
};

export default PodcastSummary;
