const PathsByPermission = {
    approveBrands: [
        '/pending-brands'
    ],
    approvePendingDocuments: [
        '/pending-documents'
    ],
    approvePodcasters: [
        '/pending-podcasts',
    ],
    campaigns: [
        '/campaigns',
        '/campaigns/:brandId/:campaignId'
    ],
    payments: [
        '/payments',
        '/payments/:podcastId'
    ],
    seeApprovedBrands: [
        '/approved-brands',
    ],
    seeApprovedPodcasters: [
        '/approved-podcasts'
    ],
    seeRejectedBrands: [
        '/rejected-brands'
    ],
    seeRejectedPodcasters: [
        '/rejected-podcasts'
    ],
    settings: [
        '/settings'
    ]
};

export default PathsByPermission;
