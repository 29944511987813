const Path = {
  Home: '/',
  Login: '/login',
  Forbidden: '/forbidden',
  Payments: '/payments',
  PodcastPayments: '/payments/:podcastId',
  PodcastsForApproval: '/pending-podcasts',
  PodcastsApproved: '/approved-podcasts',
  PodcastsRejected: '/rejected-podcasts',
  BrandsForApproval: '/pending-brands',
  BrandsApproved: '/approved-brands',
  BrandsRejected: '/rejected-brands',
  Campaigns: '/campaigns',
  PendingDocuments: '/pending-documents',
  FilterCampaigns: '/campaigns/:brandId/:campaignId',
  Settings: '/settings',
  TermsOfService: 'https://ossacollective.com/terms-app',
  Privacy: 'https://ossacollective.com/privacy-app',
};

export default Path;
