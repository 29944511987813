import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchCurrentCampaigns = async ({ brandId }) => {
  const firestore = getFirestore();

  const today = new Date();

  const campaignRef = collection(
    firestore,
    Collections.Brands,
    brandId,
    Collections.Campaigns
  );

  const currentCampaignsQuery = query(
    campaignRef,
    where('airingTo', '>=', today)
  );

  const currentCampaignsSnapshot = await getDocs(currentCampaignsQuery);

  const currentCampaignsData = currentCampaignsSnapshot.docs.map((doc) => {
    const data = doc.data();
    const date = data.createdAt?.toDate() ?? null;

    return {
      ...data,
      uid: doc.id,
      createdAt: date,
    };
  });

  const currentCampaigns = currentCampaignsData.filter(
    (campaign) => campaign.finishedCreatingCampaign
  );

  return currentCampaigns;
};

export default fetchCurrentCampaigns;
