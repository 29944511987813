import {
  fetchPendingBrandsInit,
  fetchPendingBrandsSuccess,
  fetchPendingBrandsFail,
  fetchApprovedBrandsInit,
  fetchApprovedBrandsSuccess,
  fetchApprovedBrandsFail,
  fetchRejectedBrandsInit,
  fetchRejectedBrandsSuccess,
  fetchRejectedBrandsFail,
  acceptBrandInit,
  acceptBrandSuccess,
  acceptBrandFail,
  rejectBrandInit,
  rejectBrandSuccess,
  rejectBrandFail,
  clearBrands,
} from 'state/actionCreators/brands';
import fetchPendingBrandsService from 'services/brands/fetchPendingBrands';
import fetchApprovedBrandsService from 'services/brands/fetchApprovedBrands';
import fetchRejectedBrandsService from 'services/brands/fetchRejectedBrands';
import acceptBrandservice from 'services/brands/acceptBrand';
import rejectBrandservice from 'services/brands/rejectBrand';

export const fetchPendingBrands = (network) => async (dispatch) => {
  dispatch(fetchPendingBrandsInit());

  try {
    const pendingBrands = await fetchPendingBrandsService(network);

    return dispatch(fetchPendingBrandsSuccess({ pendingBrands }));
  } catch (error) {
    return dispatch(fetchPendingBrandsFail({ error: error.message }));
  }
};

export const fetchApprovedBrands = (network) => async (dispatch) => {
  dispatch(fetchApprovedBrandsInit());

  try {
    const approvedBrands = await fetchApprovedBrandsService(network);

    return dispatch(fetchApprovedBrandsSuccess({ approvedBrands }));
  } catch (error) {
    return dispatch(fetchApprovedBrandsFail({ error: error.message }));
  }
};

export const fetchRejectedBrands = (network) => async (dispatch) => {
  dispatch(fetchRejectedBrandsInit());

  try {
    const rejectedBrands = await fetchRejectedBrandsService(network);

    return dispatch(fetchRejectedBrandsSuccess({ rejectedBrands }));
  } catch (error) {
    return dispatch(fetchRejectedBrandsFail({ error: error.message }));
  }
};

export const acceptBrand = ({ brandId }) => async (dispatch) => {
  dispatch(acceptBrandInit());

  try {
    await acceptBrandservice({ brandId });

    return dispatch(acceptBrandSuccess());
  } catch (error) {
    return dispatch(acceptBrandFail({ error: error.message }));
  }
};

export const rejectBrand = ({ brandId }) => async (dispatch) => {
  dispatch(rejectBrandInit());

  try {
    await rejectBrandservice({ brandId });

    return dispatch(rejectBrandSuccess());
  } catch (error) {
    return dispatch(rejectBrandFail({ error: error.message }));
  }
};

export const clearBrandsState = () => clearBrands();
