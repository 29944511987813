import { createAction } from '@reduxjs/toolkit';

export const fetchPendingPaymentsInit = createAction(
  '[Payments] Fetch Pending Payments Init'
);
export const fetchPendingPaymentsSuccess = createAction(
  '[Payments] Fetch Pending Payments Success'
);
export const fetchPendingPaymentsFail = createAction(
  '[Payments] Fetch Pending Payments Fail'
);

export const rejectPaymentInit = createAction('[Payments] Reject Payment Init');
export const rejectPaymentSuccess = createAction(
  '[Payments] Reject Payment Success'
);
export const rejectPaymentFail = createAction('[Payments] Reject Payment Fail');

export const acceptPaymentInit = createAction('[Payments] Accept Payment Init');
export const acceptPaymentSuccess = createAction(
  '[Payments] Accept Payment Success'
);
export const acceptPaymentFail = createAction('[Payments] Accept Payment Fail');

export const clearPayments = createAction('[Payments] Clear Payments');

export const clearFetchedPayments = createAction(
  '[Payments] Clear Fetched Payments'
);

export const closePaymentInit = createAction('[Payments] Close Payment Init');
export const closePaymentLoading = createAction(
  '[Payments] Close Payment Loading'
);
export const closePaymentSuccess = createAction(
  '[Payments] Close Payment Success'
);
export const closePaymentFail = createAction('[Payments] Close Payment Fail');

export const createPaymentInit = createAction('[Payments] Create Payment Init');
export const createPaymentLoading = createAction(
  '[Payments] Create Payment Loading'
);
export const createPaymentSuccess = createAction(
  '[Payments] Create Payment Success'
);
export const createPaymentFail = createAction('[Payments] Create Payment Fail');
