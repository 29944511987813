const removeNonAcceptedTags = (string, acceptedTags = []) => {
  if (!acceptedTags.length) {
    return string.replace(/<[^>]*>?/gm, '');
  }

  const acceptedTagsString =
    acceptedTags.length === 1 ? acceptedTags[0] : acceptedTags.join('|');

  const tagsRegex = new RegExp(
    `<\\/?(?!(${acceptedTagsString}))\\b[^>]*>`,
    'gi'
  );

  return string.replace(tagsRegex, '');
};

export default removeNonAcceptedTags;
