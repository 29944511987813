import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  limit,
  startAfter,
  collectionGroup,
} from 'firebase/firestore';

import filterPodcastsByAudience from 'utils/podcasts/filterPodcastsByAudience';
import Collections from 'enums/firebase/collections.enum';

const fetchPendingPodcasts = async ({
  lastItemPagination,
  first,
  selectedSort,
  searchValue,
  network,
}) => {
  const firestore = getFirestore();

  const pendingPodcastsRef = collection(firestore, Collections.PodcastUsers);

  let baseQuery;
  if (!searchValue) {
    baseQuery = query(
      pendingPodcastsRef,
      where('waitingForConfirmation', '==', true),
      where('networks', 'array-contains', network),
      orderBy(selectedSort.sortBy, selectedSort.sortOrder),
      limit(first)
    );
  } else {
    baseQuery = query(
      pendingPodcastsRef,
      where('waitingForConfirmation', '==', true),
      where('podcastData.podcastTitle', '>=', searchValue),
      where('podcastData.podcastTitle', '<=', `${searchValue}\uf8ff`),
      where('networks', 'array-contains', network),
      orderBy('podcastData.podcastTitle', 'asc'),
      limit(first)
    );
  }

  const pendingPodcastsQuery = !lastItemPagination
    ? baseQuery
    : query(baseQuery, startAfter(lastItemPagination));

  const pendingPodcastsSapshots = await getDocs(pendingPodcastsQuery);

  const lastPodcast = pendingPodcastsSapshots.docs.at(-1);

  const allScreenshots = await getDocs(
    collectionGroup(firestore, Collections.AudienceEstimateScreenshots)
  );

  const allScreenshotsData = {};
  allScreenshots.forEach((doc) => {
    const data = doc.data();
    if (
      !allScreenshotsData[data.userId] ||
      allScreenshotsData[data.userId].createdAt < data.createdAt
    ) {
      allScreenshotsData[data.userId] = data;
    }
  });

  const pendingPodcasts = pendingPodcastsSapshots.docs.map((doc) => ({
    ...doc.data(),
    uid: doc.id,
    screenshots: allScreenshotsData[doc.id],
  }));

  if (selectedSort.sortBy === 'podcastData.audienceEstimate') {
    const audienceFilteredPodcasts = filterPodcastsByAudience(
      pendingPodcasts,
      selectedSort.sortOrder
    );
    const lastElement = audienceFilteredPodcasts.slice(-1)[0];
    return {
      lastPodcast: lastElement,
      pendingPodcasts: audienceFilteredPodcasts,
    };
  }

  return { lastPodcast, pendingPodcasts };
};

export default fetchPendingPodcasts;
