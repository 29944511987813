import Path from 'enums/path.enum';
import ApprovedBrands from 'pages/Brands/ApprovedBrands';
import PendingBrands from 'pages/Brands/PendingBrands';
import RejectedBrands from 'pages/Brands/RejectedBrands';
import Campaigns from 'pages/Campaigns';
import Forbidden from 'pages/Forbidden';
import Payments from 'pages/Payments';
import PendingDocuments from 'pages/PendingDocuments';
import ApprovedPodcasts from 'pages/Podcasts/ApprovedPodcasts';
import PendingPodcasts from 'pages/Podcasts/PendingPodcasts';
import RejectedPodcasts from 'pages/Podcasts/RejectedPodcasts';
import Settings from 'pages/Settings';

const authenticatedRoutes = [
  {
    component: Payments,
    path: Path.PodcastPayments,
  },
  {
    component: Payments,
    path: Path.Payments,
  },
  {
    component: PendingPodcasts,
    path: Path.PodcastsForApproval,
  },
  {
    component: PendingBrands,
    path: Path.BrandsForApproval,
  },
  {
    component: ApprovedPodcasts,
    path: Path.PodcastsApproved,
  },
  {
    component: RejectedPodcasts,
    path: Path.PodcastsRejected,
  },
  {
    component: ApprovedBrands,
    path: Path.BrandsApproved,
  },
  {
    component: RejectedBrands,
    path: Path.BrandsRejected,
  },
  {
    component: Campaigns,
    path: Path.Campaigns,
  },
  {
    component: Campaigns,
    path: Path.FilterCampaigns,
  },
  {
    component: PendingDocuments,
    path: Path.PendingDocuments,
  },
  {
    component: Settings,
    path: Path.Settings,
  },
  {
    component: Forbidden,
    path: Path.Forbidden,
  },
];

export default authenticatedRoutes;
