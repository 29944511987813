/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import formatDateToFileTitle from 'utils/dates';

import classes from './CustomAcceptModal.module.scss';

const CustomAcceptModal = ({
  title,
  description,
  onCancel,
  columns,
  paymentList,
}) => (
  <div className={classes.container}>
    <Body size={BodySize.S} className={classes.title}>
      {title}
    </Body>
    <Body size={BodySize.XS}>{description}</Body>
    <div className={classes.actions}>
      <Button
        className={classes.button}
        kind={ButtonKind.Tertiary}
        size={ButtonSize.S}
        onClick={onCancel}
      >
        Cancel
      </Button>

      <Button
        className={classes.button}
        kind={ButtonKind.Tertiary}
        size={ButtonSize.S}
        onClick={onCancel}
      >
        <CSVLink
          className={classes.exportButtonLink}
          headers={columns.map((column) => ({
            label: column.Header,
            key: column.accessor,
          }))}
          data={paymentList.map((payment) => ({
            ...payment,
            status: payment.closed ? 'closed' : payment.status,
            paid: payment.total - payment.paid,
            campaign: {
              ...payment.campaign,
            },
          }))}
          filename={`payments_${formatDateToFileTitle(new Date())}`}
        >
          Proceed
        </CSVLink>
      </Button>
    </div>
  </div>
);

CustomAcceptModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paymentList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CustomAcceptModal.defaultProps = {
  title: '',
  description: '',
};

export default CustomAcceptModal;
