/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconButton from 'components/Common/IconButton';
import Body, { Size as BodySize } from 'components/Typography/Body';
import ArrowDownIcon from 'assets/icons/arrow-down.png';

import classes from './Dropdown.module.scss';

const Dropdown = ({ options, className, isOpen, setIsDropDownOpen }) => {
  const [open, setOpen] = useState(isOpen);

  const onClickOpenHandler = () => {
    setOpen((prevState) => !prevState);
    setIsDropDownOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <IconButton onClick={onClickOpenHandler}>
        <img
          alt="dropdown"
          src={ArrowDownIcon}
          className={classNames({ [classes.open]: open })}
        />
      </IconButton>
      {open && (
        <div className={className}>
          <div className={classes.menu}>
            {options.map((option) => (
              <IconButton
                key={option.label}
                className={classes.option}
                onClick={() => option.onClick(setOpen)}
              >
                <Body size={BodySize.XS} className={classes.optionText}>
                  {option.label}
                </Body>
              </IconButton>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func })
  ).isRequired,
  className: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsDropDownOpen: PropTypes.func.isRequired,
};

export default Dropdown;
