import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import classes from './Switch.module.scss';

const Switch = ({ label, onClickSwitch, checked, className }) => (
  <div className={classes.container}>
    <div className={classes.label}>{label}</div>
    <label htmlFor="switch" className={classNames(classes.switch, className)}>
      <input defaultChecked={checked} type="checkbox" />
      <span
        aria-label="switch"
        tabIndex={0}
        aria-checked
        role="switch"
        onClick={() => {
          onClickSwitch();
        }}
        className={classes.slider}
        onKeyDown={() => {}}
        onChange={() => {}}
      />
    </label>
  </div>
);

Switch.propTypes = {
  label: PropTypes.string,
  onClickSwitch: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Switch.defaultProps = {
  label: '',
  className: '',
  onClickSwitch: () => {},
};

export default Switch;
