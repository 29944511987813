import {
  fetchPendingPodcastsInit,
  fetchPendingPodcastsSuccess,
  fetchPendingPodcastsFail,
  fetchApprovedPodcastsInit,
  fetchApprovedPodcastsSuccess,
  fetchApprovedPodcastsFail,
  fetchRejectedPodcastsInit,
  fetchRejectedPodcastsSuccess,
  fetchRejectedPodcastsFail,
  fetchPendingDocumentsPodcastsInit,
  fetchPendingDocumentsPodcastsSuccess,
  fetchPendingDocumentsPodcastsFail,
  acceptPendingDocumentInit,
  acceptPendingDocumentSuccess,
  acceptPendingDocumentFail,
  flagPendingDocumentInit,
  flagPendingDocumentSuccess,
  flagPendingDocumentFail,
  editPendingDocumentInit,
  editPendingDocumentSuccess,
  editPendingDocumentFail,
  acceptPodcastInit,
  acceptPodcastSuccess,
  acceptPodcastFail,
  rejectPodcastInit,
  rejectPodcastSuccess,
  rejectPodcastFail,
  sendToNetworkPodcastLoading,
  sendToNetworkPodcastSuccess,
  sendToNetworkPodcastFail,
  clearPodcasts,
  clearAllPodcasts,
  clearPendingDocuments,
  clearEditPendingDocument,
  editPodcastDataInit,
  editPodcastDataSuccess,
  editPodcastDataFail,
  clearEditPodcastData,
  hiatusPodcastFail,
  hiatusPodcastLoading,
  hiatusPodcastSuccess,
  fetchPodcastOffersFail,
  fetchPodcastOffersLoading,
  fetchPodcastOffersSuccess,
} from 'state/actionCreators/podcasts';
import fetchPendingPodcastsService from 'services/podcasts/fetchPendingPodcasts';
import fetchApprovedPodcastsService from 'services/podcasts/fetchApprovedPodcasts';
import fetchRejectedPodcastsService from 'services/podcasts/fetchRejectedPodcasts';
import acceptPodcastService from 'services/podcasts/acceptPodcast';
import rejectPodcastService from 'services/podcasts/rejectPodcast';
import sendToNetworkPodcastService from 'services/podcasts/sendToNetwork';
import hiatusPodcastService from 'services/podcasts/hiatusPodcast';
import fetchPodcastOffersService from 'services/podcasts/fetchPodcastOffers';
import fetchPendingDocumentsPodcastsService from 'services/podcasts/fetchPendingDocumentsPodcasts';
import acceptPendingDocumentService from 'services/podcasts/acceptPendingDocument';
import flagPendingDocumentService from 'services/podcasts/flagPendingDocument';
import editPendingDocumentService from 'services/podcasts/editPendingDocument';
import editPodcastDataService from 'services/podcasts/editPodcastData';

export const fetchPendingPodcasts = ({
  first,
  lastItemPagination,
  selectedSort,
  searchValue,
  network,
}) => async (dispatch) => {
  dispatch(fetchPendingPodcastsInit());

  try {
    const { lastPodcast, pendingPodcasts } = await fetchPendingPodcastsService({
      first,
      lastItemPagination,
      selectedSort,
      searchValue,
      network,
    });

    return dispatch(
      fetchPendingPodcastsSuccess({ lastPodcast, pendingPodcasts })
    );
  } catch (error) {
    return dispatch(fetchPendingPodcastsFail({ error: error.message }));
  }
};

export const fetchApprovedPodcasts = (selectedSort, searchValue) => async (
  dispatch
) => {
  dispatch(fetchApprovedPodcastsInit());

  try {
    const approvedPodcasts = await fetchApprovedPodcastsService(
      selectedSort,
      searchValue
    );

    return dispatch(fetchApprovedPodcastsSuccess({ approvedPodcasts }));
  } catch (error) {
    return dispatch(fetchApprovedPodcastsFail({ error: error.message }));
  }
};

export const fetchRejectedPodcasts = (network) => async (dispatch) => {
  dispatch(fetchRejectedPodcastsInit());

  try {
    const rejectedPodcasts = await fetchRejectedPodcastsService(network);

    return dispatch(fetchRejectedPodcastsSuccess({ rejectedPodcasts }));
  } catch (error) {
    return dispatch(fetchRejectedPodcastsFail({ error: error.message }));
  }
};

export const acceptPodcast = ({
  podcastId,
  newAudience,
  network = null,
}) => async (dispatch) => {
  dispatch(acceptPodcastInit());

  try {
    await acceptPodcastService({ podcastId, newAudience, network });

    return dispatch(acceptPodcastSuccess());
  } catch (error) {
    return dispatch(acceptPodcastFail({ error: error.message }));
  }
};

export const rejectPodcast = ({ podcastId, rejectionReason }) => async (
  dispatch
) => {
  dispatch(rejectPodcastInit());

  try {
    await rejectPodcastService({ podcastId, rejectionReason });

    return dispatch(rejectPodcastSuccess());
  } catch (error) {
    return dispatch(rejectPodcastFail({ error: error.message }));
  }
};

export const sendToNetworkPodcast = ({ podcastId, network }) => async (
  dispatch
) => {
  try {
    dispatch(sendToNetworkPodcastLoading());
    await sendToNetworkPodcastService({
      podcastId,
      network,
    });

    return dispatch(sendToNetworkPodcastSuccess());
  } catch (error) {
    return dispatch(sendToNetworkPodcastFail({ error: error.message }));
  }
};

export const hiatusPodcast = ({ podcastId, status }) => async (dispatch) => {
  try {
    dispatch(hiatusPodcastLoading());
    await hiatusPodcastService({
      podcastId,
      status,
    });

    return dispatch(hiatusPodcastSuccess());
  } catch (error) {
    return dispatch(hiatusPodcastFail({ error: error.message }));
  }
};

export const fetchPodcastOffers = ({ podcastId }) => async (dispatch) => {
  try {
    dispatch(fetchPodcastOffersLoading());
    const podcastOffers = await fetchPodcastOffersService({
      podcastId,
    });

    return dispatch(fetchPodcastOffersSuccess(podcastOffers));
  } catch (error) {
    return dispatch(fetchPodcastOffersFail({ error: error.message }));
  }
};

export const fetchPendingDocumentsPodcasts = (network) => async (dispatch) => {
  dispatch(fetchPendingDocumentsPodcastsInit());

  try {
    const {
      pendingDocumentsPodcasts,
    } = await fetchPendingDocumentsPodcastsService(network);

    return dispatch(
      fetchPendingDocumentsPodcastsSuccess({ pendingDocumentsPodcasts })
    );
  } catch (error) {
    return dispatch(
      fetchPendingDocumentsPodcastsFail({ error: error.message })
    );
  }
};

export const acceptPendingDocument = ({ screenshot }) => async (dispatch) => {
  dispatch(acceptPendingDocumentInit());

  try {
    await acceptPendingDocumentService(screenshot);

    return dispatch(acceptPendingDocumentSuccess());
  } catch (error) {
    return dispatch(acceptPendingDocumentFail({ error: error.message }));
  }
};

export const flagPendingDocument = ({ screenshot }) => async (dispatch) => {
  dispatch(flagPendingDocumentInit());

  try {
    await flagPendingDocumentService(screenshot);

    return dispatch(flagPendingDocumentSuccess());
  } catch (error) {
    return dispatch(flagPendingDocumentFail({ error: error.message }));
  }
};

export const editPendingDocument = ({ screenshot, editedData }) => async (
  dispatch
) => {
  dispatch(editPendingDocumentInit());

  try {
    await editPendingDocumentService(screenshot, editedData);

    return dispatch(editPendingDocumentSuccess());
  } catch (error) {
    return dispatch(editPendingDocumentFail({ error: error.message }));
  }
};

export const editPodcastData = ({ podcastId, editedData }) => async (
  dispatch
) => {
  dispatch(editPodcastDataInit());

  try {
    await editPodcastDataService(podcastId, editedData);

    return dispatch(editPodcastDataSuccess());
  } catch (error) {
    return dispatch(editPodcastDataFail({ error: error.message }));
  }
};

export const clearPodcastsState = () => clearPodcasts();

export const clearEditPodcastDataState = () => clearEditPodcastData();

export const clearAllPodcastsState = () => clearAllPodcasts();

export const clearPendingDocumentsState = () => clearPendingDocuments();

export const clearEditPendingDocumentState = () => clearEditPendingDocument();
