/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPendingBrandsInit,
  fetchPendingBrandsSuccess,
  fetchPendingBrandsFail,
  fetchApprovedBrandsInit,
  fetchApprovedBrandsSuccess,
  fetchApprovedBrandsFail,
  fetchRejectedBrandsInit,
  fetchRejectedBrandsSuccess,
  fetchRejectedBrandsFail,
  acceptBrandInit,
  acceptBrandSuccess,
  acceptBrandFail,
  rejectBrandInit,
  rejectBrandSuccess,
  rejectBrandFail,
  clearBrands,
} from 'state/actionCreators/brands';

export const initialState = {
  fetchPendingBrandsLoading: false,
  fetchPendingBrandsSuccess: false,
  fetchPendingBrandsError: null,
  fetchApprovedBrandsLoading: false,
  fetchApprovedBrandsSuccess: false,
  fetchApprovedBrandsError: null,
  fetchRejectedBrandsLoading: false,
  fetchRejectedBrandsSuccess: false,
  fetchRejectedBrandsError: null,
  acceptBrandLoading: false,
  acceptBrandSuccess: false,
  acceptBrandError: null,
  rejectBrandLoading: false,
  rejectBrandSuccess: false,
  rejectBrandError: null,
  pendingBrands: [],
  approvedBrands: [],
  rejectedBrands: [],
};

const brandsReducer = createReducer(initialState, {
  [fetchPendingBrandsInit]: (state) => {
    state.fetchPendingBrandsLoading = true;
    state.fetchPendingBrandsSuccess = false;
    state.fetchPendingBrandsError = null;
    state.pendingBrands = [];
  },
  [fetchPendingBrandsSuccess]: (state, { payload }) => {
    const { pendingBrands } = payload;
    state.fetchPendingBrandsLoading = false;
    state.fetchPendingBrandsSuccess = true;
    state.fetchPendingBrandsError = null;
    state.pendingBrands = pendingBrands;
  },
  [fetchPendingBrandsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPendingBrandsLoading = false;
    state.fetchPendingBrandsSuccess = false;
    state.fetchPendingBrandsError = error;
  },
  [fetchApprovedBrandsInit]: (state) => {
    state.fetchApprovedBrandsLoading = true;
    state.fetchApprovedBrandsSuccess = false;
    state.fetchApprovedBrandsError = null;
    state.approvedBrands = [];
  },
  [fetchApprovedBrandsSuccess]: (state, { payload }) => {
    const { approvedBrands } = payload;
    state.fetchApprovedBrandsLoading = false;
    state.fetchApprovedBrandsSuccess = true;
    state.fetchApprovedBrandsError = null;
    state.approvedBrands = approvedBrands;
  },
  [fetchApprovedBrandsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchApprovedBrandsLoading = false;
    state.fetchApprovedBrandsSuccess = false;
    state.fetchApprovedBrandsError = error;
  },
  [fetchRejectedBrandsInit]: (state) => {
    state.fetchRejectedBrandsLoading = true;
    state.fetchRejectedBrandsSuccess = false;
    state.fetchRejectedBrandsError = null;
    state.rejectedBrands = [];
  },
  [fetchRejectedBrandsSuccess]: (state, { payload }) => {
    const { rejectedBrands } = payload;
    state.fetchRejectedBrandsLoading = false;
    state.fetchRejectedBrandsSuccess = true;
    state.fetchRejectedBrandsError = null;
    state.rejectedBrands = rejectedBrands;
  },
  [fetchRejectedBrandsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchRejectedBrandsLoading = false;
    state.fetchRejectedBrandsSuccess = false;
    state.fetchRejectedBrandsError = error;
  },
  [acceptBrandInit]: (state) => {
    state.acceptBrandLoading = true;
    state.acceptBrandSuccess = false;
    state.acceptBrandError = null;
  },
  [acceptBrandSuccess]: (state) => {
    state.acceptBrandLoading = false;
    state.acceptBrandSuccess = true;
    state.acceptBrandError = null;
  },
  [acceptBrandFail]: (state, { payload }) => {
    const { error } = payload;
    state.acceptBrandLoading = false;
    state.acceptBrandSuccess = false;
    state.acceptBrandError = error;
  },
  [rejectBrandInit]: (state) => {
    state.rejectBrandLoading = true;
    state.rejectBrandSuccess = false;
    state.rejectBrandError = null;
  },
  [rejectBrandSuccess]: (state) => {
    state.rejectBrandLoading = false;
    state.rejectBrandSuccess = true;
    state.rejectBrandError = null;
  },
  [rejectBrandFail]: (state, { payload }) => {
    const { error } = payload;
    state.rejectBrandLoading = false;
    state.rejectBrandSuccess = false;
    state.rejectBrandError = error;
  },
  [clearBrands]: (state) => {
    state.fetchPendingBrandsLoading = false;
    state.fetchPendingBrandsSuccess = false;
    state.fetchPendingBrandsError = null;
    state.fetchApprovedBrandsLoading = false;
    state.fetchApprovedBrandsSuccess = false;
    state.fetchApprovedBrandsError = null;
    state.fetchRejectedBrandsLoading = false;
    state.fetchRejectedBrandsSuccess = false;
    state.fetchRejectedBrandsError = null;
    state.acceptBrandLoading = false;
    state.acceptBrandSuccess = false;
    state.acceptBrandError = null;
    state.rejectBrandLoading = false;
    state.rejectBrandSuccess = false;
    state.rejectBrandError = null;
    state.pendingBrands = [];
    state.approvedBrands = [];
    state.rejectedBrands = [];
  },
});

export default brandsReducer;
