import Path from 'enums/path.enum';
import getPathsByPermission from './getPathsByPermission';

const getPrimaryRoute = (user) => {
  if (user === null) {
    return Path.Login;
  }

  const permissions = getPathsByPermission(user.permissions);

  if (permissions.length > 0) {
    if (permissions.includes(Path.Payments)) {
      return Path.Payments;
    }

    return permissions[0];
  }

  return Path.Forbidden;
};

export default getPrimaryRoute;
