import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Tabs from 'components/Common/Tabs';
import Tab from 'components/Common/Tab';
import CampaignSummary from 'components/Pages/Campaigns/CampaignSummary';
import Spinner from 'components/Common/Spinner';
import Modal from 'components/Common/Modal';
import CampaignDetails from 'components/Pages/Campaigns/CampaignDetails';
import Form from 'components/Common/Form';
import AdCopy from 'components/Pages/Campaigns/AdCopy';
import Toast from 'components/Common/Toast';
import {
  selectFetchCurrentCampaignsState,
  selectFetchFinishedCampaignsState,
  selectSendAdCopyState,
} from 'state/selectors/campaigns';
import {
  clearAdCopyState,
  clearCampaignState,
  fetchCurrentCampaigns,
  fetchFinishedCampaigns,
} from 'state/actions/campaigns';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import { fetchApprovedBrands } from 'state/actions/brands';
import { selectFetchApprovedBrandsState } from 'state/selectors/brands';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import Path from 'enums/path.enum';
import SelectInput from 'components/Common/Select';
import campaignStatus from 'enums/campaigns/campaignStatus.enum';

import classes from './Campaigns.module.scss';

const Campaigns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { brandId, campaignId } = useParams();
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    approvedBrands,
    success: successFetchingApprovedBrands,
    loading: loadingFetchingApprovedBrands,
  } = useSelector(selectFetchApprovedBrandsState, shallowEqual);

  const {
    campaigns: currentCampaigns,
    loading: loadingCurrentCampaigns,
    success: successFetchingCurrentCampaigns,
  } = useSelector(selectFetchCurrentCampaignsState, shallowEqual);

  const {
    campaigns: finishedCampaigns,
    loading: loadingFinishedCampaigns,
    success: successFetchingFinishedCampaigns,
  } = useSelector(selectFetchFinishedCampaignsState, shallowEqual);

  const {
    success: successSendingAdCopy,
    error: errorSendingAdCopy,
  } = useSelector(selectSendAdCopyState, shallowEqual);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [allBrands, setAllBrands] = useState([]);
  const [brandCurrentCampaigns, setBrandCurrentCampaigns] = useState([]);
  const [brandFinishedCampaigns, setBrandFinishedCampaigns] = useState([]);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (user) dispatch(fetchApprovedBrands(user.network));
  }, [user]);

  useEffect(() => {
    if (
      successFetchingApprovedBrands &&
      allBrands.length === 0 &&
      approvedBrands.length > 0
    ) {
      setAllBrands(
        approvedBrands.map((brand) => ({
          label: brand.name,
          value: brand,
        }))
      );
      if (brandId) {
        const brandValue = approvedBrands.find(
          (brand) => brand.uid === brandId
        );
        setSelectedBrand({ label: brandValue.brandName, value: brandValue });
      }
    }
  }, [successFetchingApprovedBrands, allBrands, approvedBrands, brandId]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(fetchCurrentCampaigns({ brandId: selectedBrand.value?.uid }));
      dispatch(fetchFinishedCampaigns({ brandId: selectedBrand.value?.uid }));
      setBrandCurrentCampaigns([]);
      setBrandFinishedCampaigns([]);
    }
  }, [selectedBrand?.value]);

  useEffect(() => {
    if (finishedCampaigns.length > 0 && brandFinishedCampaigns.length === 0) {
      if (campaignId) {
        const selectedCampaign = finishedCampaigns.find(
          (campaing) => campaing.uid === campaignId
        );

        const orderedCampagins = selectedCampaign
          ? [
              selectedCampaign,
              ...finishedCampaigns.filter(
                (campaign) => campaign.uid !== campaignId
              ),
            ]
          : finishedCampaigns;

        setBrandFinishedCampaigns([...orderedCampagins]);
      } else {
        setBrandFinishedCampaigns(finishedCampaigns);
      }
    }
  }, [finishedCampaigns.length, brandFinishedCampaigns.length]);

  useEffect(() => {
    if (currentCampaigns.length > 0 && brandCurrentCampaigns.length === 0) {
      setBrandCurrentCampaigns(currentCampaigns);
    }
  }, [currentCampaigns.length, brandCurrentCampaigns.length]);

  useEffect(() => {
    if (successFetchingCurrentCampaigns && successFetchingFinishedCampaigns) {
      dispatch(clearCampaignState());
    }
  }, [successFetchingCurrentCampaigns, successFetchingFinishedCampaigns]);

  useEffect(() => {
    if (
      !selectedBrand &&
      (brandCurrentCampaigns.length > 0 || brandFinishedCampaigns.length > 0)
    ) {
      setBrandCurrentCampaigns([]);
      setBrandFinishedCampaigns([]);
    }
  }, [
    selectedBrand,
    brandCurrentCampaigns.length,
    brandFinishedCampaigns.length,
  ]);

  useEffect(() => {
    if (successSendingAdCopy) {
      onCloseModalHandler();
      dispatch(clearAdCopyState());
      setBrandCurrentCampaigns([]);
      dispatch(fetchCurrentCampaigns({ brandId: selectedBrand?.value?.uid }));
    }
  }, [successSendingAdCopy, errorSendingAdCopy, selectedBrand?.value]);

  useEffect(() => {
    if (errorSendingAdCopy) {
      dispatch(clearAdCopyState());
    }
  }, [errorSendingAdCopy]);

  const onChangeSelectedBrand = useCallback((brand) => {
    setSelectedBrand(brand);
  }, []);

  const onClickMoreDetailsHandler = useCallback((campaign) => {
    onOpenModalHandler(ModalType.CAMPAIGN_SUMMARY, {
      campaign,
    });
  }, []);

  const onClickPaymentHandler = useCallback((podcastId) => {
    history.push(`${Path.Payments}/${podcastId}`);
  }, []);

  const onClickAdCopyHandler = useCallback(({ campaign, offer }) => {
    onOpenModalHandler(ModalType.AD_COPY, {
      campaign,
      offer,
    });
  }, []);

  const tabs = [
    {
      label: 'Current campaigns',
      key: 'currentCampaigns',
    },
    {
      label: 'Finished campaigns',
      key: 'finishedCampaigns',
    },
  ];

  return (
    <>
      {errorSendingAdCopy && (
        <Toast text={errorSendingAdCopy} id="Send ad copy error" />
      )}
      <Modal
        isOpen={modal.type === ModalType.CAMPAIGN_SUMMARY}
        onClose={onCloseModalHandler}
        className={classes.campaignSummaryModal}
      >
        <CampaignDetails campaign={modal.campaign} title="Campaign details" />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.AD_COPY}
        onClose={onCloseModalHandler}
        className={classes.campaignSummaryModal}
      >
        <AdCopy
          campaign={modal.campaign}
          offer={modal.offer}
          brandId={selectedBrand?.value?.uid}
        />
      </Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Campaigns
          </Body>
          <Form className={classes.brandForm}>
            <SelectInput
              name="brand"
              className={classes.selectBrand}
              placeholder="Select brand"
              options={allBrands}
              value={selectedBrand}
              onChange={onChangeSelectedBrand}
              loading={loadingFetchingApprovedBrands}
              isClearable
            />
          </Form>
          {selectedBrand && (
            <Tabs
              className={classes.tabs}
              active={
                brandId ? campaignStatus.finished : campaignStatus.current
              }
            >
              <Tab tab={tabs[0].label} key={tabs[0].key}>
                {loadingCurrentCampaigns ? (
                  <Spinner className={classes.spinner} />
                ) : (
                  <div className={classes.campaigns}>
                    {brandCurrentCampaigns.map((campaign) => (
                      <CampaignSummary
                        key={campaign.name}
                        campaign={campaign}
                        brandName={selectedBrand?.value?.name}
                        onClickMoreDetails={() =>
                          onClickMoreDetailsHandler(campaign)
                        }
                        onClickAdCopy={onClickAdCopyHandler}
                      />
                    ))}
                    {brandCurrentCampaigns.length === 0 && (
                      <Body
                        letterCase={BodyLetterCase.Uppercase}
                        size={BodySize.XS}
                        className={classes.noCampaigns}
                      >
                        There are no current campaigns
                      </Body>
                    )}
                  </div>
                )}
              </Tab>
              <Tab tab={tabs[1].label} key={tabs[1].key}>
                {loadingFinishedCampaigns ? (
                  <Spinner className={classes.spinner} />
                ) : (
                  <div className={classes.campaigns}>
                    {brandFinishedCampaigns.map((campaign) => (
                      <CampaignSummary
                        key={campaign.name}
                        campaign={campaign}
                        brandName={selectedBrand?.value?.name}
                        onClickMoreDetails={() =>
                          onClickMoreDetailsHandler(campaign)
                        }
                        hasFinished
                        onClickPayment={onClickPaymentHandler}
                      />
                    ))}
                    {brandFinishedCampaigns.length === 0 && (
                      <Body
                        letterCase={BodyLetterCase.Uppercase}
                        size={BodySize.XS}
                        className={classes.noCampaigns}
                      >
                        There are no finished campaigns
                      </Body>
                    )}
                  </div>
                )}
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
};

export default Campaigns;
