import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const rejectBrand = async ({ brandId }) => {
  const firestore = getFirestore();

  const rejectedBrandRef = doc(firestore, Collections.Brands, brandId);

  await setDoc(
    rejectedBrandRef,
    {
      waitingForConfirmation: false,
      enabled: false,
      rejectedDate: Timestamp.now().toMillis(),
    },
    { merge: true }
  );
};

export default rejectBrand;
