import offerStatus from 'enums/campaigns/offerStatus.enum';

const getCampaignOffersStatus = (campaign) => {
  if (campaign.offers?.some((offer) => offer.status === offerStatus.rejected)) {
    return offerStatus.rejected;
  }
  if (campaign.offers?.some((offer) => offer.status === offerStatus.accepted)) {
    return offerStatus.accepted;
  }
  return offerStatus.pending;
};

export default getCampaignOffersStatus;
