/* eslint-disable react/prop-types */
import classNames from 'classnames';

import Dropdown from 'components/Common/Dropdown';
import paymentStatus from 'enums/payments/paymentStatus.enum';
import getPaymentDropdownOptions from 'utils/payments/getPaymentDropdownOptions';
import sortPaymentsDates from 'utils/payments/sortPaymentsDates';
import fixDecimalPart from 'utils/numbers/fixDecimalPart';

import classes from 'pages/Payments/Payments.module.scss';
import { EXTERNAL_PAID_STRIPE } from 'constants/payments';

const customStringSorting = (first, second) => {
  const lowerFirst = first?.toLowerCase();
  const lowerSecond = second?.toLowerCase();
  return lowerFirst?.localeCompare(lowerSecond) || 1;
};

const getPendingPaymentsColumns = ({
  onClickPay,
  onClickPartialPay,
  onClickClosePay,
  onClickReject,
  onClickCampaign,
}) => [
  {
    Header: 'header',
    columns: [
      {
        Header: 'Status',
        accessor: 'status',
        columnName: 'status',
        Cell: ({ row: { original } }) => (
          <div
            className={classNames(
              classes.status,
              classes[
                original?.podcaster.stripeId === EXTERNAL_PAID_STRIPE ||
                original?.podcaster.stripeId === ''
                  ? EXTERNAL_PAID_STRIPE
                  : original?.status
              ],
              original.closed ? classes.closed : null
            )}
          />
        ),
      },
      {
        Header: 'Total Amount',
        accessor: 'total',
        columnName: 'total',
        Cell: ({ value }) => `$${fixDecimalPart(value)}`,
      },
      {
        Header: 'Amount Remaining',
        accessor: 'paid',
        columnName: 'paid',
        Cell: ({ value, row }) => {
          const amount = row?.original?.total;
          return `$${Number((amount - (value || 0)).toFixed(2))}`;
        },
        sortType: (first, second) => {
          const firstAmount = first?.original?.total || 0;
          const secondAmount = second?.original?.total || 0;

          const firstPaid = first?.original?.paid || 0;
          const secondPaid = second?.original?.paid || 0;

          const firstRemaining = firstAmount - firstPaid;
          const secondRemaining = secondAmount - secondPaid;

          return fixDecimalPart(firstRemaining - secondRemaining);
        },
      },
      {
        Header: 'Processing Date',
        accessor: 'processingDate',
        columnName: 'processingDate',
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        Header: 'From',
        accessor: 'campaign.startDate',
        columnName: 'campaign.startDate',
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortType: sortPaymentsDates,
      },
      {
        Header: 'To',
        accessor: 'campaign.endDate',
        columnName: 'campaign.endDate',
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortType: sortPaymentsDates,
      },
      {
        Header: 'Brand',
        accessor: 'brand.name',
        columnName: 'brand.name',
        sortType: (first, second) => {
          const firstSource = first?.original?.brand.name;
          const secondSource = second?.original?.brand.name;
          return customStringSorting(firstSource, secondSource);
        },
      },
      {
        Header: 'CPM Rate',
        accessor: 'campaign.cpmRate',
        columnName: 'campaign.cpmRate',
        Cell: ({ value }) => `$${Number(value).toFixed(2)}`,
      },
      {
        Header: 'Network %',
        accessor: 'campaign.percentages.network',
        columnName: 'campaign.percentages.network',
        Cell: ({ row }) => {
          const amount = row?.original?.campaign?.percentages?.network;
          return `${Math.round(Number(amount * 100))}%`;
        },
      },
      {
        Header: 'Podcaster %',
        accessor: 'campaign.percentages.podcaster',
        columnName: 'campaign.percentages.podcaster',
        Cell: ({ row }) => {
          const amount = row?.original?.campaign?.percentages?.podcaster;
          return `${Math.round(Number(amount * 100))}%`;
        },
      },
      {
        Header: 'Podcast',
        accessor: 'podcaster.podcast.name',
        columnName: 'podcaster.podcast.name',
        sortType: (first, second) => {
          const firstTitle = first?.original?.podcaster.podcast.name;
          const secondTitle = second?.original?.podcaster.podcast.name;
          return customStringSorting(firstTitle, secondTitle);
        },
      },
      {
        Header: 'Impressions',
        accessor: 'podcaster.podcast.impressions',
        columnName: 'podcaster.podcast.impressions',
      },
      {
        Header: 'Episodes',
        accessor: 'podcaster.podcast.episodes',
        columnName: 'podcaster.podcast.episodes',
      },
      {
        Header: 'Email',
        accessor: 'podcaster.email',
        columnName: 'podcaster.email',
        sortType: (first, second) => {
          const firstEmail = first?.original?.podcaster.email;
          const secondEmail = second?.original?.podcaster.email;
          return customStringSorting(firstEmail, secondEmail);
        },
      },

      {
        Header: 'Campaign Name',
        accessor: 'campaign.name',
        columnName: 'campaign.name',
        sortType: (first, second) => {
          const firstName = first?.original?.campaign.name;
          const secondName = second?.original?.campaign.name;
          return customStringSorting(firstName, secondName);
        },
      },
      {
        Header: 'Stripe ID',
        accessor: 'podcaster.stripeId',
        columnName: 'podcaster.stripeId',
      },
      {
        Header: '',
        accessor: 'pay',
        columnName: 'pay',
        isPaymentButton: true,
        Cell: ({ row: { original } }) => {
          const { status, closed } = original;

          const options = getPaymentDropdownOptions({
            status,
            closed,
            onClickPay,
            onClickPartialPay,
            onClickClosePay,
            onClickReject,
            onClickCampaign,
            row: original.id,
            external:
              original?.podcaster.stripeId === EXTERNAL_PAID_STRIPE ||
              original?.podcaster.stripeId === '',
          });

          const DROPPABLE_STATES = [
            paymentStatus.Pending,
            paymentStatus.Partial,
          ];

          if (DROPPABLE_STATES.includes(status)) {
            return (
              <Dropdown
                options={options}
                className=""
                isOpen={false}
                setIsDropDownOpen={() => {}}
              />
            );
          }
          return null;
        },
      },
    ],
  },
];

export default getPendingPaymentsColumns;
