import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './Tabs.module.scss';

const Tabs = ({ children, className, active }) => {
  const [activeTab, setActiveTab] = useState(children[active].props.tab);

  const isCurrentTab = useCallback((tabName) => activeTab === tabName, [
    activeTab,
  ]);

  const handleClick = useCallback((e, newActiveTab) => {
    e.preventDefault();

    setActiveTab(newActiveTab);
  }, []);

  return (
    <div className={className}>
      <ul className={classes.tabs}>
        {children.map(({ props: { tab: tabName } }) => (
          <li
            className={classNames(classes.tab, {
              [classes.current]: isCurrentTab(tabName),
            })}
            key={tabName}
          >
            <button
              type="button"
              className={classes.button}
              onClick={(e) => handleClick(e, tabName)}
            >
              <Body
                size={BodySize.S}
                className={classNames(classes.buttonText, {
                  [classes.currentText]: isCurrentTab(tabName),
                })}
              >
                {tabName}
              </Body>
            </button>
          </li>
        ))}
      </ul>
      {children.find((tabContent) => isCurrentTab(tabContent.props.tab))}
    </div>
  );
};

const childrenValidation = (props, propName, componentName) => {
  PropTypes.checkPropTypes(
    {
      [propName]: PropTypes.arrayOf(PropTypes.node).isRequired,
    },
    props,
    propName,
    componentName
  );

  const tabNames = props.children.map(
    ({ props: childrenProps }) => childrenProps.tab
  );

  /* istanbul ignore next */
  return new Set(tabNames).size !== tabNames.length
    ? new Error(
        'Encountered two children with the same tab name. Tab names should be unique so the component knows what content render'
      )
    : null;
};

Tabs.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: childrenValidation,
  className: PropTypes.string,
  active: PropTypes.number,
};

Tabs.defaultProps = {
  className: '',
  active: 0,
};

export default Tabs;
