import PathsByPermission from 'enums/pathsByPermission.enum';

const getPathsByPermission = (userPermissions) => {
    const matchedPaths = [];

    Object.keys(PathsByPermission).forEach((permission) => {
        if (userPermissions !== undefined && userPermissions[permission]) {
        matchedPaths.push(...PathsByPermission[permission]);
        }
    });

    return matchedPaths;
};

export default getPathsByPermission;