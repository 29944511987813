import removeAccents from 'utils/functions/removeAccents';

const filterPodcastsBySearchValue = (podcasts = [], searchValue = '') =>
  podcasts.filter(({ podcastData }) => {
    const podcastNameWithoutAccents = removeAccents(
      podcastData.podcastTitle ?? 'Podcast'
    )
      .toLowerCase()
      .trim();

    const searchValueWithoutAccents = removeAccents(searchValue)
      .toLowerCase()
      .trim();

    return podcastNameWithoutAccents.includes(searchValueWithoutAccents);
  });

export default filterPodcastsBySearchValue;
