import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = ({ users }) => users;

export const selectAcceptUserState = createSelector(
    selectUsersState,
    ({ acceptUserLoading, acceptUserSuccess, acceptUserError }) => ({
        loading: acceptUserLoading,
        success: acceptUserSuccess,
        error: acceptUserError,
    })
);

export const selectCreateUserState = createSelector(
    selectUsersState,
    ({
        createUserLoading,
        createUserSuccess,
        createUserError,
        createUserResult,
    }) => ({
        loading: createUserLoading,
        success: createUserSuccess,
        error: createUserError,
        createUserResult,
    })
);

export const selectFetchUsersState = createSelector(
    selectUsersState,
    ({
        fetchUsersLoading,
        fetchUsersSuccess,
        fetchUsersError,
        users,
    }) => ({
        loading: fetchUsersLoading,
        success: fetchUsersSuccess,
        error: fetchUsersError,
        users
    })
);

export const selectUpdateUserState = createSelector(
    selectUsersState,
    ({
        updateUserLoading,
        updateUserSuccess,
        updateUserError
    }) => ({
        loading: updateUserLoading,
        success: updateUserSuccess,
        error: updateUserError
    })
);

export const selectDeleteUserState = createSelector(
    selectUsersState,
    ({
        deleteUserLoading,
        deleteUserSuccess,
        deleteUserError
    }) => ({
        loading: deleteUserLoading,
        success: deleteUserSuccess,
        error: deleteUserError
    })
);
