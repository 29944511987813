import React from 'react';
import RcSlider, { SliderTooltip } from 'rc-slider';
import PropTypes from 'prop-types';
import { brandName } from 'assets/white-label';

const { Handle: SliderHandle } = RcSlider;

const Handle = ({ value, dragging, index, ...restProps }) => (
  <SliderTooltip
    prefixCls="rc-slider-tooltip"
    overlay={`${100 - value}% ${brandName}, ${value}% Podcast`}
    visible={dragging}
    placement="top"
    key={index}
  >
    <SliderHandle value={value} {...restProps} />
  </SliderTooltip>
);

Handle.propTypes = {
  value: PropTypes.number.isRequired,
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default Handle;
