/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  clearUsers,
  createUserInit,
  createUserLoading,
  createUserSuccess,
  createUserFail,
  fetchUsersInit,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchUsersLoading,
  updateUserInit,
  updateUserSuccess,
  updateUserFail,
  updateUserLoading,
  deleteUserInit,
  deleteUserSuccess,
  deleteUserFail,
  deleteUserLoading,
  resetUser,
} from 'state/actionCreators/users';

export const initialState = {
  createUserLoading: false,
  createUserSuccess: false,
  createUserError: null,
  createUserResult: {},
  fetchUsersLoading: false,
  fetchUsersSuccess: false,
  fetchUsersError: null,
  users: [],
  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: null,
  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserError: null,
};

const usersReducer = createReducer(initialState, {
  [clearUsers]: (state) => {
    state.createUserLoading = false;
    state.createUserSuccess = false;
    state.createUserError = null;
    state.createUserResult = {};
  },
  [createUserInit]: (state) => {
    state.createUserLoading = false;
    state.createUserSuccess = false;
    state.createUserError = null;
    state.createUserResult = {};
  },
  [createUserLoading]: (state) => {
    state.createUserLoading = true;
    state.createUserSuccess = false;
    state.createUserError = null;
    state.createUserResult = {};
  },
  [createUserSuccess]: (state) => {
    state.createUserLoading = false;
    state.createUserSuccess = true;
    state.createUserError = null;
    state.createUserResult = {};
  },
  [createUserFail]: (state, { payload }) => {
    const { error } = payload;
    state.createUserLoading = false;
    state.createUserSuccess = false;
    state.createUserError = error;
    state.createUserResult = {};
  },
  [fetchUsersInit]: (state) => {
    state.fetchUsersLoading = false;
    state.fetchUsersSuccess = false;
    state.fetchUsersError = null;
  },
  [fetchUsersSuccess]: (state, { payload }) => {
    const { users } = payload;
    state.fetchUsersLoading = false;
    state.fetchUsersSuccess = true;
    state.fetchUsersError = null;
    state.users = users;
  },
  [fetchUsersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchUsersLoading = false;
    state.fetchUsersSuccess = false;
    state.fetchUsersError = error;
  },
  [fetchUsersLoading]: (state) => {
    state.fetchUsersLoading = true;
    state.fetchUsersSuccess = false;
    state.fetchUsersError = null;
  },
  [updateUserInit]: (state) => {
    state.updateUserLoading = true;
    state.updateUserSuccess = false;
    state.updateUserError = null;
  },
  [updateUserSuccess]: (state) => {
    state.updateUserLoading = false;
    state.updateUserSuccess = true;
    state.updateUserError = null;
  },
  [updateUserFail]: (state, { payload }) => {
    const { error } = payload;
    state.updateUserLoading = false;
    state.updateUserSuccess = false;
    state.updateUserError = error;
  },
  [updateUserLoading]: (state) => {
    state.updateUserLoading = true;
    state.updateUserSuccess = false;
    state.updateUserError = null;
  },
  [deleteUserInit]: (state) => {
    state.deleteUserLoading = true;
    state.deleteUserSuccess = false;
    state.deleteUserError = null;
  },
  [deleteUserSuccess]: (state) => {
    state.deleteUserLoading = false;
    state.deleteUserSuccess = true;
    state.deleteUserError = null;
  },
  [deleteUserFail]: (state, { payload }) => {
    const { error } = payload;
    state.deleteUserLoading = false;
    state.deleteUserSuccess = false;
    state.deleteUserError = error;
  },
  [deleteUserLoading]: (state) => {
    state.deleteUserLoading = true;
    state.deleteUserSuccess = false;
    state.deleteUserError = null;
  },
  [resetUser]: (state) => {
    state.fetchUsersLoading = false;
    state.fetchUsersSuccess = false;
    state.fetchUsersError = null;

    state.createUserLoading = false;
    state.createUserSuccess = false;
    state.createUserError = null;

    state.updateUserLoading = false;
    state.updateUserSuccess = false;
    state.updateUserError = null;

    state.deleteUserLoading = false;
    state.deleteUserSuccess = false;
    state.deleteUserError = null;
  },
});

export default usersReducer;
