import { createSelector } from '@reduxjs/toolkit';

const selectPaymentsState = ({ payments }) => payments;

export const selectFetchPendingPaymentsState = createSelector(
  selectPaymentsState,
  ({
    fetchPendingPaymentsLoading,
    fetchPendingPaymentsSuccess,
    fetchPendingPaymentsError,
    pendingPayments,
  }) => ({
    loading: fetchPendingPaymentsLoading,
    success: fetchPendingPaymentsSuccess,
    error: fetchPendingPaymentsError,
    pendingPayments,
  })
);

export const selectRejectPaymentState = createSelector(
  selectPaymentsState,
  ({ rejectPaymentLoading, rejectPaymentSuccess, rejectPaymentError }) => ({
    loading: rejectPaymentLoading,
    success: rejectPaymentSuccess,
    error: rejectPaymentError,
  })
);

export const selectAcceptPaymentState = createSelector(
  selectPaymentsState,
  ({ acceptPaymentLoading, acceptPaymentSuccess, acceptPaymentError }) => ({
    loading: acceptPaymentLoading,
    success: acceptPaymentSuccess,
    error: acceptPaymentError,
  })
);

export const selectClosePaymentState = createSelector(
  selectPaymentsState,
  ({ closePaymentLoading, closePaymentSuccess, closePaymentError }) => ({
    loading: closePaymentLoading,
    success: closePaymentSuccess,
    error: closePaymentError,
  })
);

export const selectCreatePaymentState = createSelector(
  selectPaymentsState,
  ({
    createPaymentLoading,
    createPaymentSuccess,
    createPaymentError,
    createPaymentResult,
  }) => ({
    loading: createPaymentLoading,
    success: createPaymentSuccess,
    error: createPaymentError,
    createPaymentResult,
  })
);
