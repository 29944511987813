import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Link from 'components/Typography/Link';
import Path from 'enums/path.enum';

import classes from './Footer.module.scss';

const Footer = ({ className }) => (
  <footer className={classNames(classes.footer, className)}>
    <Link
      to={{ pathname: Path.TermsOfService }}
      underline={false}
      target="_blank"
    >
      <Body
        color={BodyColor.Gray}
        size={BodySize.XXS}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.S}
      >
        Terms of service
      </Body>
    </Link>
    <Link to={{ pathname: Path.Privacy }} underline={false} target="_blank">
      <Body
        color={BodyColor.Gray}
        size={BodySize.XXS}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.S}
      >
        Privacy
      </Body>
    </Link>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
