import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchRejectedPodcasts = async (network) => {
  const firestore = getFirestore();

  const rejectedPodcastsRef = collection(firestore, Collections.PodcastUsers);

  const rejectedPodcastsQuery = query(
    rejectedPodcastsRef,
    where('waitingForConfirmation', '==', false),
    where('enabled', '==', false),
    where('networks', 'array-contains', network),
    orderBy('createdAt')
  );

  const rejectedPodcastsSapshot = await getDocs(rejectedPodcastsQuery);

  const rejectedPodcasts = rejectedPodcastsSapshot.docs.map((doc) => ({
    ...doc.data(),
    uid: doc.id,
  }));

  return rejectedPodcasts;
};

export default fetchRejectedPodcasts;
