import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const acceptBrand = async ({ brandId }) => {
  const firestore = getFirestore();

  const acceptedBrandRef = doc(firestore, Collections.Brands, brandId);

  await setDoc(
    acceptedBrandRef,
    {
      waitingForConfirmation: false,
      enabled: true,
      acceptedDate: Timestamp.now().toMillis(),
    },
    { merge: true }
  );
};

export default acceptBrand;
