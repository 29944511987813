import Path from 'enums/path.enum';
import SideBarItems from 'enums/sideBar/items.enum';

const sideBarRoutes = [
  {
    title: SideBarItems.PODCASTS_FOR_APPROVAL,
    to: Path.PodcastsForApproval,
    pathname: Path.PodcastsForApproval,
  },
  {
    title: SideBarItems.PODCASTS_APPROVED,
    to: Path.PodcastsApproved,
    pathname: Path.PodcastsApproved,
  },
  {
    title: SideBarItems.PODCASTS_REJECTED,
    to: Path.PodcastsRejected,
    pathname: Path.PodcastsRejected,
  },
  {
    title: SideBarItems.BRANDS_FOR_APPROVAL,
    to: Path.BrandsForApproval,
    pathname: Path.BrandsForApproval,
  },
  {
    title: SideBarItems.BRANDS_APPROVED,
    to: Path.BrandsApproved,
    pathname: Path.BrandsApproved,
  },
  {
    title: SideBarItems.BRANDS_REJECTED,
    to: Path.BrandsRejected,
    pathname: Path.BrandsRejected,
  },
  {
    title: SideBarItems.CAMPAIGNS,
    to: Path.Campaigns,
    pathname: Path.Campaigns,
  },
  {
    title: SideBarItems.PAYMENTS,
    to: Path.Payments,
    pathname: Path.Payments,
  },
  {
    title: SideBarItems.PENDING_DOCUMENTS,
    to: Path.PendingDocuments,
    pathname: Path.PendingDocuments,
  },
  {
    title: SideBarItems.SETTINGS,
    to: Path.Settings,
    pathname: Path.Settings,
  },
  {
    title: SideBarItems.LOGOUT,
    to: Path.Login,
    pathname: Path.Login,
  },
];

export default sideBarRoutes;
