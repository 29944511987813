import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import linkStartsWithProtocol from 'utils/functions/linkStartsWithProtocol';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Link from 'components/Typography/Link';
import WebIcon from 'assets/icons/website.png';
import LinkedinIcon from 'assets/icons/linkedin.png';

import classes from 'components/Pages/Brands/BrandSummary/BrandSummary.module.scss';

const getBrandData = (brand, isAccepted) => [
  {
    label: 'Contact Name',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {brand.name || ''}
      </Body>
    ),
  },
  {
    label: 'Phone Number',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {brand.phone || ''}
      </Body>
    ),
  },
  {
    label: `${isAccepted ? 'Approved' : 'Rejected'} Date`,
    condition: 'not-pending',
    component: (props) => {
      const date = isAccepted ? brand.acceptedDate : brand.rejectedDate;
      return (
        <Body {...props} size={BodySize.XS} className={classes.informationText}>
          {date ? dayjs(date).format('MM/DD/YYYY') : '-'}
        </Body>
      );
    },
  },
  {
    label: 'Email',
    component: (props) => (
      <Body
        {...props}
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.email)}
      >
        {brand.email || ''}
      </Body>
    ),
  },
  {
    label: 'Sign Up Date',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {brand.createdAt ? dayjs(brand.createdAt).format('MM/DD/YYYY') : '-'}
      </Body>
    ),
  },
  {
    label: 'Links',
    component: (props) => (
      <div className={classes.links}>
        <Link
          {...props}
          to={{
            pathname: linkStartsWithProtocol(brand.url)
              ? brand.url
              : `https://${brand.url}`,
          }}
          underline={false}
          target="_blank"
        >
          <img src={WebIcon} alt="web" className={classes.mediaIcon} />
        </Link>
        <Link
          {...props}
          to={{
            pathname: linkStartsWithProtocol(brand.linkedInUrl)
              ? brand.linkedInUrl
              : `https://${brand.linkedInUrl}`,
          }}
          underline={false}
          target="_blank"
        >
          <img
            src={LinkedinIcon}
            alt="linkedInUrl"
            className={classes.mediaIcon}
          />
        </Link>
      </div>
    ),
    props: {
      linkedInUrl: brand.linkedInUrl,
      url: brand.url,
    },
  },
];

export default getBrandData;
