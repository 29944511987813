import { doc, updateDoc, getFirestore } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const closePayment = async ({ paymentId }) => {
  const firestore = getFirestore();

  const paymentRef = doc(firestore, Collections.Payments, paymentId);
  await updateDoc(paymentRef, { closed: true });
};

export default closePayment;
