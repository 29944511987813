const fixDecimalPart = (val) => {
  const decimalPart = val?.toString()?.split('.')[1];

  if (decimalPart?.length > 4) {
    return val.toFixed(4);
  }

  return val;
};

export default fixDecimalPart;
