import { createAction } from '@reduxjs/toolkit';

export const loginInit = createAction('[Auth] Login Init');
export const loginSuccess = createAction('[Auth] Login Success');
export const loginFail = createAction('[Auth] Login Fail');

export const verifyAuthInit = createAction('[Auth] Verify Auth Init');
export const verifyAuthSuccess = createAction('[Auth] Verify Auth Success');
export const verifyAuthFail = createAction('[Auth] Verify Auth Fail');

export const logoutInit = createAction('[Auth] Logout Init');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFail = createAction('[Auth] Logout Fail');
