/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import Button from 'components/Common/Button';
import Input, { Type as InputType } from 'components/Common/Input';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Modal from 'components/Common/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import Confirmation from 'components/Common/Confirmation';
import Form from 'components/Common/Form';
import { createUser } from 'state/actions/users';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import { selectCreateUserState } from 'state/selectors/users';
import classes from './CreateUser.module.scss';

const CreateUser = ({ closeSelf }) => {
  const [email, setEmail] = useState('');
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const clearFormValues = () => {
    setEmail('');
  };

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const {
    loading: loadingCreateUser,
    success: successCreateUser,
    error: errorCreateUser,
    createUserResult,
  } = useSelector(selectCreateUserState, shallowEqual);

  const handleUser = () => {
    const userData = {
      email,
    };
    onOpenModalHandler(ModalType.CREATE_USER_CONFIRMATION, { userData });
  };

  const checkUserAvailable = () => {
    const values = [email];
    return values.every((value) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      if (value instanceof Date) {
        return true;
      }
      if (typeof value === 'object') {
        return Object.keys(value).length > 0;
      }
      return false;
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (successCreateUser) {
      onCloseModalHandler();
    }
  }, [successCreateUser]);

  useEffect(() => {
    if (successCreateUser) {
      clearFormValues();
      closeSelf();
    }
  }, [successCreateUser]);

  const onCreateUserHandler = useCallback(() => {
    const { userData } = modal;
    dispatch(
      createUser({
        email: userData.email,
        network: user.network,
      })
    );
  }, [modal]);

  useEffect(() => {
    if (errorCreateUser) {
      onCloseModalHandler();
    }
  }, [errorCreateUser]);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
  });

  return (
    <div className={classes.container}>
      <Form
        className={classes.form}
        validationSchema={validationSchema}
        onSubmit={onCreateUserHandler}
      >
        <Modal
          isOpen={modal.type === ModalType.CREATE_USER_CONFIRMATION}
          onClose={onCloseModalHandler}
        >
          <Confirmation
            title="Add user"
            description="Are you sure you want to add a new user?"
            onAccept={onCreateUserHandler}
            onCancel={onCloseModalHandler}
            loading={loadingCreateUser}
          />
        </Modal>
        <Body size={BodySize.XS} className={classes.title}>
          CREATE USER
        </Body>
        <div className={classes.row}>
          <Body size={BodySize.XS}>Email</Body>
          <Input
            type={InputType.Email}
            containerClassName={classes.inputContainer}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
          />
        </div>
        <Button
          loading={loadingCreateUser}
          className={classes.create}
          onClick={handleUser}
          disabled={!checkUserAvailable()}
        >
          Create
        </Button>
      </Form>
    </div>
  );
};

CreateUser.propTypes = {
  closeSelf: PropTypes.func.isRequired,
};

export default CreateUser;
