export const sortingOptions = [
  {
    label: 'A-Z',
    value: 'a-z',
    sortBy: 'podcastData.podcastTitle',
    sortOrder: 'asc',
  },
  {
    label: 'Z-A',
    value: 'z-a',
    sortBy: 'podcastData.podcastTitle',
    sortOrder: 'desc',
  },
  {
    label: 'Recently Created',
    value: 'recent',
    sortBy: 'createdAt',
    sortOrder: 'desc',
  },
  {
    label: 'Oldest Created',
    value: 'oldest',
    sortBy: 'createdAt',
    sortOrder: 'asc',
  },
  {
    label: 'More Audience',
    value: 'moreAudience',
    sortBy: 'podcastData.audienceEstimate',
    sortOrder: 'desc',
  },
  {
    label: 'Less Audience',
    value: 'lessAudience',
    sortBy: 'podcastData.audienceEstimate',
    sortOrder: 'asc',
  },
];

export const sortingOptionsApprovedPodcasts = [
  ...sortingOptions,
  {
    label: 'Recently Approved',
    value: 'recentApproved',
    sortBy: 'acceptedDate',
    sortOrder: 'desc',
  },
  {
    label: 'Oldest Approved',
    value: 'oldestApproved',
    sortBy: 'acceptedDate',
    sortOrder: 'asc',
  },
];
