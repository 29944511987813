import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Input, { Type } from 'components/Common/Input';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';

import classes from './PartialPayConfirmation.module.scss';

const PartialPayConfirmation = ({
  title,
  onAccept,
  loading,
  name,
  amount,
  paid,
  onChange,
  value,
}) => {
  const remainingAmount = useMemo(() => Number((amount - paid).toFixed(2)), [
    amount,
    paid,
  ]);

  return (
    <div className={classes.container}>
      <Body size={BodySize.S} className={classes.title}>
        {title}
      </Body>
      <Body size={BodySize.XS} className={classes.name}>
        {name}
      </Body>
      <div className={classes.toPay}>
        <div className={classes.valueTitles}>
          <Body size={BodySize.XS}>Total</Body>
          <Body size={BodySize.S}>${amount}</Body>
        </div>
        <div className={classes.inputContainer}>
          <Input
            className={classes.number}
            type={Type.Number}
            onChange={onChange}
            min={1}
            max={remainingAmount}
            value={value !== 0 ? value : ''}
            name="partialPayInput"
          />
        </div>
        <div className={classes.valueTitles}>
          <Body size={BodySize.XS}>Remaining</Body>
          <Body size={BodySize.S}>${remainingAmount}</Body>
        </div>
      </div>

      <div className={classes.actions}>
        <Body size={BodySize.XS}>
          Only ${Number((remainingAmount - value).toFixed(2))} will be left to
          pay.
        </Body>
        <Button
          className={classes.button}
          kind={ButtonKind.Primary}
          size={ButtonSize.L}
          onClick={onAccept}
          loading={loading}
        >
          Pay
        </Button>
      </div>
    </div>
  );
};

PartialPayConfirmation.propTypes = {
  title: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string,
  amount: PropTypes.number,
  paid: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

PartialPayConfirmation.defaultProps = {
  loading: false,
  title: '',
  name: '',
  amount: 0,
  paid: 0,
  onChange: () => {},
  value: 0,
};

export default PartialPayConfirmation;
