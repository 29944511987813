import {
    getFirestore,
    doc,
    getDoc,
} from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const getAdministrator = async ({ user }) => {
    try {
        const firestore = getFirestore();
        const userRef = doc(firestore, Collections.Administrators, user.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
            return {
                ...userSnapshot.data(),
                id: userSnapshot.id,
            };
        }

        return null;
    } catch (error) {
        throw new Error(error);
    }
};

export default getAdministrator;
