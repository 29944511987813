import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  Timestamp,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchPendingBrands = async (network) => {
  const firestore = getFirestore();

  const pendingBrandsRef = collection(firestore, Collections.Brands);

  const pendingBrandsQuery = query(
    pendingBrandsRef,
    where('waitingForConfirmation', '==', true),
    where('networks', 'array-contains', network),
    orderBy('createdAt')
  );

  const pendingBrandsSnapshot = await getDocs(pendingBrandsQuery);

  const pendingBrands = pendingBrandsSnapshot.docs.map((doc) => {
    const data = doc.data();
    const date = new Timestamp(
      data.createdAt.seconds,
      data.createdAt.nanoseconds
    ).toDate();

    return {
      ...data,
      uid: doc.id,
      createdAt: date || null,
    };
  });

  return pendingBrands;
};

export default fetchPendingBrands;
