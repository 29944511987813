import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const convertHtmlToDraft = (value) => {
  const { contentBlocks, entityMap } = htmlToDraft(value);

  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  const editorStateWithSelection = EditorState.moveFocusToEnd(editorState);

  return editorStateWithSelection;
};
export default convertHtmlToDraft;
