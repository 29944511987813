import { getName, overwrite } from 'country-list';

const countryOverwrites = [
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
];

overwrite(countryOverwrites);

const isoToName = (isoCode) => getName(isoCode);

export default isoToName;
