import { getFirestore, doc, updateDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const updateUser = async (value) => {
  try {
    const firestore = getFirestore();
    const userRef = doc(firestore, Collections.Administrators, value.id);
    await updateDoc(userRef, value);
    return value;
  } catch (error) {
    return error;
  }
};

export default updateUser;
