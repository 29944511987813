import { createSelector } from '@reduxjs/toolkit';

const selectCampaignState = (state) => state.campaigns;

export const selectFetchCurrentCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchCurrentCampaignsLoading,
    fetchCurrentCampaignsSuccess,
    fetchCurrentCampaignsError,
    currentCampaigns,
  }) => ({
    loading: fetchCurrentCampaignsLoading,
    success: fetchCurrentCampaignsSuccess,
    error: fetchCurrentCampaignsError,
    campaigns: currentCampaigns,
  })
);

export const selectFetchFinishedCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchFinishedCampaignsLoading,
    fetchFinishedCampaignsSuccess,
    fetchFinishedCampaignsError,
    finishedCampaigns,
  }) => ({
    loading: fetchFinishedCampaignsLoading,
    success: fetchFinishedCampaignsSuccess,
    error: fetchFinishedCampaignsError,
    campaigns: finishedCampaigns,
  })
);

export const selectSendAdCopyState = createSelector(
  selectCampaignState,
  ({ sendAdCopyLoading, sendAdCopySuccess, sendAdCopyError }) => ({
    loading: sendAdCopyLoading,
    success: sendAdCopySuccess,
    error: sendAdCopyError,
  })
);
