import { createAction } from '@reduxjs/toolkit';

export const fetchCurrentCampaignsInit = createAction(
  '[Campaigns] Fetch Current Campaigns Init'
);
export const fetchCurrentCampaignsSuccess = createAction(
  '[Campaigns] Fetch Current Campaigns Success'
);
export const fetchCurrentCampaignsFail = createAction(
  '[Campaigns] Fetch Current Campaigns Fail'
);

export const fetchFinishedCampaignsInit = createAction(
  '[Campaigns] Fetch Finished Campaigns Init'
);
export const fetchFinishedCampaignsSuccess = createAction(
  '[Campaigns] Fetch Finished Campaigns Success'
);
export const fetchFinishedCampaignsFail = createAction(
  '[Campaigns] Fetch Finished Campaigns Fail'
);

export const sendAdCopyInit = createAction('[Campaigns] Send Ad Copy Init');
export const sendAdCopySuccess = createAction(
  '[Campaigns] Send Ad Copy Success'
);
export const sendAdCopyFail = createAction('[Campaigns] Send Ad Copy Fail');

export const clearAdCopy = createAction('[Campaigns] Clear Ad Copy');

export const clearCampaigns = createAction('[Campaigns] Clear Campaigns');
