import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import AuthenticatedRoute from 'components/Navigation/AuthenticatedRoute';
import Loader from 'components/Common/Loader';
import {
  selectAuthenticatedUserState,
  selectVerifyAuthState,
} from 'state/selectors/auth';
import loginRoutes from 'utils/routes/loginRoutes';
import authenticatedRoutes from 'utils/routes/authenticatedRoutes';
import Path from 'enums/path.enum';
import RestrictedRoute from 'components/Navigation/RestrictedRoute';
import getPrimaryRoute from 'utils/users/getPrimaryRoute';

const Router = () => {
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const { loading: loadingVerifyAuth, error: verifyAuthError } = useSelector(
    selectVerifyAuthState,
    shallowEqual
  );

  const isAuthenticated = useMemo(() => !!user, [user]);

  const isLoading = useMemo(
    () => loadingVerifyAuth || (!user && !verifyAuthError),
    [loadingVerifyAuth, user, verifyAuthError]
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Switch>
      {authenticatedRoutes.map(({ component, path }) => (
        <AuthenticatedRoute
          key={path}
          component={component}
          path={path}
          exact
        />
      ))}
      {loginRoutes.map(({ component, path }) => (
        <RestrictedRoute
          key={path}
          component={component}
          condition={!isAuthenticated}
          redirectTo={Path.Home}
          path={path}
          exact
        />
      ))}
      <Route exact path={Path.Home}>
        <Redirect to={getPrimaryRoute(user)} />
      </Route>
      <Route path="*">
        <Redirect to={Path.Home} />
      </Route>
    </Switch>
  );
};

export default Router;
