import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';
import dayjs from 'dayjs';

const fetchPendingPayments = async ({ podcastId, network }) => {
  if (!network) return [];

  const firestore = getFirestore();

  const pendingPaymentsRef = collection(firestore, Collections.Payments);

  let pendingPaymentsQuery = query(
    pendingPaymentsRef,
    where('network', '==', network),
    orderBy('created', 'desc')
  );

  if (podcastId) {
    pendingPaymentsQuery = query(
      pendingPaymentsQuery,
      where('podcaster.podcast.id', '==', podcastId)
    );
  }

  const pendingPaymentsSnapshot = await getDocs(pendingPaymentsQuery);

  const pendingPayments = pendingPaymentsSnapshot.docs
    .map((doc) => {
      const payment = doc.data();

      return {
        ...payment,
        id: doc.id || null,
        created: payment.created?.toDate().toString() || null,
        campaign: {
          ...payment.campaign,
          startDate: dayjs(payment.campaign?.startDate?.toDate()).format(
            'MM/DD/YYYY'
          ),
          endDate: dayjs(payment.campaign?.endDate?.toDate()).format(
            'MM/DD/YYYY'
          ),
        },
        partialPayments: {
          ...payment.partialPayments.map((p) => ({
            ...p,
            date: dayjs(payment.partialPayments?.date?.toDate()).format(
              'MM/DD/YYYY'
            ),
          })),
        },
        processingDate: payment.processingDate
          ? dayjs(payment.processingDate?.toDate()).format('MM/DD/YYYY')
          : null,
      };
    })
    .filter((doc) => doc.podcaster.stripeId || doc.podcaster.stripeId === '');

  return pendingPayments;
};

export default fetchPendingPayments;
