import React from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from 'components/Common/IconButton';
import { brandLogo } from 'assets/white-label';
import Path from 'enums/path.enum';

import classes from './Header.module.scss';

const Header = () => {
  const history = useHistory();

  return (
    <IconButton
      onClick={() => history.push(Path.Home)}
      className={classes.header}
    >
      <img src={brandLogo} alt="Brand Logo" className={classes.logo} />
    </IconButton>
  );
};

export default Header;
