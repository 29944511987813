/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
} from 'components/Typography/Body';

import classes from './Badge.module.scss';

export const Size = Object.freeze({
  M: 'm',
  S: 's',
});

export const Kind = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
});

const Badge = ({
  text,
  endIcon,
  startIcon,
  altEndIcon,
  altStartIcon,
  kind,
  onClick,
  className,
}) => (
  <div className={classNames(classes.badge, classes[kind], className)}>
    <button type="button" onClick={onClick} className={classes.icon}>
      {startIcon && <img src={startIcon} alt={altStartIcon} />}
      <Body
        size={BodySize.XXS}
        color={kind === Kind.Primary ? BodyColor.White : BodyColor.Violet}
        letterCase={BodyLetterCase.Uppercase}
      >
        {text}
      </Body>
      {endIcon && <img src={endIcon} alt={altEndIcon} />}
    </button>
  </div>
);

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(Kind)),
  onClick: PropTypes.func,
  altEndIcon: PropTypes.string,
  endIcon: PropTypes.string,
  altStartIcon: PropTypes.string,
  startIcon: PropTypes.string,
  className: PropTypes.string,
};

Badge.defaultProps = {
  kind: Kind.Primary,
  onClick: null,
  className: '',
  altEndIcon: '',
  endIcon: null,
  altStartIcon: '',
  startIcon: null,
};

export default Badge;
