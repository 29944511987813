import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { fetchPodcastOffers } from 'state/actions/podcasts';
import { selectFetchPodcastOffersState } from 'state/selectors/podcasts';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Modal from 'components/Common/Modal';
import Spinner from 'components/Common/Spinner';
import PodcastCampaignSummary from 'components/Pages/Podcasts/PodcastCampaignSummary';
import CampaignDetails from 'components/Pages/Campaigns/CampaignDetails';
import fetchCampaignDataService from 'services/campaigns/fetchCampaignData';

import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import classes from './PodcastsCampaigns.module.scss';

const PodcastsCampaigns = ({ podcastId, current }) => {
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(fetchPodcastOffers({ podcastId }));
  }, [podcastId]);

  const { podcastOffers, loading: fetchPodcastOffersLoading } = useSelector(
    selectFetchPodcastOffersState,
    shallowEqual
  );
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onClickMoreDetailsHandler = useCallback(async (campaign) => {
    const { campaignId, brandId } = campaign;
    const campaignData = await fetchCampaignDataService({
      brandId,
      campaignId,
    });
    onOpenModalHandler(ModalType.CAMPAIGN_SUMMARY, {
      campaignData,
    });
  }, []);

  const today = new Date();
  const campaignsRelated = current
    ? podcastOffers
        .map((i) => JSON.parse(i))
        .filter((c) => new Date(c.periodTo) >= today)
    : podcastOffers
        .map((i) => JSON.parse(i))
        .filter((c) => new Date(c.periodTo) < today);
  return fetchPodcastOffersLoading ? (
    <Spinner className={classes.spinner} />
  ) : (
    <div className={classes.campaigns}>
      {campaignsRelated.map((campaign) => (
        <>
          <Modal
            isOpen={modal.type === ModalType.CAMPAIGN_SUMMARY}
            onClose={onCloseModalHandler}
            className={classes.campaignSummaryModal}
          >
            <CampaignDetails
              campaign={modal.campaignData}
              title="Campaign details"
            />
          </Modal>
          <PodcastCampaignSummary
            key={campaign.uid}
            campaign={campaign}
            onClickMoreDetails={() => onClickMoreDetailsHandler(campaign)}
          />
        </>
      ))}
      {campaignsRelated.length === 0 && fetchPodcastOffersLoading !== true && (
        <Body size={BodySize.XS} className={classes.noCampaigns}>
          There are no current campaigns
        </Body>
      )}
    </div>
  );
};

PodcastsCampaigns.propTypes = {
  podcastId: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
};

export default PodcastsCampaigns;
