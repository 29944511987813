import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  selectEditPendingDocumentsPodcastsState,
  selectEditPodcastDataState,
} from 'state/selectors/podcasts';
import {
  clearEditPendingDocumentState,
  fetchPendingDocumentsPodcasts,
} from 'state/actions/podcasts';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Spinner, { Color as SpinnerColor } from 'components/Common/Spinner';
import Button, {
  Size as ButtonSize,
  Kind as ButtonKind,
  Color as ButtonColor,
} from 'components/Common/Button';
import Input, { Type as InputType } from 'components/Common/Input';

import classes from './EditPodcastAudienceEstimate.module.scss';

const EditPodcastAudienceEstimate = ({
  podcast,
  onUpdate,
  isApproved,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const screenshotAudienceEstimate =
    podcast?.screenshots?.monthlyListeners ||
    podcast?.screenshots?.audienceEstimate;

  const oldAudienceEstimate = useMemo(() => {
    if (isApproved || !screenshotAudienceEstimate) {
      return Number(podcast?.podcastData?.audienceEstimate) ?? '';
    }

    if (screenshotAudienceEstimate) {
      return screenshotAudienceEstimate === ''
        ? ''
        : Number(screenshotAudienceEstimate);
    }

    return Number(podcast?.screenshots?.audienceEstimate) ?? '';
  }, [
    screenshotAudienceEstimate,
    podcast?.screenshots?.audienceEstimate,
    podcast?.podcastData?.audienceEstimate,
    isApproved,
  ]);

  const [newAudienceEstimate, setAudienceEstimate] = useState(
    oldAudienceEstimate
  );

  const { loading, success } = useSelector(
    selectEditPendingDocumentsPodcastsState,
    shallowEqual
  );

  const { loading: loadingEditPodcastData } = useSelector(
    selectEditPodcastDataState,
    shallowEqual
  );

  useEffect(() => {
    setAudienceEstimate(oldAudienceEstimate);
  }, [oldAudienceEstimate]);

  useEffect(() => {
    if (success) {
      dispatch(fetchPendingDocumentsPodcasts());
    }

    return () => {
      dispatch(clearEditPendingDocumentState());
    };
  }, [success]);

  const onChangeAudienceHandler = ({ target: { value } }) =>
    setAudienceEstimate(value);

  const onClickCancelHandler = () => onCancel({ podcast });
  const onClickUpdateHandler = () =>
    onUpdate({ podcast, audienceEstimate: newAudienceEstimate });

  const isUpdating = loading || loadingEditPodcastData;

  return (
    <div className={classes.container}>
      <Body className={classes.title}>{podcast?.podcastName}</Body>
      <img
        src={podcast?.podcastData?.imageUrl}
        alt="podcast"
        className={classes.image}
      />
      <Body size={BodySize.XS}>EDIT AUDIENCE</Body>
      <Input
        type={InputType.Number}
        containerClassName={classes.inputContainer}
        value={newAudienceEstimate}
        onChange={onChangeAudienceHandler}
      />
      <div className={classes.buttonsContainer}>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          color={ButtonColor.Pink}
          className={classes.button}
          onClick={onClickCancelHandler}
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          className={classes.button}
          disabled={newAudienceEstimate === oldAudienceEstimate || isUpdating}
          onClick={onClickUpdateHandler}
        >
          {isUpdating ? <Spinner color={SpinnerColor.White} /> : 'Update'}
        </Button>
      </div>
    </div>
  );
};

EditPodcastAudienceEstimate.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isApproved: PropTypes.bool,
  podcast: PropTypes.shape({
    uid: PropTypes.string,
    id: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      audienceEstimate: PropTypes.string,
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
      ),
      webUrl: PropTypes.string,
      applePodcastUrl: PropTypes.string,
      ratingAverage: PropTypes.number,
    }),
    screenshots: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      audienceEstimate: PropTypes.string,
      monthlyListeners: PropTypes.string,
    }),
  }),
};

EditPodcastAudienceEstimate.defaultProps = {
  podcast: null,
  isApproved: false,
};

export default EditPodcastAudienceEstimate;
