import { collection, addDoc, getFirestore } from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const createPayment = async ({ paymentData }) => {
  try {
    const firestore = getFirestore();
    const collRef = collection(firestore, Collections.Payments);
    const result = await addDoc(collRef, paymentData);
    return result.id;
  } catch (error) {
    throw new Error(error);
  }
};

export default createPayment;
