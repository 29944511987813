import { createAction } from '@reduxjs/toolkit';

export const fetchPendingBrandsInit = createAction(
  '[Brands] Fetch Pending Brands Init'
);
export const fetchPendingBrandsSuccess = createAction(
  '[Brands] Fetch Pending Brands Success'
);
export const fetchPendingBrandsFail = createAction(
  '[Brands] Fetch Pending Brands Fail'
);

export const fetchApprovedBrandsInit = createAction(
  '[Brands] Fetch Approved Brands Init'
);
export const fetchApprovedBrandsSuccess = createAction(
  '[Brands] Fetch Approved Brands Success'
);
export const fetchApprovedBrandsFail = createAction(
  '[Brands] Fetch Approved Brands Fail'
);

export const fetchRejectedBrandsInit = createAction(
  '[Brands] Fetch Rejected Brands Init'
);
export const fetchRejectedBrandsSuccess = createAction(
  '[Brands] Fetch Rejected Brands Success'
);
export const fetchRejectedBrandsFail = createAction(
  '[Brands] Fetch Rejected Brands Fail'
);

export const acceptBrandInit = createAction('[Brands] Accept Brand Init');
export const acceptBrandSuccess = createAction('[Brands] Accept Brand Success');
export const acceptBrandFail = createAction('[Brands] Accept Brand Fail');

export const rejectBrandInit = createAction('[Brands] Reject Brand Init');
export const rejectBrandSuccess = createAction('[Brands] Reject Brand Success');
export const rejectBrandFail = createAction('[Brands] Reject Brand Fail');

export const clearBrands = createAction('[Brands] Clear Brands');
