import {
  clearUsers,
  createUserInit,
  createUserLoading,
  createUserSuccess,
  createUserFail,
  fetchUsersInit,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchUsersLoading,
  updateUserInit,
  updateUserSuccess,
  updateUserFail,
  updateUserLoading,
  deleteUserInit,
  deleteUserSuccess,
  deleteUserFail,
  deleteUserLoading,
  resetUser,
} from 'state/actionCreators/users';
import fetchUsersService from 'services/users/fetchUsers';
import updateUserService from 'services/users/updateUser';
import deleteUserService from 'services/users/deleteUser';
import addAdministratorService from 'services/users/addAdministrator';

export const createUser = ({ email, network }) => async (dispatch) => {
  dispatch(createUserInit());

  try {
    dispatch(createUserLoading());

    await addAdministratorService({
      email,
      network,
    });

    return dispatch(createUserSuccess());
  } catch (error) {
    return dispatch(createUserFail({ error: error.message }));
  }
};

export const fetchUsers = ({ network }) => async (dispatch) => {
  dispatch(fetchUsersInit());

  try {
    dispatch(fetchUsersLoading());
    const users = await fetchUsersService({ network });
    return dispatch(fetchUsersSuccess({ users }));
  } catch (error) {
    return dispatch(fetchUsersFail({ error: error.message }));
  }
};

export const updateUser = (value) => async (dispatch) => {
  dispatch(updateUserInit());

  try {
    dispatch(updateUserLoading());
    const user = await updateUserService(value);
    return dispatch(updateUserSuccess({ user }));
  } catch (error) {
    return dispatch(updateUserFail({ error: error.message }));
  }
};

export const deleteUser = ({ user }) => async (dispatch) => {
  dispatch(deleteUserInit());

  try {
    dispatch(deleteUserLoading());
    const result = await deleteUserService({ user });
    return dispatch(deleteUserSuccess({ result }));
  } catch (error) {
    return dispatch(deleteUserFail({ error: error.message }));
  }
};

export const resetUserState = () => async (dispatch) => {
  dispatch(resetUser());
};

export const clearUsersState = () => clearUsers();
