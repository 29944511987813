const getSelectStyles = ({
  borderColor,
  readOnly,
  backgroundColor,
  disabled,
}) => ({
  control: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    border: state.isFocused ? '1px solid #645aff' : borderColor,
    minHeight: '48px',
    boxShadow: '0px 12px 23px rgba(55, 125, 255, 0.06)',
    backgroundColor: state.isDisabled && readOnly ? 'white' : backgroundColor,
    fontSize: '0.875rem',
    '&:hover': {
      border: state.isFocused ? '1px solid #645aff' : borderColor,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginLeft: '0.5rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: disabled ? '#7b88a8' : 'black',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#b9b9b9',
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '15rem',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.875rem',
    backgroundColor: state.isSelected ? '#645aff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#e2e0ff',
    },
  }),
});

export default getSelectStyles;
