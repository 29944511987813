import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';
import CampaignOffer from 'components/Pages/Campaigns/CampaignOffer';
import ViewIcon from 'assets/icons/view.png';
import ArrowDownIcon from 'assets/icons/arrow-down.png';
import offerStatus from 'enums/campaigns/offerStatus.enum';
import podcastPaymentStatus from 'enums/campaigns/podcastPaymentStatus';
import getCampaignOffersStatus from 'utils/campaigns/getCampaignOffersStatus';
import getCampaignPodcastsPaymentsStatus from 'utils/campaigns/getCampaignPodcastsPaymentsStatus';

import classes from './CampaignSummary.module.scss';

const CampaignSummary = ({
  campaign,
  brandName,
  onClickMoreDetails,
  hasFinished,
  onClickPayment,
  onClickAdCopy,
}) => {
  const [expanded, setExpanded] = useState(false);

  const onClickExpandHandle = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  const offersStatus = getCampaignOffersStatus(campaign);
  const paymentStatus = getCampaignPodcastsPaymentsStatus(campaign);

  const paidOffers = campaign.offers?.filter((offer) => offer.isPaid) || [];

  return (
    <>
      <div
        className={classNames(classes.container, {
          [classes.expandedContainer]: expanded,
        })}
      >
        <div className={classes.content}>
          <div>
            <Body size={BodySize.S} className={classes.title}>
              {campaign.name || 'Campaign title'}
            </Body>
            <div className={classes.campaignDetails}>
              <div className={classes.details}>
                <Body size={BodySize.XS} className={classes.text}>
                  Brand
                </Body>
                <Body
                  size={BodySize.XS}
                  className={classNames(classes.budgetAmount, classes.text)}
                >
                  {`${brandName}` || '-'}
                </Body>
              </div>
              <div className={classes.details}>
                <Body size={BodySize.XS} className={classes.text}>
                  Creation Date
                </Body>
                <Body
                  size={BodySize.XS}
                  className={classNames(classes.budgetAmount, classes.text)}
                >
                  {campaign.createdAt
                    ? `${dayjs(campaign.createdAt).format('MMMM DD, YYYY')}`
                    : '-'}
                </Body>
              </div>
            </div>
            <div className={classes.campaignDetails}>
              <div className={classes.details}>
                <Body size={BodySize.XS} className={classes.text}>
                  Budget
                </Body>
                <Body
                  size={BodySize.XS}
                  className={classNames(classes.budgetAmount, classes.text)}
                >
                  {`$${campaign.budget}` || 'Campaign budget'}
                </Body>
              </div>
              <div className={classes.details}>
                <Body size={BodySize.XS} className={classes.text}>
                  Airing between
                </Body>
                <Body
                  size={BodySize.XS}
                  className={classNames(classes.budgetAmount, classes.text)}
                >
                  {dayjs(campaign.airingFrom).format('MMMM DD, YYYY')} to{' '}
                  {dayjs(campaign.airingTo).format('MMMM DD, YYYY')}
                </Body>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.offerStatus}>
              <div
                className={classNames(classes.status, {
                  [classes.green]: hasFinished
                    ? paymentStatus === podcastPaymentStatus.accepted
                    : offersStatus === offerStatus.accepted,
                  [classes.yellow]:
                    !hasFinished && offersStatus === offerStatus.pending,
                  [classes.orange]:
                    hasFinished &&
                    paymentStatus === podcastPaymentStatus.pending,
                  [classes.red]: hasFinished
                    ? paymentStatus === podcastPaymentStatus.rejected
                    : offersStatus === offerStatus.rejected,
                })}
              />
              <Body
                size={BodySize.XS}
                className={classNames(classes.bold, classes.noMargin)}
              >
                {hasFinished ? `${paymentStatus}` : `Offers ${offersStatus}`}
              </Body>
            </div>
            <IconButton
              className={classes.seeMore}
              onClick={onClickMoreDetails}
            >
              <img src={ViewIcon} alt="view" className={classes.icon} />
              <Body size={BodySize.XXS} className={classes.bold}>
                More details
              </Body>
            </IconButton>
          </div>
        </div>
        <div className={classes.paidOffers}>
          {paidOffers.length > 0 && (
            <>
              <div className={classes.expand}>
                <IconButton
                  className={classes.seeMore}
                  onClick={onClickExpandHandle}
                >
                  <img
                    src={ArrowDownIcon}
                    alt="arrow"
                    className={classNames(classes.icon, {
                      [classes.closeIcon]: expanded,
                    })}
                  />
                  <Body size={BodySize.XS} className={classes.expandText}>
                    {expanded ? 'Close' : 'Expand'}
                  </Body>
                </IconButton>
              </div>
              <div
                className={classNames(classes.expanded, {
                  [classes.visible]: expanded,
                })}
              >
                {paidOffers.map((offer, index) => (
                  <CampaignOffer
                    offer={offer}
                    index={index}
                    key={offer.title + offer.podcastName}
                    hasFinished={hasFinished}
                    onClickPayment={onClickPayment}
                    onClickAdCopy={() => onClickAdCopy({ campaign, offer })}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

CampaignSummary.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string.isRequired,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }).isRequired,
  brandName: PropTypes.string.isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
  hasFinished: PropTypes.bool,
  onClickPayment: PropTypes.func,
  onClickAdCopy: PropTypes.func,
};

CampaignSummary.defaultProps = {
  hasFinished: false,
  onClickPayment: null,
  onClickAdCopy: null,
};

export default CampaignSummary;
