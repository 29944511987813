const SideBarItems = {
  PAYMENTS: 'PAYMENTS',
  PODCASTS_FOR_APPROVAL: 'PODCASTS FOR APPROVAL',
  PODCASTS_APPROVED: 'PODCASTS APPROVED',
  PODCASTS_REJECTED: 'PODCASTS REJECTED',
  BRANDS_FOR_APPROVAL: 'BRANDS FOR APPROVAL',
  BRANDS_APPROVED: 'BRANDS APPROVED',
  BRANDS_REJECTED: 'BRANDS REJECTED',
  CAMPAIGNS: 'CAMPAIGNS',
  LOGOUT: 'LOGOUT',
  PENDING_DOCUMENTS: 'PENDING DOCUMENTS',
  SETTINGS: 'SETTINGS',
};

export default SideBarItems;
