import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  Timestamp,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchRejectedBrands = async (network) => {
  const firestore = getFirestore();

  const rejectedBrandsRef = collection(firestore, Collections.Brands);

  const rejectedBrandsQuery = query(
    rejectedBrandsRef,
    where('waitingForConfirmation', '==', false),
    where('enabled', '==', false),
    where('networks', 'array-contains', network),
    orderBy('createdAt')
  );

  const rejectedBrandsSapshot = await getDocs(rejectedBrandsQuery);

  const rejectedBrands = rejectedBrandsSapshot.docs.map((doc) => {
    const data = doc.data();
    const date = new Timestamp(
      data.createdAt.seconds,
      data.createdAt.nanoseconds
    ).toDate();

    return {
      ...data,
      uid: doc.id,
      createdAt: date || null,
    };
  });

  return rejectedBrands;
};

export default fetchRejectedBrands;
