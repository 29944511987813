/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';

import classes from './Input.module.scss';

export const Type = Object.freeze({
  Text: 'text',
  Number: 'number',
  Password: 'password',
  Submit: 'submit',
  Reset: 'reset',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Button: 'button',
  File: 'file',
  Image: 'image',
  Email: 'email',
  Tel: 'tel',
  Date: 'date',
});

export const Size = Object.freeze({
  M: 'M',
  S: 'S',
  XS: 'XS',
});

const Input = ({
  label,
  name,
  disabled,
  readOnly,
  error,
  register,
  size,
  className,
  containerClassName,
  labelClassName,
  onKeyPress,
  ...rest
}) => (
  <div className={classNames(containerClassName, classes.container)}>
    {label && (
      <Body
        size={BodySize[size]}
        className={classNames(labelClassName, classes.labelText)}
        color={BodyColor.Black}
      >
        {label}
      </Body>
    )}
    <input
      className={classNames(className, classes.input, {
        [classes.hasError]: error,
        [classes.isDisabled]: disabled,
        [classes.readOnly]: readOnly,
      })}
      id={name}
      name={name}
      alt={name}
      ref={register}
      aria-label={name}
      disabled={disabled}
      readOnly={readOnly}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onKeyPress();
        }
      }}
      {...rest}
    />
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  onKeyPress: PropTypes.func,
};

Input.defaultProps = {
  register: null,
  onKeyPress: () => {},
  error: false,
  disabled: false,
  size: Size.XS,
  className: '',
  containerClassName: '',
  labelClassName: '',
  readOnly: false,
  label: null,
};

export default Input;
