import React from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import PodcastFieldInformation from 'components/Pages/Podcasts/PodcastFieldInformation';

import classes from './PodcastDetails.module.scss';

const PodcastDetails = ({
  podcastData,
  oldUrls,
  images,
  openImageViewer,
  setCurrentImage,
  email,
  name,
  lastName,
  createdAt,
}) => (
  <div className={classes.details}>
    <div className={classes.information}>
      {podcastData.map(({ icon, title, information, editable, ...props }) => (
        <PodcastFieldInformation
          icon={icon}
          title={title}
          information={information}
          editable={editable}
          key={information}
          {...props}
        />
      ))}
    </div>
    {oldUrls ? (
      <>
        {images.length > 0 && (
          <div className={classes.oldScreenshots}>
            <Body size={BodySize.XS}>Screenshot</Body>
            <div className={classes.oldScreenshotsContainer}>
              {images.map((image, index) => (
                <div
                  onClick={() => {
                    setCurrentImage(index);
                    openImageViewer();
                  }}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    className={classes.screenshot}
                    alt={image.alt}
                    key={image.url + image.alt}
                    src={image.src}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    ) : (
      <>
        {images.length > 0 && (
          <div className={classes.screenshots}>
            {images.map(({ title, src, alt }, index) => (
              <div className={classes.screenshotAndTitleContainer}>
                <Body size={BodySize.XS}>{title}</Body>
                <div className={classes.screenshotsContainer}>
                  <div
                    onClick={() => {
                      setCurrentImage(index);
                      openImageViewer();
                    }}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <img
                      className={classes.screenshot}
                      alt={alt}
                      key={src + alt}
                      src={src}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )}
    <div className={classes.contactBox}>
      <div className={classes.contactData}>
        <div className={classes.contactItem}>
          <div className={classes.contactItemTitle}>
            <Body size={BodySize.XS} className={classes.valueName}>
              Contact Name
            </Body>
          </div>
          <div className={classes.contactItemText}>
            <Body size={BodySize.S} className={classes.value}>
              {name} {lastName}
            </Body>
          </div>
        </div>
        <div className={classes.contactItem}>
          <div className={classes.contactItemTitle}>
            <Body size={BodySize.XS} className={classes.valueName}>
              Email
            </Body>
          </div>
          <div className={classes.contactItemText}>
            <Body size={BodySize.S} className={classes.value}>
              {email}
            </Body>
          </div>
        </div>
        <div className={classes.contactItem}>
          <div className={classes.contactItemTitle}>
            <Body size={BodySize.XS} className={classes.valueName}>
              Sign Up Date
            </Body>
          </div>
          <div className={classes.contactItemText}>
            <Body size={BodySize.S} className={classes.value}>
              {new Date(createdAt).toLocaleDateString()}
            </Body>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PodcastDetails.propTypes = {
  podcastData: PropTypes.arrayOf({}).isRequired,
  oldUrls: PropTypes.arrayOf({}).isRequired,
  images: PropTypes.arrayOf({}).isRequired,
  openImageViewer: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default PodcastDetails;
