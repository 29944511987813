/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  loginInit,
  loginSuccess,
  loginFail,
  verifyAuthInit,
  verifyAuthSuccess,
  verifyAuthFail,
  logoutInit,
  logoutSuccess,
  logoutFail,
} from 'state/actionCreators/auth';

export const initialState = {
  loginLoading: false,
  loginSuccess: false,
  loginError: null,
  verifyAuthLoading: false,
  verifyAuthSuccess: false,
  verifyAuthError: null,
  logoutLoading: false,
  logoutSuccess: false,
  logoutError: null,
  user: null,
};

const authReducer = createReducer(initialState, {
  [loginInit]: (state) => {
    state.loginLoading = true;
    state.loginSuccess = false;
    state.loginError = null;
  },
  [loginSuccess]: (state, { payload }) => {
    state.loginLoading = false;
    state.loginSuccess = true;
    state.loginError = null;
    state.user = payload;
  },
  [loginFail]: (state, { payload }) => {
    const { error } = payload;
    state.loginLoading = false;
    state.loginSuccess = false;
    state.loginError = error;
  },
  [verifyAuthInit]: (state) => {
    state.verifyAuthLoading = true;
    state.verifyAuthSuccess = false;
    state.verifyAuthError = null;
  },
  [verifyAuthSuccess]: (state, { payload }) => {
    state.verifyAuthLoading = false;
    state.verifyAuthSuccess = true;
    state.verifyAuthError = null;
    state.user = payload;
  },
  [verifyAuthFail]: (state, { payload }) => {
    const { error } = payload;
    state.verifyAuthLoading = false;
    state.verifyAuthSuccess = false;
    state.verifyAuthError = error;
  },
  [logoutInit]: (state) => {
    state.logoutLoading = true;
    state.logoutSuccess = false;
    state.logoutError = null;
  },
  [logoutSuccess]: (state) => {
    state.logoutLoading = false;
    state.logoutSuccess = true;
    state.logoutError = null;
    state.user = null;
  },
  [logoutFail]: (state, { payload }) => {
    const { error } = payload;
    state.logoutLoading = false;
    state.logoutSuccess = false;
    state.logoutError = error;
  },
});

export default authReducer;
