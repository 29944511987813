import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = ({ auth }) => auth;

export const selectLoginState = createSelector(
  selectAuthState,
  ({ loginLoading, loginSuccess, loginError }) => ({
    loading: loginLoading,
    success: loginSuccess,
    error: loginError,
  })
);

export const selectVerifyAuthState = createSelector(
  selectAuthState,
  ({ verifyAuthLoading, verifyAuthSuccess, verifyAuthError }) => ({
    loading: verifyAuthLoading,
    success: verifyAuthSuccess,
    error: verifyAuthError,
  })
);

export const selectAuthenticatedUserState = createSelector(
  selectAuthState,
  ({ user }) => ({ user })
);

export const selectLogoutState = createSelector(
  selectAuthState,
  ({ logoutLoading, logoutSuccess, logoutError }) => ({
    loading: logoutLoading,
    success: logoutSuccess,
    error: logoutError,
  })
);
