/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchGeneralValuesInit,
  fetchGeneralValuesSuccess,
  fetchGeneralValuesFail,
  updateGeneralValuesInit,
  updateGeneralValuesSuccess,
  updateGeneralValuesFail,
  resetGeneralValues,
  fetchHostingProvidersInit,
  fetchHostingProvidersSuccess,
  fetchHostingProvidersFail
} from 'state/actionCreators/generals';

export const initialState = {
  fetchGeneralValuesLoading: false,
  fetchGeneralValuesSuccess: false,
  fetchGeneralValuesError: null,
  updateGeneralValuesLoading: false,
  updateGeneralValuesSuccess: false,
  updateGeneralValuesError: null,
  generalValues: '',
  fetchHostingProvidersSuccess: false,
  fetchHostingProvidersError: null,
  hostingProviders: [],
};

const generalsReducer = createReducer(initialState, {
  [fetchGeneralValuesInit]: (state) => {
    state.fetchGeneralValuesLoading = true;
    state.fetchGeneralValuesSuccess = false;
    state.fetchGeneralValuesError = null;
    state.generalValues = '';
  },
  [fetchGeneralValuesSuccess]: (state, { payload }) => {
    state.fetchGeneralValuesLoading = false;
    state.fetchGeneralValuesSuccess = true;
    state.fetchGeneralValuesError = null;
    state.generalValues = payload;
  },
  [fetchGeneralValuesFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchGeneralValuesLoading = false;
    state.fetchGeneralValuesSuccess = false;
    state.fetchGeneralValuesError = error;
  },
  [updateGeneralValuesInit]: (state) => {
    state.updateGeneralValuesLoading = true;
    state.updateGeneralValuesSuccess = false;
    state.updateGeneralValuesError = null;
    state.generalValues = '';
  },
  [updateGeneralValuesSuccess]: (state, { payload }) => {
    state.updateGeneralValuesLoading = false;
    state.updateGeneralValuesSuccess = true;
    state.updateGeneralValuesError = null;
    state.generalValues = payload;
  },
  [updateGeneralValuesFail]: (state, { payload }) => {
    const { error } = payload;
    state.updateGeneralValuesLoading = false;
    state.updateGeneralValuesSuccess = false;
    state.updateGeneralValuesError = error;
  },
  [resetGeneralValues]: (state) => {
    state.fetchGeneralValuesLoading = false;
    state.fetchGeneralValuesSuccess = false;
    state.fetchGeneralValuesError = null;

    state.updateGeneralValuesLoading = false;
    state.updateGeneralValuesSuccess = false;
    state.updateGeneralValuesError = null;
  },
  [fetchHostingProvidersInit]: (state) => {
    state.fetchHostingProvidersSuccess = false;
    state.fetchHostingProvidersError = null;
  },
  [fetchHostingProvidersSuccess]: (state, { payload }) => {
    const { hostingProviders } = payload;
    state.fetchHostingProvidersSuccess = true;
    state.fetchHostingProvidersError = null;
    state.hostingProviders = hostingProviders;
  },
  [fetchHostingProvidersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchHostingProvidersSuccess = false;
    state.fetchHostingProvidersError = error;
  },
});

export default generalsReducer;
