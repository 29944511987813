import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from 'components/Common/IconButton';
import closeIcon from 'assets/icons/close.svg';

import classes from './Modal.module.scss';

const Modal = ({ isOpen, onClose, children, className, showCloseIcon }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={classNames(classes.content, className, {
          [classes.open]: isOpen,
          [classes.close]: !isOpen,
        })}
      >
        {showCloseIcon && (
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <img src={closeIcon} alt="close" className={classes.closeImg} />
          </IconButton>
        )}
        {children}
      </div>
      {isOpen && (
        <div
          name="background"
          role="button"
          className={classes.background}
          aria-label="background"
          onClick={onClose}
          onKeyDown={onClose}
          tabIndex={0}
        />
      )}
    </>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  showCloseIcon: true,
};

export default Modal;
