import React from 'react';
import PropTypes, { string } from 'prop-types';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import rejectionReasons from 'enums/podcasts/rejectionReason.enum';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Color as ButtonColor,
  Size as ButtonSize,
  Kind as ButtonKind,
} from 'components/Common/Button';
import getPodcastData from 'utils/podcasts/getPodcastDates';

import networks from 'enums/podcasts/networks.enum';

import Switch from 'components/Common/Switch';
import classes from './PodcastDates.module.scss';

const PodcastDates = ({
  podcast,
  isAccepted,
  onAction,
  learnMoreHandler,
  hiatusHandler,
  onSendToNetwork,
}) => {
  const brandData = getPodcastData(podcast, isAccepted);
  const mismatchReason = 'Screenshot doesn’t match stats.';

  const actualNetwork = podcast.networks[0];
  const isVisible = actualNetwork !== networks.Colture;
  const newNetwork =
    actualNetwork === networks.Ossa ? networks.Spotify : networks.Ossa;

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.content}>
          <div className={classes.image}>
            <img
              src={podcast.podcastData?.imageUrl}
              alt="logo"
              className={classes.logo}
            />
          </div>
          <div className={classes.brand}>
            <Body size={BodySize.XS} className={classes.title}>
              {podcast?.podcastData?.podcastTitle || 'Podcast'}
            </Body>
            <div className={classes.information}>
              {brandData.map(({ label, component: Component }) => (
                <div className={classes.item} key={label}>
                  <Body size={BodySize.XS} className={classes.itemTitle}>
                    {label}
                  </Body>
                  <Component />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={onAction}
            className={classes.button}
            color={isAccepted ? ButtonColor.Pink : ButtonColor.Default}
          >
            {isAccepted ? 'Reject' : 'Accept'}
          </Button>
          <Switch
            label="Hiatus"
            onClickSwitch={hiatusHandler}
            checked={podcast.isOnHiatus}
          />
          {isVisible && (
            <Button
              onClick={onSendToNetwork}
              className={classes.button}
              color={
                actualNetwork === networks.Ossa
                  ? ButtonColor.Green
                  : ButtonColor.Black
              }
            >
              Send To {newNetwork}
            </Button>
          )}
        </div>
      </div>
      {learnMoreHandler && (
        <Button
          className={classes.learnMore}
          kind={ButtonKind.Secondary}
          size={ButtonSize.S}
          onClick={learnMoreHandler}
        >
          Learn more
        </Button>
      )}
      {!isAccepted && podcast.rejectionType && (
        <div className={classes.bottom}>
          <Body size={BodySize.XS} className={classes.rejectionDate}>
            Rejected On{' '}
            {dayjs(podcast.rejectionDate).format('MMM D YYYY') || ''}
          </Body>
          <div className={classes.rejectionReasonContainer}>
            <Body size={BodySize.XS} className={classes.rejectionReasonTitle}>
              Reason:&nbsp;
            </Body>
            <span className={classes.rejectionReason}>
              {podcast.rejectionType === rejectionReasons.other
                ? parse(podcast.rejectionReason || '')
                : mismatchReason}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

PodcastDates.propTypes = {
  podcast: PropTypes.shape({
    podcastId: PropTypes.string,
    podcastData: PropTypes.shape({
      podcastTitle: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
    rejectionReason: PropTypes.string,
    rejectionType: PropTypes.string,
    rejectionDate: PropTypes.number,
    isOnHiatus: PropTypes.bool,
    networks: PropTypes.arrayOf(string),
  }).isRequired,
  isAccepted: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  learnMoreHandler: PropTypes.func,
  hiatusHandler: PropTypes.func.isRequired,
  onSendToNetwork: PropTypes.func.isRequired,
};

PodcastDates.defaultProps = {
  learnMoreHandler: null,
};

export default PodcastDates;
