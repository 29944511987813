import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';
import rejectionReasons from 'enums/podcasts/rejectionReason.enum';

const rejectPodcast = async ({ podcastId, rejectionReason }) => {
  const firestore = getFirestore();

  const rejectedPodcastRef = doc(
    firestore,
    Collections.PodcastUsers,
    podcastId
  );

  const rejectionType = rejectionReason
    ? rejectionReasons.other
    : rejectionReasons.informationMismatch;

  const rejectionReasonText = rejectionReason || null;

  await setDoc(
    rejectedPodcastRef,
    {
      waitingForConfirmation: false,
      enabled: false,
      rejectedDate: Timestamp.now().toMillis(),
      rejectionReason: rejectionReasonText,
      rejectionType,
    },
    { merge: true }
  );
};

export default rejectPodcast;
