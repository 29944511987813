import {
  fetchGeneralValuesInit,
  fetchGeneralValuesSuccess,
  fetchGeneralValuesFail,
  updateGeneralValuesInit,
  updateGeneralValuesSuccess,
  updateGeneralValuesFail,
  fetchHostingProvidersInit,
  fetchHostingProvidersSuccess,
  fetchHostingProvidersFail,
  resetGeneralValues,
} from 'state/actionCreators/generals';
import fetchGeneralValuesService from 'services/generals/fetchCpm';
import updateGeneralValuesService from 'services/generals/updateCpm';
import fetchHostingProvidersService from 'services/generals/fetchHostingProviders';

export const fetchGeneralValues = (network) => async (dispatch) => {
  dispatch(fetchGeneralValuesInit());

  try {
    const generalValues = await fetchGeneralValuesService(network);
    return dispatch(fetchGeneralValuesSuccess(generalValues));
  } catch (error) {
    return dispatch(fetchGeneralValuesFail({ error: error.message }));
  }
};

export const updateGeneralValues = (value) => async (dispatch) => {
  dispatch(updateGeneralValuesInit());

  try {
    const generalValues = await updateGeneralValuesService(value);

    return dispatch(updateGeneralValuesSuccess({ generalValues }));
  } catch (error) {
    return dispatch(updateGeneralValuesFail({ error: error.message }));
  }
};

export const fetchHostingProviders = () => async (dispatch) => {
  dispatch(fetchHostingProvidersInit());

  try {
    const hostingProviders = await fetchHostingProvidersService();
    return dispatch(fetchHostingProvidersSuccess({ hostingProviders }));
  } catch (error) {
    return dispatch(fetchHostingProvidersFail({ error: error.message }));
  }
};

export const resetGeneralValuesState = () => async (dispatch) => {
  dispatch(resetGeneralValues());
}
