import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import getSelectStyles from 'utils/styles/selectStyles';

const SelectInput = ({
  borderColor,
  backgroundColor,
  readOnly,
  disabled,
  ...props
}) => {
  const styles = getSelectStyles({
    borderColor,
    readOnly,
    backgroundColor,
    disabled,
  });

  return <Select styles={styles} {...props} />;
};

SelectInput.propTypes = {
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectInput.defaultProps = {
  borderColor: '1px solid #b9b9b9',
  backgroundColor: 'white',
  readOnly: false,
  disabled: false,
};

export default SelectInput;
