const ModalType = Object.freeze({
  REJECT_PAYMENT: 'reject-payment',
  ACCEPT_PAYMENT: 'accept-payment',
  PARTIAL_PAYMENT: 'partial-payment',
  PODCAST_SUMMARY: 'podcast-summary',
  ACCEPT_PODCAST: 'accept-podcast',
  REJECT_PODCAST: 'reject-podcast',
  SEND_TO_NETWORK_PODCAST: 'send-to-network-podcast',
  ON_HIATUS_PODCAST: 'on-hiatus-podcast',
  REJECT_PODCAST_REASON: 'reject-podcast-reason',
  ACCEPT_BRAND: 'accept-brand',
  REJECT_BRAND: 'reject-brand',
  CAMPAIGN_SUMMARY: 'campaign-summary',
  AD_COPY: 'ad-copy',
  CONFIRM_DOCUMENT: 'confirm-document',
  FLAG_DOCUMENT: 'flag-document',
  UPDATE_DOCUMENT_AUDIENCE: 'update-document-audience',
  UPDATE_DOCUMENT_DOWNLOADS: 'update-document-downloads',
  EXPORT_DOCUMENT: 'export-document',
  CLOSE_PAYMENT: 'close-payment',
  CREATE_PAYMENT: 'create-payment',
  CREATE_PAYMENT_CONFIRMATION: 'create-payment-confirmation',
  DELETE_USER_CONFIRMATION: 'delete-user-confirmation',
  CREATE_USER: 'create-user',
  CREATE_USER_CONFIRMATION: 'create-user-confirmation',
});

export default ModalType;
