import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const acceptPendingDocument = async (screenshot) => {
  const {
    userId,
    id,
    audienceEstimate,
    monthlyListeners,
    downloadsPerEpisode,
    createdAt,
  } = screenshot;

  const firestore = getFirestore();

  const podcastRef = doc(firestore, Collections.PodcastUsers, userId);

  const allAudienceEstimationsScreenshots = await getDocs(
    query(
      collection(
        firestore,
        Collections.PodcastUsers,
        userId,
        Collections.AudienceEstimateScreenshots
      ),
      where('approved', '==', false)
    )
  );

  const fieldsToUpdate = {
    ...((monthlyListeners || audienceEstimate) && {
      audienceEstimate: monthlyListeners ?? audienceEstimate,
    }),
    ...(downloadsPerEpisode && { downloadsEstimate: downloadsPerEpisode }),
  };

  const promises = [
    setDoc(
      podcastRef,
      {
        podcastData: {
          ...fieldsToUpdate,
          lastConfirmed: createdAt,
        },
      },
      { merge: true }
    ),
  ];

  allAudienceEstimationsScreenshots.docs.forEach((audience) => {
    const audienceEstimateCreatedAt = new Date(audience.data().createdAt);

    if (audienceEstimateCreatedAt < new Date(createdAt) || audience.id === id) {
      const audienceEstimateScreenshotsRef = doc(
        podcastRef,
        Collections.AudienceEstimateScreenshots,
        audience.id
      );

      promises.push(
        setDoc(
          audienceEstimateScreenshotsRef,
          {
            approved: true,
          },
          { merge: true }
        )
      );
    }
  });

  await Promise.all(promises);
};

export default acceptPendingDocument;
