import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import adTypes from 'utils/campaigns/adTypes';
import offerStatus from 'enums/campaigns/offerStatus.enum';
import podcastPaymentStatus from 'enums/campaigns/podcastPaymentStatus';
import statusColors from 'enums/campaigns/statusColors.enum';
import getOfferPodcastPayment from 'utils/campaigns/getOfferPodcastPaymentStatus';
import { REJECTED_OFFER_DATE_FORMAT } from 'constants/podcasts';

import classes from './CampaignOffer.module.scss';

const FinishedStatusColor = Object.freeze({
  [podcastPaymentStatus.rejected]: statusColors.Red,
  [podcastPaymentStatus.pending]: statusColors.Orange,
  [podcastPaymentStatus.accepted]: statusColors.Green,
});

const UnfinishedStatusColor = Object.freeze({
  [offerStatus.rejected]: statusColors.Red,
  [offerStatus.pending]: statusColors.Yellow,
  [offerStatus.accepted]: statusColors.Green,
});

const CampaignOffer = ({
  offer,
  index,
  hasFinished,
  onClickPayment,
  onClickAdCopy,
}) => {
  const paymentStatus = getOfferPodcastPayment(offer);

  const statusColor = useMemo(() => {
    const color = hasFinished
      ? FinishedStatusColor[paymentStatus]
      : UnfinishedStatusColor[offer.status];

    return color ?? statusColors.None;
  }, [hasFinished, paymentStatus, offer]);

  return (
    <>
      {!offer.broken && (
        <div
          className={classNames(classes.container, {
            [classes.hasBorder]: index > 0,
          })}
        >
          <div className={classes.left}>
            <img
              src={offer.podcastImageUrl}
              alt="logo"
              className={classes.image}
            />
            <div className={classes.info}>
              <Body
                size={BodySize.XS}
                className={classNames(classes.bold, classes.title)}
              >
                {offer.podcastName}
              </Body>
              <Body size={BodySize.S} className={classes.budget}>
                ${offer.amount}
              </Body>
              <div className={classes.adTypes}>
                {offer.adTypes?.map((adType) => {
                  const adTypeValue = adTypes.find((ad) => ad.value === adType);
                  return (
                    <div
                      className={classes.selectedAdType}
                      key={adTypeValue.label}
                    >
                      <div className={classes.selected} />
                      <Body size={BodySize.XXS} className={classes.bold}>
                        {adTypeValue.label}
                      </Body>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.offerStatus}>
              <div
                className={classNames(classes.status, classes[statusColor])}
              />
              <Body
                size={BodySize.XS}
                className={classNames(classes.bold, classes.noWrap)}
              >
                {hasFinished ? paymentStatus : `Offer ${offer.status}`}
              </Body>
            </div>
            {hasFinished && (
              <Button
                kind={ButtonKind.Secondary}
                className={classes.adCopy}
                size={ButtonSize.S}
                onClick={() => onClickPayment(offer.podcastId)}
              >
                {paymentStatus === podcastPaymentStatus.pending
                  ? 'Make payment'
                  : 'See payment'}
              </Button>
            )}
            {!hasFinished && offer.status === offerStatus.accepted && (
              <Button
                kind={ButtonKind.Secondary}
                className={classes.adCopy}
                size={ButtonSize.S}
                onClick={onClickAdCopy}
              >
                {offer.adCopy ? 'Edit roll' : 'Send roll'}
              </Button>
            )}
          </div>
        </div>
      )}
      {!offer.broken && offer.status === offerStatus.rejected && (
        <div className={classes.rejectedContainer}>
          <div className={classes.topText}>
            <Body size={BodySize.XS}>
              This podcast has rejected this offer.
            </Body>
          </div>
          {offer.rejectDate && offer.rejectReason && (
            <div className={classes.rejected}>
              <div className={classes.bottomText}>
                <Body size={BodySize.XS} className={classes.rejectDate}>
                  Rejected on&nbsp;
                  {dayjs(offer.rejectDate.toDate()).format(
                    REJECTED_OFFER_DATE_FORMAT
                  )}
                </Body>
                <div className={classes.reason}>
                  <Body size={BodySize.XS} className={classes.reasonTitle}>
                    Reason:&nbsp;
                  </Body>
                  <span>{offer.rejectReason}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

CampaignOffer.propTypes = {
  offer: PropTypes.shape({
    adTypes: PropTypes.arrayOf(PropTypes.string),
    podcastPaymentStatus: PropTypes.string,
    adCopy: PropTypes.shape({
      copy: PropTypes.string,
      notes: PropTypes.string,
    }),
    podcastId: PropTypes.string,
    rejectReason: PropTypes.string,
    rejectDate: PropTypes.instanceOf(Date),
    podcastImageUrl: PropTypes.string,
    podcastName: PropTypes.string,
    amount: PropTypes.string,
    status: PropTypes.string,
    broken: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  hasFinished: PropTypes.bool.isRequired,
  onClickPayment: PropTypes.func,
  onClickAdCopy: PropTypes.func,
};

CampaignOffer.defaultProps = {
  onClickPayment: null,
  onClickAdCopy: null,
};

export default CampaignOffer;
