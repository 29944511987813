import {
  collection,
  query,
  getDocs,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchPodcastOffers = async ({ podcastId }) => {
  const firestore = getFirestore();

  const podcastOffersRef = await getDocs(
    query(
      collection(
        firestore,
        Collections.PodcastUsers,
        podcastId,
        Collections.Offers
      )
    )
  );

  const podcastOffers = podcastOffersRef.docs.map((item) => {
    const data = item.data();
    const createdAt = new Timestamp(
      data.createdAt.seconds,
      data.createdAt.nanoseconds
    ).toDate();

    const periodFrom = new Timestamp(
      data.periodFrom.seconds,
      data.periodFrom.nanoseconds
    ).toDate();

    const periodTo = new Timestamp(
      data.periodTo.seconds,
      data.periodTo.nanoseconds
    ).toDate();

    return JSON.stringify({
      ...data,
      uid: item.id,
      createdAt: createdAt || null,
      periodFrom: periodFrom || null,
      periodTo: periodTo || null,
    });
  });

  return { podcastOffers };
};

export default fetchPodcastOffers;
