/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchCurrentCampaignsInit,
  fetchCurrentCampaignsSuccess,
  fetchCurrentCampaignsFail,
  fetchFinishedCampaignsInit,
  fetchFinishedCampaignsSuccess,
  fetchFinishedCampaignsFail,
  clearCampaigns,
  sendAdCopyInit,
  sendAdCopySuccess,
  sendAdCopyFail,
  clearAdCopy,
} from 'state/actionCreators/campaigns';

export const initialState = {
  fetchCurrentCampaignsLoading: false,
  fetchCurrentCampaignsSuccess: false,
  fetchCurrentCampaignsError: null,
  fetchFinishedCampaignsLoading: false,
  fetchFinishedCampaignsSuccess: false,
  fetchFinishedCampaignsError: null,
  sendAdCopyLoading: false,
  sendAdCopySuccess: false,
  sendAdCopyError: null,
  currentCampaigns: [],
  finishedCampaigns: [],
};

const campaignsReducer = createReducer(initialState, {
  [fetchCurrentCampaignsInit]: (state) => {
    state.fetchCurrentCampaignsLoading = true;
    state.fetchCurrentCampaignsSuccess = false;
    state.fetchCurrentCampaignsError = null;
    state.currentCampaigns = [];
  },
  [fetchCurrentCampaignsSuccess]: (state, { payload }) => {
    const { campaigns } = payload;
    state.fetchCurrentCampaignsLoading = false;
    state.fetchCurrentCampaignsSuccess = true;
    state.fetchCurrentCampaignsError = null;
    state.currentCampaigns = campaigns;
  },
  [fetchCurrentCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCurrentCampaignsLoading = false;
    state.fetchCurrentCampaignsSuccess = false;
    state.fetchCurrentCampaignsError = error;
  },
  [fetchFinishedCampaignsInit]: (state) => {
    state.fetchFinishedCampaignsLoading = true;
    state.fetchFinishedCampaignsSuccess = false;
    state.fetchFinishedCampaignsError = null;
    state.finishedCampaigns = [];
  },
  [fetchFinishedCampaignsSuccess]: (state, { payload }) => {
    const { campaigns } = payload;
    state.fetchFinishedCampaignsLoading = false;
    state.fetchFinishedCampaignsSuccess = true;
    state.fetchFinishedCampaignsError = null;
    state.finishedCampaigns = campaigns;
  },
  [fetchFinishedCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchFinishedCampaignsLoading = false;
    state.fetchFinishedCampaignsSuccess = false;
    state.fetchFinishedCampaignsError = error;
  },
  [sendAdCopyInit]: (state) => {
    state.sendAdCopyLoading = true;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = null;
  },
  [sendAdCopySuccess]: (state) => {
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = true;
    state.sendAdCopyError = null;
  },
  [sendAdCopyFail]: (state, { payload }) => {
    const { error } = payload;
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = error;
  },
  [clearAdCopy]: (state) => {
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = null;
  },
  [clearCampaigns]: (state) => {
    state.fetchFinishedCampaignsLoading = false;
    state.fetchFinishedCampaignsSuccess = false;
    state.fetchFinishedCampaignsError = null;
    state.fetchCurrentCampaignsLoading = false;
    state.fetchCurrentCampaignsSuccess = false;
    state.fetchCurrentCampaignsError = null;
    state.finishedCampaigns = [];
    state.currentCampaigns = [];
  },
});

export default campaignsReducer;
