import paymentStatus from 'enums/payments/paymentStatus.enum';

const getPaymentDropdownOptions = ({
  status,
  closed,
  row,
  onClickPay,
  onClickPartialPay,
  onClickClosePay,
  onClickReject,
  onClickCampaign,
  external = false,
}) => {
  let dropdownOptions = [
    {
      label: 'Process Full Payment',
      onClick: () => onClickPay(row),
    },
    {
      label: 'Process Partial Payment',
      onClick: () => onClickPartialPay(row),
    },
    {
      label: 'View Campaign',
      onClick: () => onClickCampaign(row),
    },
  ];

  if (status === paymentStatus.Pending) {
    dropdownOptions.splice(dropdownOptions.length - 1, 0, {
      label: 'Reject Payment',
      onClick: () => onClickReject(row),
    });
  }

  if (status === paymentStatus.Partial) {
    dropdownOptions.splice(dropdownOptions.length - 1, 0, {
      label: 'Close Payment',
      onClick: () => onClickClosePay(row),
    });
  }
  if (status === paymentStatus.Partial && closed) {
    dropdownOptions = [
      {
        label: 'Payment Closed',
        onClick: () => {},
      },
    ];
  }
  return external ? dropdownOptions.slice(3) : dropdownOptions;
};

export default getPaymentDropdownOptions;
