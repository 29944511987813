const adTypes = [
  {
    label: 'PRE-ROLL',
    value: 'preRoll',
  },
  {
    label: 'MID-ROLL',
    value: 'midRoll',
  },
  {
    label: 'POST-ROLL',
    value: 'postRoll',
  },
];

export default adTypes;
