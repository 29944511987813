import { createAction } from '@reduxjs/toolkit';

export const clearUsers = createAction('[Users] Clear Users');

export const createUserInit = createAction('[Users] Create User Init');
export const createUserLoading = createAction('[Users] Create User Loading');
export const createUserSuccess = createAction('[Users] Create User Success');
export const createUserFail = createAction('[Users] Create User Fail');

export const fetchUsersInit = createAction('[Users] Fetch User Init');
export const fetchUsersSuccess = createAction('[Users] Fetch User Success');
export const fetchUsersFail = createAction('[Users] Fetch User Fail');
export const fetchUsersLoading = createAction('[Users] Fetch User Loading');

export const updateUserInit = createAction('[Users] Update User Init');
export const updateUserSuccess = createAction('[Users] Update User Success');
export const updateUserFail = createAction('[Users] Update User Fail');
export const updateUserLoading = createAction('[Users] Update User Loading');

export const deleteUserInit = createAction('[Users] Delete User Init');
export const deleteUserSuccess = createAction('[Users] Delete User Success');
export const deleteUserFail = createAction('[Users] Delete User Fail');
export const deleteUserLoading = createAction('[Users] Delete User Loading');


export const resetUser = createAction('[Users] Reset User');
