import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './PodcastFieldInformation.module.scss';

const PodcastFieldInformation = ({
  canShowField,
  Component,
  information,
  icon,
  title,
  children,
  multipleChildrenField,
  ...rest
}) => {
  if (!canShowField) {
    return null;
  }

  if (multipleChildrenField) {
    return (
      <Component>
        {children.map((childrenProps) => (
          <PodcastFieldInformation {...childrenProps} />
        ))}
      </Component>
    );
  }

  return (
    <div className={classes.item}>
      {icon && (
        <div>
          <img src={icon} alt={information} className={classes.icon} />
        </div>
      )}
      <div>
        {title && (
          <Body size={BodySize.XS} className={classes.itemTitle}>
            {title}
          </Body>
        )}
        <Component {...rest} />
      </div>
    </div>
  );
};

PodcastFieldInformation.propTypes = {
  canShowField: PropTypes.bool,
  Component: PropTypes.elementType,
  information: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      canShowField: PropTypes.bool,
      Component: PropTypes.elementType,
      information: PropTypes.string.isRequired,
      icon: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  multipleChildrenField: PropTypes.bool,
};

PodcastFieldInformation.defaultProps = {
  canShowField: true,
  Component: () => {},
  icon: null,
  title: null,
  children: null,
  multipleChildrenField: false,
};

export default PodcastFieldInformation;
