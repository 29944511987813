import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Spinner from 'components/Common/Spinner';
import Table from 'components/Common/Table';
import Toast from 'components/Common/Toast';
import Modal from 'components/Common/Modal';
import Confirmation from 'components/Common/Confirmation';
import PartialPayConfirmation from 'components/Common/PartialPayConfirmation';
import CustomAcceptModal from 'components/Common/CustomAcceptModal';
import Button, { Size as ButtonSize } from 'components/Common/Button';
import Tabs from 'components/Common/Tabs';
import Tab from 'components/Common/Tab';
import CreatePayment from 'components/Pages/Payments/CreatePayment';
import {
  acceptPayment,
  clearFetchedPaymentsState,
  clearPaymentsState,
  fetchPendingPayments,
  rejectPayment,
  closePayment,
} from 'state/actions/payments';
import { closePaymentInit } from 'state/actionCreators/payments';
import {
  selectAcceptPaymentState,
  selectFetchPendingPaymentsState,
  selectRejectPaymentState,
  selectClosePaymentState,
} from 'state/selectors/payments';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import PaymentTabs from 'enums/payments/paymentTabs.enum';
import getPendingPaymentsColumns from 'utils/payments/getPendingPaymentsColumns';
import Path from 'enums/path.enum';
import fixDecimalPart from 'utils/numbers/fixDecimalPart';
import formatDateToFileTitle from 'utils/dates';

import classes from './Payments.module.scss';

const Payments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { podcastId } = useParams();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    loading: loadingPayments,
    success: successFetchingPayments,
    error: errorFetchingPayments,
    pendingPayments,
  } = useSelector(selectFetchPendingPaymentsState, shallowEqual);

  const {
    loading: loadingRejectPayment,
    success: successRejectingPayment,
    error: errorRejectingPayment,
  } = useSelector(selectRejectPaymentState, shallowEqual);

  const {
    loading: loadingAcceptPayment,
    success: successAcceptingPayment,
    error: errorAcceptingPayment,
  } = useSelector(selectAcceptPaymentState, shallowEqual);

  const {
    loading: loadingClosePayment,
    success: successClosingPayment,
    error: errorClosingPayment,
  } = useSelector(selectClosePaymentState, shallowEqual);

  const [paymentList, setPaymentList] = useState([]);
  const [amountToPay, setAmountToPay] = useState(0);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (user)
      dispatch(fetchPendingPayments({ podcastId, network: user.network }));

    return () => {
      dispatch(clearFetchedPaymentsState());
      setPaymentList([]);
    };
  }, [user]);

  useEffect(() => {
    if (
      successRejectingPayment ||
      successAcceptingPayment ||
      errorRejectingPayment ||
      errorClosingPayment ||
      errorAcceptingPayment
    ) {
      dispatch(clearPaymentsState());
    }
  }, [
    successRejectingPayment,
    successAcceptingPayment,
    errorRejectingPayment,
    errorClosingPayment,
    errorAcceptingPayment,
  ]);

  useEffect(() => {
    if (
      successFetchingPayments &&
      pendingPayments.length !== paymentList.length
    ) {
      setPaymentList(pendingPayments);
    }
  }, [successFetchingPayments, pendingPayments, paymentList]);

  useEffect(() => {
    if (
      (successRejectingPayment ||
        successClosingPayment ||
        successAcceptingPayment) &&
      user
    ) {
      dispatch(fetchPendingPayments({ podcastId, network: user.network }));
      dispatch(closePaymentInit());
      setPaymentList([]);
      onCloseModalHandler();
    }
  }, [
    successRejectingPayment,
    successClosingPayment,
    successAcceptingPayment,
    user,
  ]);

  useEffect(() => {
    if (errorRejectingPayment || errorAcceptingPayment) {
      onCloseModalHandler();
    }
  }, [errorRejectingPayment, errorClosingPayment, errorAcceptingPayment]);

  useEffect(() => {
    if (!modal.type) {
      setAmountToPay(0);
    }
  }, [modal.type]);

  const onClickPayHandler = useCallback(
    (index) => {
      const payment = paymentList.filter((i) => i.id === index)[0];
      onOpenModalHandler(ModalType.ACCEPT_PAYMENT, {
        payment,
      });
    },
    [paymentList]
  );

  const onClickPartialPayHandler = useCallback(
    (index) => {
      const payment = paymentList.filter((i) => i.id === index)[0];
      onOpenModalHandler(ModalType.PARTIAL_PAYMENT, {
        payment,
      });
    },
    [paymentList]
  );

  const onClickClosePayHandler = useCallback(
    (index) => {
      const payment = paymentList.filter((i) => i.id === index)[0];
      onOpenModalHandler(ModalType.CLOSE_PAYMENT, {
        payment,
      });
    },
    [paymentList]
  );

  const onAcceptPaymentHandler = useCallback(() => {
    const { payment } = modal;
    dispatch(
      acceptPayment({
        paymentId: payment.id,
        amount: payment.total - payment.paid,
      })
    );
  }, [modal.payment?.id]);

  const onAcceptPartialPaymentHandler = useCallback(() => {
    const { payment } = modal;
    const roundedAmount = Number(amountToPay.toFixed(2));

    dispatch(acceptPayment({ paymentId: payment.id, amount: roundedAmount }));
  }, [modal.payment?.id, amountToPay]);

  const onClickRejectHandler = useCallback(
    (index) => {
      const payment = paymentList.filter((i) => i.id === index)[0];
      onOpenModalHandler(ModalType.REJECT_PAYMENT, {
        payment,
      });
    },
    [paymentList]
  );

  const onClickExportHandler = useCallback(() => {
    onOpenModalHandler(ModalType.EXPORT_DOCUMENTS);
  }, []);

  const onClickCreatePaymentHandler = useCallback(() => {
    onOpenModalHandler(ModalType.CREATE_PAYMENT);
  }, []);

  const onRejectPaymentHandler = useCallback(() => {
    const { payment } = modal;
    dispatch(rejectPayment({ paymentId: payment.id }));
  }, [modal]);

  const onClosePaymentHandler = useCallback(() => {
    const { payment } = modal;
    dispatch(closePayment({ paymentId: payment.id }));
  }, [modal]);

  const onClickCampaignHandler = useCallback(
    (index) => {
      const payment = paymentList.filter((i) => i.id === index)[0];
      history.push(
        `${Path.Campaigns}/${payment.brand.id}/${payment.campaign.id}`
      );
    },
    [paymentList]
  );

  const onChangeAmountToPayHandler = useCallback((event) => {
    setAmountToPay(Number(event.target.value));
  }, []);

  const columns = useMemo(
    () =>
      getPendingPaymentsColumns({
        onClickPay: onClickPayHandler,
        onClickPartialPay: onClickPartialPayHandler,
        onClickClosePay: onClickClosePayHandler,
        onClickReject: onClickRejectHandler,
        onClickCampaign: onClickCampaignHandler,
      }),
    [onClickPayHandler, onClickPartialPayHandler, onClickRejectHandler]
  );

  return (
    <>
      {errorFetchingPayments && (
        <Toast text={errorFetchingPayments} id="Fetch pending payments error" />
      )}
      {errorRejectingPayment && (
        <Toast
          text={errorRejectingPayment}
          id="Fetch rejecting payment error"
        />
      )}
      {errorAcceptingPayment && (
        <Toast
          text={errorAcceptingPayment}
          id="Fetch accepting payment error"
        />
      )}
      {errorClosingPayment && (
        <Toast text={errorClosingPayment} id="Fetch closing payment error" />
      )}
      {successAcceptingPayment && (
        <Toast
          type="success"
          text="Payment Success"
          id="Fetch accepting payment success"
        />
      )}
      {successClosingPayment && (
        <Toast
          type="success"
          text="Payment closed successfully!"
          id="Fetch closing payment success"
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.REJECT_PAYMENT}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Reject payment"
          description="Are you sure you want to reject the payment of the selected podcast?"
          onAccept={onRejectPaymentHandler}
          onCancel={onCloseModalHandler}
          loading={loadingRejectPayment}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ACCEPT_PAYMENT}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Approve payment"
          description="Are you sure you want to approve the payment of the selected podcast?"
          onAccept={onAcceptPaymentHandler}
          onCancel={onCloseModalHandler}
          loading={loadingAcceptPayment}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.PARTIAL_PAYMENT}
        onClose={onCloseModalHandler}
      >
        <PartialPayConfirmation
          title="Approve partial payment"
          onAccept={onAcceptPartialPaymentHandler}
          loading={loadingAcceptPayment}
          name={modal.payment?.title}
          amount={fixDecimalPart(modal.payment?.total)}
          paid={modal.payment?.paid}
          onChange={onChangeAmountToPayHandler}
          value={amountToPay}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.CLOSE_PAYMENT}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Close payment"
          description="Are you sure you want to close the payment of the selected podcast?"
          onAccept={onClosePaymentHandler}
          onCancel={onCloseModalHandler}
          loading={loadingClosePayment}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.EXPORT_DOCUMENTS}
        onClose={onCloseModalHandler}
      >
        <CustomAcceptModal
          title="Export payments"
          description="Proceed to export the pending payments to a CSV file."
          columns={columns[0].columns}
          filename={`payments_${formatDateToFileTitle(new Date())}`}
          onCancel={onCloseModalHandler}
          paymentList={paymentList}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.CREATE_PAYMENT}
        onClose={onCloseModalHandler}
      >
        <CreatePayment closeSelf={onCloseModalHandler} />
      </Modal>
      <div className={classes.container}>
        {loadingPayments ? (
          <Spinner className={classes.spinner} />
        ) : (
          <>
            <div className={classes.exportButtonContainer}>
              <Button
                className={classes.exportButton}
                onClick={onClickExportHandler}
                size={ButtonSize.S}
              >
                Export CSV
              </Button>
            </div>
            <div className={classes.createButtonContainer}>
              <Button
                className={classes.createButton}
                onClick={onClickCreatePaymentHandler}
                size={ButtonSize.S}
              >
                Create Payment
              </Button>
            </div>
            <Tabs className={classes.tabs} active={1}>
              {PaymentTabs.map((tab) => (
                <Tab tab={tab.label} key={tab.key}>
                  <div className={classes.tableContainer}>
                    <Table
                      columns={columns}
                      data={
                        tab.key === 'pending'
                          ? paymentList.filter(
                              (i) => i.status === tab.key && i.closed !== true
                            )
                          : paymentList.filter(
                              (i) => i.status !== 'pending' || i.closed === true
                            )
                      }
                    />
                  </div>
                </Tab>
              ))}
            </Tabs>
          </>
        )}
      </div>
    </>
  );
};

export default Payments;
