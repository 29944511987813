import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters } from 'react-table';
import classNames from 'classnames';
import PaymentHideFilterColumn from 'enums/payments/paymentHideFilterColumn.enum';
import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import ArrowDownIcon from 'assets/icons/arrow-down.png';
import NoSort from 'assets/icons/no-sort.png';
import Filter from 'components/Common/Filter';

import classes from './Table.module.scss';

const Table = ({ columns, data, className, showFilter = true }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'processingDate',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );
  const filterColumns = columns[0].columns.filter(
    (i) => !PaymentHideFilterColumn.includes(i.accessor)
  );
  return (
    <>
      <div className={classNames(classes.tableWrapper, className)}>
        {showFilter && <Filter options={filterColumns} setFilter={setFilter} />}

        <table {...getTableProps()} className={classes.table}>
          <thead className={classes.thead}>
            {headerGroups
              .map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classes.th}
                    >
                      <div className={classes.headerContainer}>
                        <Body size={BodySize.XS} className={classes.thBody}>
                          {column.render('Header')}
                        </Body>
                        <p>
                          {(column.isSorted || column.Header) && (
                            <img
                              alt="dropdown"
                              src={column.isSorted ? ArrowDownIcon : NoSort}
                              className={classNames(classes.arrow, {
                                [classes.open]:
                                  !column.isSortedDesc && column.isSorted,
                              })}
                            />
                          )}
                        </p>
                      </div>
                    </th>
                  ))}
                </tr>
              ))
              .filter((_, index) => index !== 0)}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              <td colSpan={columns[0].columns.length}>
                {rows?.length === 0 && (
                  <Body className={classes.noData} size={BodySize.XXS}>
                    No data to show
                  </Body>
                )}
              </td>
            </tr>
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={classes.tr}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className={classes.td}>
                      <Body
                        size={BodySize.XS}
                        color={BodyColor.Black}
                        className={classes.tdBody}
                      >
                        {cell.render('Cell')}
                      </Body>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

Table.defaultProps = {
  className: '',
};

Table.propTypes = {
  columns: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  showFilter: PropTypes.bool.isRequired,
};

export default Table;
