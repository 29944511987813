import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Common/Dropdown';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Input, { Type as InputType } from 'components/Common/Input';
import classes from './Filter.module.scss';

const Filter = ({ options, setFilter }) => {
  const [filterList, setFilterList] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const addFilterHandler = (item) => {
    setFilterList([...filterList, { ...item, filterValue }]);
    setFilter(item.columnName, filterValue);
    setIsDropDownOpen(false);
  };
  const onCloseButton = (item) => {
    setFilter(item.columnName, '');
    setFilterList(filterList.filter((i) => i !== item));
  };
  const newOptions = options.map((item) => ({
    ...item,
    label: item.Header,
    onClick: (setOpen) => {
      addFilterHandler(item);
      setOpen(false);
      setFilterValue('');
    },
  }));
  const onChangeFilterValue = ({ target: { value } }) => {
    setFilterValue(value);
  };
  return (
    <div className={classes.container}>
      <div className={classes.filterContainer}>
        <Body size={BodySize.S}>Add Filter</Body>
        <Dropdown
          options={newOptions}
          className={classes.dropdown}
          isOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
        />
        {isDropDownOpen && (
          <Input
            type={InputType.Text}
            containerClassName={classes.inputContainer}
            value={filterValue}
            onChange={onChangeFilterValue}
            name="filter"
          />
        )}

        {filterList.map((item) => (
          <div className={classes.itemContainer} key={item.columnName}>
            <div className={classes.titleContainer}>
              <div>{item.Header}</div>
              <div className={classes.filterValue}>{item.filterValue}</div>
            </div>
            <Button
              className={classes.button}
              kind={ButtonKind.Primary}
              size={ButtonSize.S}
              onClick={() => onCloseButton(item)}
            >
              <span>X</span>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

Filter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilter: PropTypes.func.isRequired,
};
export default Filter;
