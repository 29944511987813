import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  Timestamp,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchApprovedBrands = async (network) => {
  const firestore = getFirestore();

  const approvedBrandsRef = collection(firestore, Collections.Brands);

  const approvedBrandsQuery = query(
    approvedBrandsRef,
    where('waitingForConfirmation', '==', false),
    where('enabled', '==', true),
    where('networks', 'array-contains', network),
    orderBy('createdAt')
  );

  const approvedBrandsSapshot = await getDocs(approvedBrandsQuery);

  const approvedBrands = approvedBrandsSapshot.docs.map((doc) => {
    const data = doc.data();
    const date = new Timestamp(
      data.createdAt.seconds,
      data.createdAt.nanoseconds
    ).toDate();

    return {
      ...data,
      uid: doc.id,
      createdAt: date || null,
    };
  });

  return approvedBrands;
};

export default fetchApprovedBrands;
