import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Button, { Type as ButtonType } from 'components/Common/Button';
import { Size as TextareaSize } from 'components/Common/Textarea';
import Form, { ValidationMode } from 'components/Common/Form';
import FormControlTextarea from 'components/Common/FormControlTextarea';
import Toast from 'components/Common/Toast';
import Spinner from 'components/Common/Spinner';
import { sendAdCopy } from 'state/actions/campaigns';
import { selectSendAdCopyState } from 'state/selectors/campaigns';

import validationSchema from './AdCopy.schema';
import classes from './AdCopy.module.scss';

const AdCopy = ({ campaign, offer, brandId }) => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(selectSendAdCopyState, shallowEqual);

  const offerData = useMemo(() => offer, [offer]);

  const onClickSendHandler = useCallback(
    (values) => {
      const { copy, notes } = values;

      // TODO: Replace this to add the div wrapper in the text editor
      const parsedCopy = `<div>${copy}</div>`;
      const parsedNotes = `<div>${notes}</div>`;

      dispatch(
        sendAdCopy({
          brandId,
          campaign,
          podcastId: offer.podcastId,
          adCopy: {
            copy: parsedCopy,
            notes: parsedNotes,
          },
        })
      );
    },
    [campaign, offer]
  );

  return (
    <>
      {error && <Toast text={error} id="Send Ad Copy Error" />}
      {!offerData ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.container}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
            size={BodySize.S}
          >
            Send podcast ad copy
          </Body>
          <div>
            <img
              src={offerData.podcastImageUrl}
              alt="podcast logo"
              className={classes.logo}
            />
          </div>
          <Body className={classes.podcastName} size={BodySize.XS}>
            {offerData?.podcastName}
          </Body>
          <Form
            validationSchema={validationSchema}
            validationMode={ValidationMode.OnChange}
            onSubmit={onClickSendHandler}
            className={classes.form}
          >
            <Body size={BodySize.XS}>
              Insert Your Ad Copy for {offerData?.podcastName}
            </Body>
            <FormControlTextarea
              name="copy"
              className={classes.formControl}
              size={TextareaSize.M}
              defaultValue={offerData?.adCopy?.copy || ''}
            />
            <Body className={classes.notesText} size={BodySize.XS}>
              If you have any additional notes, add below (optional)
            </Body>
            <FormControlTextarea
              name="notes"
              className={classes.formControl}
              size={TextareaSize.S}
              defaultValue={offerData?.adCopy?.notes || ''}
            />
            <Button
              className={classes.button}
              type={ButtonType.Submit}
              loading={loading}
            >
              {offerData?.adCopy ? 'Send changes' : 'Send'}
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};

AdCopy.propTypes = {
  campaign: PropTypes.shape({}),
  offer: PropTypes.shape({
    podcastName: PropTypes.string,
    podcastId: PropTypes.string,
    podcastImageUrl: PropTypes.string,
    adCopy: PropTypes.shape({
      copy: PropTypes.string,
      notes: PropTypes.string,
    }),
  }),
  brandId: PropTypes.string,
};

AdCopy.defaultProps = {
  campaign: null,
  offer: null,
  brandId: null,
};

export default AdCopy;
