import { doc, getFirestore, setDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const editPodcastData = async (podcastId, editedData) => {
  const firestore = getFirestore();

  const acceptedPodcastRef = doc(
    firestore,
    Collections.PodcastUsers,
    podcastId
  );

  await setDoc(
    acceptedPodcastRef,
    {
      ...editedData,
    },
    { merge: true }
  );
};

export default editPodcastData;
