import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Body, { Size as BodySize } from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';
import ViewIcon from 'assets/icons/view.png';
import adFormats from 'utils/campaigns/adFormatsOptions';

import classes from './PodcastCampaignSummary.module.scss';

const PodcastCampaignSummary = ({ campaign, onClickMoreDetails }) => (
  <div className={classes.container}>
    <div className={classes.content}>
      <div className={classes.imageAdFormatContainer}>
        <div className={classes.image}>
          <img src={campaign.image} alt="logo" className={classes.logo} />
        </div>
        {campaign.adFormats?.length > 0 && (
          <div className={classes.adFormatContainer}>
            <Body size={BodySize.S} className={classes.title}>
              {
                adFormats.find(
                  (addFormat) => campaign.adFormats[0] === addFormat.value
                )?.label
              }
            </Body>
          </div>
        )}
      </div>
      <div className={classes.seeMoreContainer}>
        <Body size={BodySize.S} className={classes.title}>
          {campaign.title || 'Campaign title'}
        </Body>
        <IconButton className={classes.seeMore} onClick={onClickMoreDetails}>
          <img src={ViewIcon} alt="view" className={classes.icon} />
          <Body size={BodySize.XXS} className={classes.bold}>
            More details
          </Body>
        </IconButton>
      </div>
      <div className={classes.campaignDetails}>
        <div className={classes.details}>
          <Body size={BodySize.XS} className={classes.text}>
            Airing between
          </Body>
          <Body
            size={BodySize.XS}
            className={classNames(classes.budgetAmount, classes.text)}
          >
            {dayjs(campaign.periodFrom).format('MMMM DD, YYYY')} to{' '}
            {dayjs(campaign.periodTo).format('MMMM DD, YYYY')}
          </Body>
        </div>
      </div>
      <div className={classes.campaignDetails}>
        <div className={classes.detailsEpisodes}>
          <Body size={BodySize.XS} className={[classes.text, classes.episodes]}>
            {campaign.episodes} Episodes
          </Body>
        </div>
        <div className={classes.details}>
          <Body size={BodySize.XS} className={classes.text}>
            Aprox. <b>{campaign.impressions}</b> impressions
          </Body>
        </div>
        <div className={classNames(classes.details, classes.paidDetails)}>
          <Body
            size={BodySize.XS}
            className={classNames(classes.text, classes.paid)}
          >
            ${campaign.amount}
          </Body>
        </div>
      </div>
    </div>
  </div>
);

PodcastCampaignSummary.propTypes = {
  campaign: PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    periodFrom: PropTypes.instanceOf(Date).isRequired,
    periodTo: PropTypes.instanceOf(Date).isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
    image: PropTypes.string.isRequired,
    episodes: PropTypes.number.isRequired,
    impressions: PropTypes.number.isRequired,
    paid: PropTypes.number.isRequired,
    adFormats: PropTypes.oneOfType([PropTypes.string]).isRequired,
  }).isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
};

export default PodcastCampaignSummary;
