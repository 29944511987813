import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Body.module.scss';

export const Size = Object.freeze({
  L: 'l-size',
  M: 'm-size',
  S: 's-size',
  XS: 'xs-size',
  XXS: 'xxs-size',
});

export const Color = Object.freeze({
  Black: 'black',
  Gray: 'gray',
  White: 'white',
  Violet: 'violet',
  Red: 'red',
});

export const LetterCase = Object.freeze({
  Uppercase: 'uppercase',
  Lowercase: 'lowercase',
});

export const Spacing = Object.freeze({
  Normal: 'normal-spacing',
  S: 's-spacing',
  M: 'm-spacing',
  L: 'l-spacing',
});

const Body = ({ size, color, letterCase, spacing, children, className }) => (
  <p
    className={classNames(
      classes.fontFamily,
      classes[color],
      classes[size],
      classes[letterCase],
      classes[spacing],
      className
    )}
  >
    {children}
  </p>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(Size)),
  color: PropTypes.oneOf(Object.values(Color)),
  letterCase: PropTypes.oneOf(Object.values(LetterCase)),
  spacing: PropTypes.oneOf(Object.values(Spacing)),
  className: PropTypes.string,
};

Body.defaultProps = {
  size: Size.M,
  color: Color.Black,
  letterCase: LetterCase.Lowercase,
  spacing: Spacing.Normal,
  className: '',
};

export default Body;
