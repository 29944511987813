/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const ValidationMode = Object.freeze({
  OnChange: 'onChange',
  OnBlur: 'onBlur',
  OnSubmit: 'onSubmit',
  OnTouched: 'onTouched',
});

const Form = ({
  children,
  validationSchema,
  validationMode,
  onSubmit,
  className,
}) => {
  const formMethods = useForm({
    resolver: validationSchema && yupResolver(validationSchema),
    mode: validationMode,
  });
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  validationMode: PropTypes.oneOf(Object.values(ValidationMode)),
  className: PropTypes.string,
};

Form.defaultProps = {
  validationSchema: null,
  validationMode: ValidationMode.OnSubmit,
  className: '',
  onSubmit: () => {},
};

export default Form;
