import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchFinishedCampaigns = async ({ brandId }) => {
  const firestore = getFirestore();

  const today = new Date();

  const campaignRef = collection(
    firestore,
    Collections.Brands,
    brandId,
    Collections.Campaigns
  );

  const finishedCampaignsQuery = query(
    campaignRef,
    where('airingTo', '<', today)
  );

  const finishedCampaignsSapshot = await getDocs(finishedCampaignsQuery);

  return finishedCampaignsSapshot.docs.map((doc) => {
    const data = doc.data();
    const date = data.createdAt?.toDate() ?? null;

    return {
      ...data,
      uid: doc.id,
      createdAt: date,
    };
  });
};

export default fetchFinishedCampaigns;
