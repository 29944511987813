import { getFirestore, doc, setDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const hiatusPodcast = async ({ podcastId, status }) => {
  const firestore = getFirestore();

  const hiatusedPodcastRef = doc(
    firestore,
    Collections.PodcastUsers,
    podcastId
  );
  await setDoc(
    hiatusedPodcastRef,
    {
      isOnHiatus: status,
    },
    { merge: true }
  );
};

export default hiatusPodcast;
