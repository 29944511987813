const Collections = Object.freeze({
  AudienceEstimateScreenshots: 'audienceEstimateScreenshots',
  Brands: 'brands',
  Campaigns: 'campaigns',
  Categories: 'categories',
  DefaultValues: 'defaultValues',
  General: 'general',
  Offers: 'offers',
  Payments: 'payments',
  Podcasts: 'podcasts',
  PodcastUsers: 'users',
  HostingProviders: 'hostingProviders',
  Administrators: 'administrators',
  Users: 'users',
});

export default Collections;
