import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const sendAdCopy = async ({ brandId, campaign, podcastId, adCopy }) => {
  const firestore = getFirestore();

  const campaignRef = doc(
    firestore,
    Collections.Brands,
    brandId,
    Collections.Campaigns,
    campaign.uid
  );

  const newOffers = campaign.offers.map((offer) => {
    if (offer.podcastId === podcastId) {
      return { ...offer, adCopy };
    }
    return offer;
  });

  await updateDoc(campaignRef, { offers: newOffers });
};

export default sendAdCopy;
