import React from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';

import classes from './Confirmation.module.scss';

const Confirmation = ({
  title,
  description,
  onAccept,
  onCancel,
  loading,
  acceptButtonText,
}) => (
  <div className={classes.container}>
    <Body size={BodySize.S} className={classes.title}>
      {title}
    </Body>
    <Body size={BodySize.XS}>{description}</Body>
    <div className={classes.actions}>
      <Button
        className={classes.button}
        kind={ButtonKind.Tertiary}
        size={ButtonSize.S}
        onClick={onCancel}
      >
        No
      </Button>
      <Button
        className={classes.button}
        kind={ButtonKind.Primary}
        size={ButtonSize.S}
        onClick={onAccept}
        loading={loading}
      >
        {acceptButtonText}
      </Button>
    </div>
  </div>
);

Confirmation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  acceptButtonText: PropTypes.string,
};

Confirmation.defaultProps = {
  loading: false,
  title: '',
  description: '',
  acceptButtonText: 'Yes',
};

export default Confirmation;
