/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import LoginForm from 'components/Pages/Forms/LoginForm';
import { selectLoginState } from 'state/selectors/auth';
import { brandLogo } from 'assets/white-label';

import classes from './Login.module.scss';

const Login = () => {
  const { error } = useSelector(selectLoginState, shallowEqual);

  return (
    <>
      {error && <Toast text={error} id="Login error" />}
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img
              className={classes.imageLogo}
              src={brandLogo}
              alt="Brand Logo"
            />
          </div>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Enter your account
          </Body>
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default Login;
